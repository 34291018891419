import {
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
import Button from "@mui/material/Button";
import PolicyPassword from "./PolicyPassword";

export default (props) => {

    const [validPassword, setValidPassword] = useState(false);

    const [nouveauMotDePasse, setNouveauMotDePasse] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClose = () => {
        props.closeModal(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleChangePassword = (event) => {
        const enteredPassword = event.target.value;
        setNouveauMotDePasse(enteredPassword);
        props.setNewPassword(enteredPassword);
    };
    const handleSendNewPassword = () => {
        props.submitNewPassword();
    };

    return (
        <Dialog open={true} fullWidth maxWidth="xs" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Nouveau mot de passe</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel required error={validPassword}>Nouveau mot de passe</InputLabel>
                            <OutlinedInput
                                required
                                error={validPassword}
                                id="nouveau-mot-de-passe"
                                type={showPassword ? 'text' : 'password'}
                                value={nouveauMotDePasse}
                                autoComplete='current-password'
                                onChange={handleChangePassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Nouveau mot de passe"/>
                            <FormHelperText id="outlined-error-password"
                                            error={validPassword}>{validPassword ? 'Le mot de passe ne respecte pas politique de mot de passe' : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <PolicyPassword
                            password={nouveauMotDePasse}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSendNewPassword}>Valider</Button>
                <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}