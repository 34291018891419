import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import formUtils from "../../../utils/formUtils";
import IconButton from "@mui/material/IconButton";
import {DeleteForever} from "@mui/icons-material";
import {DataGrid, frFR} from "@mui/x-data-grid";
import CloseIcon from '@mui/icons-material/Close';

export default (props) => {

    const initialStateSerieinfo = {
        nom: '',
        description: '',
        prix: '',
        premierDossard: '',
        maximumInscrit: '',
        nombreSerie: '',
        scoreMinimum: '',
        scoreMaximum: '',
        selectTypeInscription: '',
        niveau: props.listNiveau.map(niveau => {
            return {...niveau, check: false}
        }),
        typeMoto: props.listTypeMoto.map(niveau => {
            return {...niveau, check: false}
        }),
        serieBenevole: false
    };
    const initialStateErrrorForm = {
        libelle: false,
        description: false,
        premierDossard: false,
        prix: false,
        maximumInscrit: false,
        nombreSerie: false,
        scoreMinimum: false,
        scoreMaximum: false,
        selectTypeInscription: false,
        niveau: false,
        typeMoto: false,
    };

    const [pagesize, setPagesize] = useState(5);
    const [newType, setNewType] = useState(false);
    const [oldType, setOldType] = useState(false);
    const [selectedType, setSelectedType] = useState([]);
    const [errorForm, setErrorForm] = useState({...initialStateErrrorForm});
    const [serieInfo, setSerieInfo] = useState({...initialStateSerieinfo});

    useEffect(() => {
        if (props.editMode) {
            setSerieInfo({
                ...serieInfo,
                nom: props.editItem.libelle,
                description: props.editItem.desciption,
                prix: props.editItem.prix.toString(),
                premierDossard: props.editItem.premierdossard.toString(),
                maximumInscrit: props.editItem.nbmax.toString(),
                nombreSerie: props.editItem.nbacces.toString(),
                scoreMaximum: props.editItem.scoremax.toString(),
                scoreMinimum: props.editItem.scoremin.toString(),
                selectTypeInscription: props.editItem.seriebenevole ? '' : props.editItem.inscriptiontype.id,
                niveau: filterNiveauEditSerie(props.editItem),
                typeMoto: filterTypeMotoEditSerie(props.editItem),
                serieBenevole: props.editItem.seriebenevole
            });
        }
    }, []);

    const handleNewSerie = () => {
        setNewType(true);
        setOldType(false);
        setSerieInfo({...initialStateSerieinfo});
        setErrorForm({...initialStateErrrorForm});
    };
    const handleOldSerie = () => {
        setNewType(false);
        setOldType(true);
        setSelectedType([]);
    };
    const handleClose = () => {
        props.closeModal();
    };
    const handleAddSerie = () => {
        if (oldType) {
            props.addSerie('old', selectedType);
        } else if (newType) {
            if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
                props.addSerie('new', {
                    libelle: serieInfo.nom,
                    nbacces: serieInfo.nombreSerie,
                    desciption: serieInfo.description,
                    prix: serieInfo.prix,
                    nbmax: serieInfo.maximumInscrit,
                    premierdossard: serieInfo.premierDossard,
                    scoremin: serieInfo.scoreMinimum,
                    scoremax: serieInfo.scoreMaximum,
                    inscriptiontypeid: serieInfo.selectTypeInscription,
                    listniveau: serieInfo.niveau.filter(val => val.check).map(val => {
                        return val.id
                    }),
                    listmototype: serieInfo.typeMoto.filter(val => val.check).map(val => {
                        return val.id
                    }),
                    seriebenevole: serieInfo.serieBenevole
                });
            }
        } else if (props.editMode) {
            if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
                props.addSerie('update', {
                    id: props.editItem.id,
                    libelle: serieInfo.nom,
                    nbacces: serieInfo.nombreSerie,
                    desciption: serieInfo.description,
                    prix: serieInfo.prix,
                    nbmax: serieInfo.maximumInscrit,
                    premierdossard: serieInfo.premierDossard,
                    scoremin: serieInfo.scoreMinimum,
                    scoremax: serieInfo.scoreMaximum,
                    inscriptiontypeid: serieInfo.selectTypeInscription,
                    listniveau: serieInfo.niveau.filter(val => val.check).map(val => {
                        return val.id
                    }),
                    listmototype: serieInfo.typeMoto.filter(val => val.check).map(val => {
                        return val.id
                    }),
                    seriebenevole: serieInfo.serieBenevole
                });
            }
        }
    };

    const handleChangeNomSerie = (event) => {
        setSerieInfo({
            ...serieInfo,
            nom: event.target.value
        });
    };
    const handleChangeDescriptionSerie = (event) => {
        setSerieInfo({
            ...serieInfo,
            description: event.target.value
        });
    };
    const handleChangeSelectInscriptionType = (event) => {
        setSerieInfo({
            ...serieInfo,
            selectTypeInscription: event.target.value
        });
    };
    const handleChangePrixSerie = (event) => {
        setSerieInfo({
            ...serieInfo,
            prix: event.target.value
        });
    };
    const handleChangePremierDossard = (event) => {
        setSerieInfo({
            ...serieInfo,
            premierDossard: event.target.value
        });
    };
    const handleChangeMaximumInscrit = (event) => {
        setSerieInfo({
            ...serieInfo,
            maximumInscrit: event.target.value
        });
    };
    const handleChangeNombreSerie = (event) => {
        setSerieInfo({
            ...serieInfo,
            nombreSerie: event.target.value
        });
    };
    const handleChangeScoreMini = (event) => {
        setSerieInfo({
            ...serieInfo,
            scoreMinimum: event.target.value
        });
    };
    const handleChangeScoreMaxi = (event) => {
        setSerieInfo({
            ...serieInfo,
            scoreMaximum: event.target.value
        });
    };
    const handleChangeNiveau = (event, idNiveau) => {
        setSerieInfo({
            ...serieInfo,
            niveau: [...serieInfo.niveau.map(value => {
                if (value.id === idNiveau) {
                    return {...value, check: event.target.checked}
                } else {
                    return value;
                }
            })]
        });
    };
    const handleChangeTypeMoto = (event, idTypeMoto) => {
        setSerieInfo({
            ...serieInfo,
            typeMoto: [...serieInfo.typeMoto.map(value => {
                if (value.id === idTypeMoto) {
                    return {...value, check: event.target.checked}
                } else {
                    return value;
                }
            })]
        });
    };
    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            libelle: serieInfo.nom.length === 0,
            description: serieInfo.description.length === 0,
            premierDossard: serieInfo.premierDossard.length === 0,
            prix: serieInfo.prix.length === 0,
            maximumInscrit: serieInfo.maximumInscrit.length === 0,
            nombreSerie: serieInfo.nombreSerie.length === 0,
            scoreMinimum: serieInfo.serieBenevole ? false : serieInfo.scoreMinimum.length === 0,
            scoreMaximum: serieInfo.serieBenevole ? false : serieInfo.scoreMaximum.length === 0,
            selectTypeInscription: serieInfo.serieBenevole ? false : serieInfo.selectTypeInscription.length === 0,
            niveau: serieInfo.niveau.filter(value => value.check === true).length === 0,
            typeMoto: serieInfo.typeMoto.filter(value => value.check === true).length === 0
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    };
    const filterNiveauEditSerie = (objSerie) => {
        return [...serieInfo.niveau.map(val => {
            return {...val, check: objSerie.niveaus.filter(test => test.id === val.id).length === 1}
        })];
    };
    const filterTypeMotoEditSerie = (objSerie) => {
        return [...serieInfo.typeMoto.map(val => {
            return {...val, check: objSerie.mototypes.filter(test => test.id === val.id).length === 1}
        })];
    };
    const handleChangeSerieBenevole = (event) => {
        setSerieInfo({
            ...serieInfo,
            serieBenevole: event.target.checked,
            scoreMinimum: '0',
            scoreMaximum: '1000',
            selectTypeInscription: ''
        })
    };
    const handleTemplateSerie = () => {
        const objectSerie = props.data.filter(val => val.id === selectedType[0])[0];
        setSerieInfo({
            ...serieInfo,
            nom: objectSerie.libelle,
            description: objectSerie.desciption,
            prix: objectSerie.prix.toString(),
            premierDossard: objectSerie.premierdossard.toString(),
            maximumInscrit: objectSerie.nbmax.toString(),
            nombreSerie: objectSerie.nbacces.toString(),
            scoreMaximum: objectSerie.scoremax.toString(),
            scoreMinimum: objectSerie.scoremin.toString(),
            selectTypeInscription: objectSerie.seriebenevole ? '' : objectSerie.inscriptiontype.id,
            niveau: filterNiveauEditSerie(objectSerie),
            typeMoto: filterTypeMotoEditSerie(objectSerie),
            serieBenevole: objectSerie.seriebenevole
        });
        setNewType(true);
        setOldType(false);
    };

    const columns = [
        {
            field: 'libelle',
            headerName: 'Nom',
            width: 150,
            editable: false,
        }, {
            field: 'desciption',
            headerName: 'Desciption',
            width: 290,
            editable: false,
        }, {
            field: 'seriebenevole',
            headerName: 'Benevole',
            width: 78,
            editable: false,
            type: 'boolean',
        }, {
            field: 'nbacces',
            headerName: 'Accès',
            width: 55,
            editable: false,
            align: 'center',
        }, {
            field: 'prix',
            headerName: 'Prix',
            width: 40,
            editable: false,
            align: 'center',
        }, {
            field: 'premierdossard',
            headerName: 'Premier dossard',
            width: 125,
            editable: false,
            align: 'center',
        }, {
            field: 'inscriptiontype',
            headerName: 'Type inscription',
            headerAlign: 'center',
            width: 220,
            editable: false,
            align: 'center',
            renderCell: (params) => {
                return params.value != null ? params.row.inscriptiontype.libelle : <CloseIcon/>;
            }
        }, {
            field: 'nbmax',
            headerName: 'Nombre max inscrit',
            headerAlign: 'center',
            width: 140,
            editable: false,
            align: 'center',
        }, {
            field: 'scoremin',
            headerName: 'Score minimum',
            headerAlign: 'center',
            width: 120,
            editable: false,
            align: 'center',
        }, {
            field: 'scoremax',
            headerName: 'Score maximum',
            headerAlign: 'center',
            width: 120,
            editable: false,
            align: 'center',
        }, {
            field: 'supprimer',
            headerName: 'Supprimer',
            headerAlign: 'center',
            width: 85,
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton edge="end" aria-label="edit"
                                onClick={() => props.deleteSerie(cellValues)}
                    >
                        <DeleteForever/>
                    </IconButton>)
            }
        },
    ];

    return (
        <Dialog open={true} fullWidth maxWidth="xl" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>{props.editMode ? 'Modifier' : 'Ajouter'} serie</DialogTitle>
            </Box>
            <DialogContent>
                {!props.editMode &&
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 4}}>
                        <Button onClick={handleNewSerie} variant="contained">Nouvelle serie</Button>
                        <Button onClick={handleOldSerie} variant="contained">Ajouter serie existante</Button>
                    </Box>
                }
                {(newType || props.editMode) &&
                    <Container component="main">
                        <Grid container spacing={2}>
                            <Grid item lg={3}>
                                <TextField fullWidth
                                           id="input-field-label-serie"
                                           label="Nom Serie"
                                           variant="outlined"
                                           onChange={handleChangeNomSerie}
                                           value={serieInfo.nom}
                                           error={errorForm.libelle}
                                           helperText={errorForm.libelle ? 'Veuillez renseigner le champs' : ''}
                                           required
                                />
                            </Grid>
                            <Grid item lg={7}>
                                <TextField fullWidth
                                           id="input-field-desciption-serie"
                                           label="Description Serie"
                                           variant="outlined"
                                           onChange={handleChangeDescriptionSerie}
                                           value={serieInfo.description}
                                           error={errorForm.description}
                                           helperText={errorForm.description ? 'Veuillez renseigner le champs' : ''}
                                           required
                                />
                            </Grid>
                            <Grid item lg={2}>
                                <TextField fullWidth
                                           id="input-field-desciption-serie"
                                           label="Prix série"
                                           variant="outlined"
                                           type="number"
                                           onChange={handleChangePrixSerie}
                                           inputProps={{min: 0}}
                                           value={serieInfo.prix}
                                           error={errorForm.prix}
                                           helperText={errorForm.prix ? 'Veuillez renseigner le champs' : ''}
                                           required
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <TextField fullWidth
                                           id="input-field-premier-dossard-serie"
                                           label="Premier dossard"
                                           variant="outlined"
                                           onChange={handleChangePremierDossard}
                                           type="number"
                                           inputProps={{min: 0}}
                                           value={serieInfo.premierDossard}
                                           error={errorForm.premierDossard}
                                           helperText={errorForm.premierDossard ? 'Veuillez renseigner le champs' : 'Indique le n° du premier dossard de cette série'}
                                           required
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <TextField fullWidth
                                           id="input-field-premier-dossard-serie"
                                           label="Maximum inscrits"
                                           variant="outlined"
                                           onChange={handleChangeMaximumInscrit}
                                           type="number"
                                           inputProps={{min: 0}}
                                           value={serieInfo.maximumInscrit}
                                           error={errorForm.maximumInscrit}
                                           helperText={errorForm.maximumInscrit ? 'Veuillez renseigner le champs' : 'Indique le nombre maximum d\'inscrits qui peuvent rouler dans cette série'}
                                           required
                                />
                            </Grid>
                            <Grid item lg={2}>
                                <TextField fullWidth
                                           id="input-field-premier-dossard-serie"
                                           label="Nombre de série"
                                           variant="outlined"
                                           onChange={handleChangeNombreSerie}
                                           type="number"
                                           inputProps={{min: 0}}
                                           value={serieInfo.nombreSerie}
                                           error={errorForm.nombreSerie}
                                           helperText={errorForm.nombreSerie ? 'Veuillez renseigner le champs' : ''}
                                           required
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <TextField fullWidth
                                           id="input-field-premier-dossard-serie"
                                           label="Score minimum"
                                           variant="outlined"
                                           onChange={handleChangeScoreMini}
                                           type="number"
                                           value={serieInfo.scoreMinimum}
                                           error={errorForm.scoreMinimum}
                                           helperText={errorForm.scoreMinimum ? 'Veuillez renseigner le champs' : 'Indique le score minimal pour entrer dans cette série'}
                                           required={!serieInfo.serieBenevole}
                                           disabled={serieInfo.serieBenevole}
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <TextField fullWidth
                                           id="input-field-premier-dossard-serie"
                                           label="Score maximum"
                                           variant="outlined"
                                           onChange={handleChangeScoreMaxi}
                                           type="number"
                                           value={serieInfo.scoreMaximum}
                                           error={errorForm.scoreMaximum}
                                           helperText={errorForm.scoreMaximum ? 'Veuillez renseigner le champs' : 'Indique le score maximal pour entrer dans cette série'}
                                           required={!serieInfo.serieBenevole}
                                           disabled={serieInfo.serieBenevole}
                                />
                            </Grid>
                            <Grid item lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel disabled={serieInfo.serieBenevole} required={!serieInfo.serieBenevole}
                                                error={errorForm.selectTypeInscription}>Type
                                        inscription</InputLabel>
                                    <Select
                                        id="demo-simple-select-helper"
                                        value={serieInfo.selectTypeInscription}
                                        label="Type inscription"
                                        onChange={handleChangeSelectInscriptionType}
                                        error={errorForm.selectTypeInscription}
                                        required={!serieInfo.serieBenevole}
                                        disabled={serieInfo.serieBenevole}
                                    >
                                        {props.listTypeInscription.map(type => {
                                            return <MenuItem key={type.id} value={type.id}>{type.libelle}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText
                                        disabled={serieInfo.serieBenevole}
                                        error={errorForm.selectTypeInscription}>{errorForm.selectTypeInscription ? 'Veuillez renseigner le champs' : 'Indique quel type d\'inscription est retenu pour cette série'}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item lg={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={serieInfo.serieBenevole}
                                                                       onChange={handleChangeSerieBenevole}/>}
                                                      label="Serie benevole"/>
                                </FormGroup>
                            </Grid>
                            <Grid item lg={6}>
                                <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
                                    <FormControl required error={errorForm.niveau}>
                                        <FormLabel>Niveau</FormLabel>
                                        <FormGroup>
                                            {serieInfo.niveau.map(niveau => {
                                                return <FormControlLabel key={niveau.id}
                                                                         control={<Checkbox checked={!!niveau.check}
                                                                                            name={niveau.libelle}
                                                                                            onClick={event => handleChangeNiveau(event, niveau.id)}/>}
                                                                         label={niveau.libelle}/>
                                            })}
                                        </FormGroup>
                                        {errorForm.niveau &&
                                            <FormHelperText error={errorForm.niveau}>Veuillez sélectionner au moins un
                                                élément</FormHelperText>}
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item lg={6}>
                                <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
                                    <FormControl required error={errorForm.typeMoto}>
                                        <FormLabel>Type de Moto</FormLabel>
                                        <FormGroup>
                                            {serieInfo.typeMoto.map(typeMoto => {
                                                return <FormControlLabel key={typeMoto.id} label={typeMoto.libelle}
                                                                         control={<Checkbox
                                                                             checked={!!typeMoto.check}
                                                                             name={typeMoto.libelle}
                                                                             onClick={event => handleChangeTypeMoto(event, typeMoto.id)}/>}/>
                                            })}
                                        </FormGroup>
                                        {errorForm.typeMoto &&
                                            <FormHelperText error={errorForm.typeMoto}>Veuillez sélectionner au moins un
                                                élément</FormHelperText>}
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                }
                {oldType &&
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <DataGrid
                                rows={props.data}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                checkboxSelection
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                                onRowSelectionModelChange={value => setSelectedType(value)}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            {(newType || oldType || props.editMode) &&
                <DialogActions>
                    {oldType &&
                        <Button disabled={selectedType.length !== 1 && oldType} onClick={handleTemplateSerie}
                                variant="contained">Template</Button>}
                    <Button variant="contained" onClick={handleAddSerie}
                            disabled={selectedType.length === 0 && oldType}>{props.editMode ? 'Enregistrer' : 'Ajouter'}</Button>
                    <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
                </DialogActions>
            }
        </Dialog>
    )
};