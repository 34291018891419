import {Fragment} from "react";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from "@mui/material";
import Grid from "@mui/material/Grid";

export default (props) => {

    const handleChangeCheckZorga = (event) => {
        props.onChange({champs: 'check', value: event.target.checked});
    };
    const handleChangeAssuranceZorga = (event) => {
        props.onChange({champs: 'assurance', value: event.target.value});
    };
    const handleChangeNumeroPlaque = (event) => {
        props.onChange({champs: 'serie', value: event.target.checked});
    };

    return (
        <Fragment>
            <Grid item xs={12} lg={12}>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup row>
                        <FormControlLabel control={<Switch checked={props.data.zorga}
                                                           onChange={handleChangeCheckZorga}/>}
                                          label="Serie de roulage pour les bénévoles"/>
                        {props.data.zorga &&
                            <FormControlLabel control={<Switch checked={props.data.numeroPlaqueIdentique}
                                                               onChange={handleChangeNumeroPlaque}/>}
                                              label="Numero de plaque identique a la série pour les rouleurs"/>}
                    </FormGroup>
                </FormControl>
            </Grid>
            {props.data.zorga &&
                <Grid item xs={12} lg={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-assurance-zorga-label">Assurance</InputLabel>
                                <Select
                                    labelId="select-assurance-zorga-label"
                                    id="select-assurance-zorga"
                                    value={props.data.assuranceZorgaOfferte}
                                    label="Assurance"
                                    onChange={handleChangeAssuranceZorga}
                                >
                                    <MenuItem value={'none'}><em>Pas d'assurance offerte</em></MenuItem>
                                    {
                                        props.assuranceList.map(assurance => {
                                            return <MenuItem key={assurance.id}
                                                             value={assurance.id}>{`${assurance.libellecourt} (${assurance.prix}€)`}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText>Assurance offerte pour les bénévoles</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
}