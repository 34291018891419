import {apiSlice} from "../../app/api/apiSlice";
const urlRepas = "repas";
const urlRepasDetail = "repasdetail";

export const repasApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllRepasEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlRepas}/list/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListRepasEvenement', ...result.id}, {type: 'ListRepasEvenement', id: 'LIST'}] :
                    [{type: 'ListRepasEvenement', id: 'LIST'}],
        }),
        addRepasDetail: builder.mutation({
            query: (data) => {
                const {evenementID, repas} = data;
                return {
                    url: `${urlRepasDetail}/`,
                    method: 'POST',
                    body: {
                        evenementid: evenementID,
                        libelle: repas.libelle,
                        prixpublic: repas.prixpublic,
                        prixcomplet: repas.prixcomplet,
                    }
                }
            },
            invalidatesTags: [{type: 'ListRepasEvenement', id: 'LIST'}],
        }),
        deleteRepasDetail: builder.mutation({
            query: (repasID) => {
                return {
                    url: `${urlRepasDetail}/${repasID}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListRepasEvenement', id: 'LIST'}],
        }),
        ajoutDateRepas: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlRepas}/`,
                    method: 'POST',
                    body: {
                        date: data.date,
                        repasdetailid: data.repasDetailID
                    }
                }
            },
            invalidatesTags: [{type: 'ListRepasEvenement', id: 'LIST'}],
        }),
        suppressionDateRepas: builder.mutation({
            query: (repasID) => {
                return {
                    url: `${urlRepas}/${repasID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListRepasEvenement', id: 'LIST'}],
        }),
        updateRepasDetail: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlRepasDetail}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libelle: data.libelle,
                        prixpublic: data.prixPublic,
                        prixcomplet: data.prixComplet,
                    }
                }
            },
            invalidatesTags: [{type: 'ListRepasEvenement', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllRepasEvenementQuery,
    useAddRepasDetailMutation,
    useDeleteRepasDetailMutation,
    useAjoutDateRepasMutation,
    useSuppressionDateRepasMutation,
    useUpdateRepasDetailMutation,
} = repasApiSlice