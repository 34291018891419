import {apiSlice} from "../../app/api/apiSlice";

const urlMoto = "moto";

export const motoApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllMotoUser: builder.query({
            query: (userID) => ({
                url: `${urlMoto}/list-user/${userID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'listAllMotoUser', ...result.id}, {type: 'listAllMotoUser', id: 'LIST'}] :
                    [{type: 'listAllMotoUser', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllMotoUserQuery,
} = motoApiSlice