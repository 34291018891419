import Grid from "@mui/material/Grid";
import {Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch} from "@mui/material";
import InformationPilote from "./InformationPilote";
import {Fragment} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ModalExtraPilote from "./ModalExtraPilote";
import {useState} from "react";

export default (props) => {

    const [showModal, setShowModal] = useState({
        modalCompteClient: {
            show: false,
            extraPiloteID: '',
            nom: '',
        },
    });

    const passagerSideCar = props.insciptionTypeData.inscriptionTypeId.length > 0 ?
        props.listInscriptionType.find(type => type.id === props.insciptionTypeData.inscriptionTypeId).side : false;

    const handleAddSecondGuidon = (idSecondGuidon, nom, prenom, mail) => {
        setShowModal({
            ...showModal,
            modalCompteClient: {
                show: true,
                extraPiloteID: idSecondGuidon,
                nom: nom,
                prenom: prenom,
                mail: mail
            }
        });
    };
    const handleAddPassagerSide = (idPassagerSide, nom) => {
        setShowModal({
            ...showModal,
            modalCompteClient: {
                show: true,
                extraPiloteID: idPassagerSide,
                nom: nom,
            }
        });
    };
    const handleOpenCloseModalCompteClient = (choice) => {
        setShowModal({
            ...showModal,
            modalCompteClient: {
                ...showModal.modalCompteClient,
                show: choice
            }
        });
    };
    const handleChangeInscriptionType = (event) => {
        props.updateInscriptionType('typeInscription', event.target.value);
    };
    const handleChangeInferno = (event) => {
        props.updateInscriptionType('inferno', event.target.checked);
    };
    const handleChangePartageGuidon = (event) => {
        props.updateInscriptionType('partageGuidon', event.target.checked);
    };
    const handleChangeNomSecondGuidon = (event) => {
        props.updateInscriptionType('secondGuidon-nom', event.target.value);
    };
    const handleChangePrenomSecondGuidon = (event) => {
        props.updateInscriptionType('secondGuidon-prenom', event.target.value);
    };
    const handleChangeMailSecondGuidon = (event) => {
        props.updateInscriptionType('secondGuidon-mail', event.target.value);
    };
    const handleChangePermisSecondGuidon = (event) => {
        props.updateInscriptionType('secondGuidon-permis', event.target.value);
    };
    const handleChangeLicenceSecondGuidon = (event) => {
        props.updateInscriptionType('secondGuidon-licence', event.target.value);
    };
    const handleChangeAssuranceSecondGuidon = (event) => {
        props.updateInscriptionType('secondGuidon-assurance', event.target.value);
    };
    const handleChangeNomPassagerSide = (event) => {
        props.updateInscriptionType('passagerSide-nom', event.target.value);
    };
    const handleChangePrenomPassagerSide = (event) => {
        props.updateInscriptionType('passagerSide-prenom', event.target.value);
    };
    const handleChangeLicencePassagerSide = (event) => {
        props.updateInscriptionType('passagerSide-licence', event.target.value);
    };
    const handleChangeAssurancePassagerSide = (event) => {
        props.updateInscriptionType('passagerSide-assurance', event.target.value);
    };
    const handleChangeMailPassagerSide = (event) => {
        props.updateInscriptionType('passagerSide-mail', event.target.value);
    };

    return (<Grid container alignItems="center" spacing={2}>
        <Grid item xs={5} lg={5}>
            <FormControl fullWidth>
                <InputLabel required id="inscription-dossier-select">Inscription</InputLabel>
                <Select
                    required
                    labelId="inscription-dossier-select-label"
                    id="inscription-dossier-select"
                    value={props.insciptionTypeData.inscriptionTypeId}
                    label="Inscription"
                    onChange={handleChangeInscriptionType}
                >
                    {props.listInscriptionType.map(assurance => {
                        return <MenuItem key={assurance.id}
                                         value={assurance.id}>{assurance.libelle}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={7} lg={7}>
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        control={<Switch checked={props.insciptionTypeData.inferno}
                                         onChange={handleChangeInferno}/>}
                        label="Participation au 200 mètres départ arrêter"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={<Switch checked={props.insciptionTypeData.partageGuidon}
                                         onChange={handleChangePartageGuidon}/>}
                        label="Partage de guidon"
                        labelPlacement="end"
                    />
                </FormGroup>
            </FormControl>
        </Grid>
        {props.insciptionTypeData.partageGuidon &&
            <Fragment>
                <Grid item xs={12} lg={12}>
                    <Divider>Second Guidon</Divider>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button variant="outlined"
                                        disabled={props.insciptionTypeData.secondGuidon.id === null}
                                        onClick={() => handleAddSecondGuidon(
                                            props.insciptionTypeData.secondGuidon.id,
                                            props.insciptionTypeData.secondGuidon.nom,
                                            props.insciptionTypeData.secondGuidon.prenom,
                                            props.insciptionTypeData.secondGuidon.mail,
                                        )}>
                                    Associer Compte</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {props.insciptionTypeData.secondGuidon.id_utilisateur !== null ?
                    <Grid item xs={12} lg={12}>
                        <InformationPilote
                            utilisateur={props.insciptionTypeData.secondGuidon.utilisateur}/>
                    </Grid> :
                    <Fragment>
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        value={props.insciptionTypeData.secondGuidon.nom}
                                        id="outlined-nom-pilote"
                                        label="Nom"
                                        variant="outlined"
                                        onChange={handleChangeNomSecondGuidon}/>
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        value={props.insciptionTypeData.secondGuidon.prenom}
                                        id="outlined-prenom-pilote"
                                        label="Prenom"
                                        variant="outlined"
                                        onChange={handleChangePrenomSecondGuidon}/>
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        value={props.insciptionTypeData.secondGuidon.mail}
                                        label="Email"
                                        variant="outlined"
                                        onChange={handleChangeMailSecondGuidon}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>}
                <Grid item xs={4} sm={4}>
                    <TextField
                        name="Numero de permis / CASM / BSR"
                        fullWidth
                        id="numero_permis"
                        label="Numero de permis / CASM / BSR"
                        value={props.insciptionTypeData.secondGuidon.numeropermis}
                        onChange={handleChangePermisSecondGuidon}
                        required
                    />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField
                        name="Numero licence"
                        fullWidth
                        id="numero_licence"
                        label="Numero Licence"
                        onChange={handleChangeLicenceSecondGuidon}
                        value={props.insciptionTypeData.secondGuidon.licence}
                    />
                </Grid>
                <Grid item xs={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Assurance Corporelle</InputLabel>
                        <Select
                            labelId="assurance-dossier-select"
                            id="assurance-dossier-select"
                            value={props.insciptionTypeData.secondGuidon.id_assurance}
                            label="Assurance Corporelle"
                            onChange={handleChangeAssuranceSecondGuidon}
                        >
                            {props.listAssurance.map(assurance => {
                                return <MenuItem key={assurance.id}
                                                 value={assurance.id}>{assurance.libellecourt}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Fragment>}
        {passagerSideCar &&
            <Fragment>
                <Grid item xs={12} lg={12}>
                    <Divider>Passager Side-Car</Divider>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button variant="outlined"
                                        disabled={props.insciptionTypeData.passagerSide.id === null}
                                        onClick={() => handleAddPassagerSide(props.insciptionTypeData.passagerSide.id, props.insciptionTypeData.passagerSide.nom)}>
                                    Associer Compte</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {props.insciptionTypeData.passagerSide.id_utilisateur !== null ?
                    <Grid item xs={12} lg={12}>
                        <InformationPilote
                            utilisateur={props.insciptionTypeData.passagerSide.utilisateur}/>
                    </Grid> :
                    <Fragment>
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        value={props.insciptionTypeData.passagerSide.nom}
                                        id="outlined-nom-pilote"
                                        label="Nom"
                                        variant="outlined"
                                        onChange={handleChangeNomPassagerSide}/>
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        value={props.insciptionTypeData.passagerSide.prenom}
                                        id="outlined-prenom-pilote"
                                        label="Prenom"
                                        variant="outlined"
                                        onChange={handleChangePrenomPassagerSide}/>
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        value={props.insciptionTypeData.passagerSide.mail}
                                        label="Email"
                                        variant="outlined"
                                        onChange={handleChangeMailPassagerSide}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>}
                <Grid item xs={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={4} sm={4}>
                            <TextField
                                name="Numero licence"
                                fullWidth
                                id="numero_licence"
                                label="Numero Licence"
                                onChange={handleChangeLicencePassagerSide}
                                value={props.insciptionTypeData.passagerSide.licence}
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Assurance Corporelle</InputLabel>
                                <Select
                                    labelId="assurance-dossier-select"
                                    id="assurance-dossier-select"
                                    value={props.insciptionTypeData.passagerSide.id_assurance}
                                    label="Assurance Corporelle"
                                    onChange={handleChangeAssurancePassagerSide}
                                >
                                    {props.listAssurance.map(assurance => {
                                        return <MenuItem key={assurance.id}
                                                         value={assurance.id}>{assurance.libellecourt}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>}
        {showModal.modalCompteClient.show &&
            <ModalExtraPilote
                extraPiloteID={showModal.modalCompteClient.extraPiloteID}
                compteClient={props.compteClientID}
                nomCompteClient={showModal.modalCompteClient.nom}
                prenomCompteClient={showModal.modalCompteClient.prenom}
                mailCompteClient={showModal.modalCompteClient.mail}
                inscriptionID={props.inscriptionID}
                closeModal={handleOpenCloseModalCompteClient}/>}
    </Grid>)
}