import {Dialog, DialogContent, DialogTitle, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useState} from "react";
import Button from "@mui/material/Button";

export default (props) => {

    const [pagesize, setPagesize] = useState(10);
    const [selectedSerie, setSelectedSerie] = useState([]);
    
    const handleClose = () => {
        props.closeModal(false);
    };

    const handleSaveSerie = () => {
        props.saveSerie(selectedSerie[0]);
    }

    const columns = [
        {
            field: 'libelle',
            headerName: 'Nom',
            width: 200,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'premierdossard',
            headerName: 'Premier Dossard',
            width: 130,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'nbacces',
            headerName: 'Nombre Accès',
            width: 120,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'scoremin',
            headerName: 'Score Min',
            width: 100,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'scoremax',
            headerName: 'Score Max',
            width: 100,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }
    ];

    return (
        <Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Associer Serie</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <DataGrid
                            rows={props.listSerie}
                            columns={columns}
                            pageSize={pagesize}
                            rowsPerPageOptions={[5, 10, 50]}
                            experimentalFeatures={{newEditingApi: true}}
                            autoHeight
                            checkboxSelection
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            onPageSizeChange={pageSize => setPagesize(pageSize)}
                            onRowSelectionModelChange={value => setSelectedSerie(value)}
                        />
                    </Grid>
                    <Grid item xl={12} lg={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Stack direction="row" spacing={2}>
                                <Button disabled={selectedSerie.length !== 1} onClick={handleSaveSerie}
                                        variant="contained">Associer</Button>
                                <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}