import {Fragment, useEffect, useState} from "react";
import {useSelectEvenementQuery} from "../../../feature/utilisateur/utilisateurApiSlice";
import {useListAllEncaissementInscriptionQuery} from "../../../feature/inscription/inscriptionApiSlice";
import {Backdrop, CircularProgress} from "@mui/material";
import {useListSerieEvenementQuery} from "../../../feature/serie/serieApiSlice";
import ListEncaissementInscription from "../ListEncaissementInscription";

export default () => {

    const [skipFetch, setSkipFetch] = useState(true);
    const [evenementID, setEvenementID] = useState('');

    const {
        data: currentSelectedEvenement,
        isSuccess: SucessCurrentSelectedEvenement,
        isError: isErreurCurrentSelectedEvenement
    } = useSelectEvenementQuery(null);

    const {
        data: listAllEncaissement,
        isSuccess: SucessListAllEncaissement,
        isError: isErreurListAllEncaissement
    } = useListAllEncaissementInscriptionQuery(evenementID, {skip: skipFetch});

    const {
        data: listSerie,
        isSuccess: SucessListSerie,
        isError: isErreurListSerie
    } = useListSerieEvenementQuery(evenementID, {skip: skipFetch});

    useEffect(() => {
        if (SucessCurrentSelectedEvenement && currentSelectedEvenement !== null) {
            setEvenementID(currentSelectedEvenement.id);
            setSkipFetch(false);
        }
    }, [SucessCurrentSelectedEvenement, currentSelectedEvenement]);

    const allSuccessLoad = SucessListAllEncaissement && SucessCurrentSelectedEvenement && SucessListSerie;

    const erreurGlobal = isErreurListAllEncaissement || isErreurCurrentSelectedEvenement || isErreurListSerie;

    return (
        <Fragment>
            {!allSuccessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop> :
                <ListEncaissementInscription
                    evenementID={evenementID}
                    listEncaissement={listAllEncaissement}
                    listSerie={listSerie}/>
            }
        </Fragment>
    )
}