const verifErreurFormulaire = (stateFormError) => {
    let arrayError = [];
    for (let i in stateFormError) {
        if (stateFormError[i]) {
            arrayError.push(true);
        }
    }
    return arrayError.length === 0;
};
const verifEmailFormat = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};
const verifAlpha = (value) => {
    const regex = new RegExp("^[a-zA-Z0-9\\\\,\\\\@\\\\+\\\\/\\\\:\\\\.\\\\'\\\\-\\\\\n ]*$");
    return regex.test(value);
};
const verifEmptyEntry = (entry) => {
    if (typeof entry !== 'number') {
        return entry.trim().length === 0;
    } else
        return false;
};
const generateExtraPilote = (type) => {
    return {
        id: null,
        nom: '',
        prenom: '',
        numeropermis: '',
        licence: '',
        id_assurance: '',
        mail: '',
        id_inscription: null,
        id_utilisateur: null,
        type: type,
        utilisateur: null
    }
};
const extractExtraPilote = (array, type) => {
    let arrayFilter = array.filter(extra => extra.type === type);
    if (arrayFilter.length > 0 && arrayFilter.length === 1) {
        return arrayFilter.map(val => {
            return {
                ...val,
                nom: val.nom !== null ? val.nom : '',
                prenom: val.prenom !== null ? val.prenom : '',
                licence: val.licence !== null ? val.licence : '',
                numeropermis: val.numeropermis !== null ? val.numeropermis : '',
                id_assurance: val.id_assurance !== null ? val.id_assurance : '',
                mail: val.mail !== null ? val.mail : '',
            }
        });
    } else {
        return [{...generateExtraPilote(type)}];
    }
};
const formatExtraPilote = (extraPilote) => {
    if (extraPilote !== null) {
        return {
            ...extraPilote,
            nom: extraPilote.nom.length > 0 ? extraPilote.nom : null,
            prenom: extraPilote.prenom.length > 0 ? extraPilote.prenom : null,
            licence: extraPilote.licence.length > 0 ? extraPilote.licence : null,
            numeropermis: extraPilote.numeropermis.length > 0 ? extraPilote.numeropermis : null,
            id_assurance: extraPilote.id_assurance.length > 0 ? extraPilote.id_assurance : null,
            mail: extraPilote.mail.length > 0 ? extraPilote.mail : null,
        }
    } else {
        return null;
    }
};
const verifErreurFormulaireObjectComplexe = (stateFormError) => {
    let arrayError = [];
    for (let i in stateFormError) {
        if (stateFormError[i].error) {
            arrayError.push(true);
        }
    }
    return arrayError.length === 0;
};

export default {
    verifErreurFormulaire,
    verifEmailFormat,
    verifAlpha,
    verifEmptyEntry,
    generateExtraPilote,
    extractExtraPilote,
    formatExtraPilote,
    verifErreurFormulaireObjectComplexe
}