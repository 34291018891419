import {apiSlice} from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: `/auth/login/`,
                method: 'POST',
                body: {...credentials}
            })
        }),
        resetPasswordTemp: builder.mutation({
            query: (data) => ({
                url: `/auth/reset-password/`,
                method: 'POST',
                body: {
                    email: data.email,
                    oldpassword: data.oldPassword,
                    newpassword: data.newPassword
                }
            })
        }),
    })
})

export const {
    useLoginMutation,
    useResetPasswordTempMutation,
} = authApiSlice