import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormHelperText,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import Box from "@mui/material/Box";
import {useListAllEvenementsQuery} from "../../feature/evenement/evenementApiSlice";
import * as React from "react";
import {useEffect} from "react";
import Button from "@mui/material/Button";
import {useSaveEvenementSelectMutation} from "../../feature/utilisateur/utilisateurApiSlice";

export default (props) => {

    const [selectEvenement, setSelectEvenement] = React.useState('');
    const [error, setError] = React.useState(false);

    useEffect(() => {
        if (props.selectEvenement != null) {
            setSelectEvenement(props.selectEvenement);
        }
    }, []);

    const [saveEvenementSelect] = useSaveEvenementSelectMutation();

    const {
        data: listEvenements,
        isSuccess: SucessListEvenements,
        isError: isErreurListEvenements
    } = useListAllEvenementsQuery();

    const handleClose = () => {
        props.closeWindows(false);
    };

    const handleChangeEvenement = (event) => {
        setSelectEvenement(event.target.value);
    }

    const handleSaveEvenement = () => {
        if (selectEvenement.length > 0) {
            saveEvenementSelect(selectEvenement).unwrap()
                .then(() => {
                    handleClose();
                })
        } else {
            setError(true);
        }
    }

    return (
        <Dialog open={true} fullWidth maxWidth="xs" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Sélectionner Evenement</DialogTitle>
            </Box>
            <DialogContent>
                {SucessListEvenements &&
                    <FormControl fullWidth>
                        <InputLabel required id="demo-simple-select-label">Evenement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectEvenement}
                            label="Evenement"
                            onChange={handleChangeEvenement}
                            error={error}
                            required
                        >
                            {listEvenements.map(value => {
                                return <MenuItem key={value.id} value={value.id}>{value.libelle}</MenuItem>
                            })}
                        </Select>
                        {error && <FormHelperText error={true}>Veuillez selectionner un evenement</FormHelperText>}
                    </FormControl>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSaveEvenement}>Enregistrer</Button>
                <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}