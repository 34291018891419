import Box from "@mui/material/Box";
import {
    Backdrop, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    TextField
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {Fragment, useState} from "react";
import {useEffect} from "react";
import {
    useGetFactureDetaillerQuery,
    useGetFactureInfoQuery,
    useValiderFactureMutation
} from "../../feature/facture/factureApiSlice";
import TypeDePaiement from "../../Constants/TypeDePaiement";
import formUtils from "../../utils/formUtils";
import Alert from "@mui/material/Alert";
import ArticlesEncaissement from "./ArticlesEncaissement";

export default (props) => {

    const [skipFetch, setSkipFetch] = useState(true);
    const [factureID, setFactureID] = useState('');
    const [paiementType, setPaiementType] = useState('');
    const [montantPaiement, setMontantPaiement] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [sucessLoad, setSucessLoad] = useState(false);
    const [errorForm, setErrorForm] = useState({
        typePaiement: false,
        montant: false
    });
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const [validerFacture, {isLoading: isLoadingValiderFacture}] = useValiderFactureMutation();

    const handleCLose = () => {
        props.closeModal(false);
    };

    useEffect(() => {
        if (props.factureID !== null) {
            setFactureID(props.factureID);
            setSkipFetch(false);
        }
    }, []);

    const {
        data: factureDetail,
        isSuccess: SucessFactureDetail,
        isError: isErreurFactureDetail,
        error: errorFactureDetail
    } = useGetFactureDetaillerQuery({factureID: factureID, userID: props.userID}, {skip: skipFetch});

    const {
        data: factureInfo,
        isSuccess: SucessFactureInfo,
        isError: isErreurFactureInfo,
        error: errorFactureInfo
    } = useGetFactureInfoQuery(factureID, {skip: skipFetch});

    const allSucess = SucessFactureInfo && SucessFactureDetail;

    const AllError = isErreurFactureDetail || isErreurFactureInfo;

    useEffect(() => {
        if (allSucess) {
            setCommentaire(factureDetail.complement !== null ? factureDetail.complement : '');
            setPaiementType(factureInfo.paiement !== null ? factureInfo.paiement : '');
            setMontantPaiement(factureDetail.facture.total);
            setSucessLoad(true);
        }
    }, [allSucess]);

    useEffect(() => {
        if (isErreurFactureDetail) {
            if (errorFactureDetail.status === 406) {
                setErrorAlertMessage(errorFactureDetail.data);
                setErrorAlert(true);
            } else {
                setErrorAlertMessage('Erreur Technique veuillez essayer ultérieuement');
                setErrorAlert(true);
            }
        }
        if (isErreurFactureInfo) {
            if (errorFactureInfo.status === 406) {
                setErrorAlertMessage(errorFactureInfo.data);
                setErrorAlert(true);
            } else {
                setErrorAlertMessage('Erreur Technique veuillez essayer ultérieuement');
                setErrorAlert(true);
            }
        }
    }, [AllError]);

    const handleChangePaiementType = (event) => {
        setPaiementType(event.target.value);
    };

    const handleValiderPaiement = async () => {
        setErrorAlert(false);
        if (formUtils.verifErreurFormulaire(validerFormulaire())) {
            await validerFacture({
                factureID: factureID,
                montant: montantPaiement,
                paiementType: paiementType,
                commentaire: commentaire
            }).unwrap()
                .then(() => {
                    props.sucessSave('Enregistrement effectuer avec succès !');
                    props.closeModal(false);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieuement');
                        setErrorAlert(true);
                    }
                })
        }
    };

    const validerFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            typePaiement: paiementType.length === 0,
            montant: montantPaiement.length === 0
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    }

    return (
        <Fragment>
            {!sucessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <Dialog open={true} fullWidth maxWidth="sm">
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <DialogTitle>Encaissement</DialogTitle>
                    </Box>
                    <DialogContent>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <ArticlesEncaissement items={factureDetail.facture.items}
                                                      total={factureDetail.facture.total}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={5} sm={5}>
                                        <FormControl fullWidth>
                                            <InputLabel error={errorForm.typePaiement} required
                                                        id="demo-simple-select-label">Type de
                                                paiement</InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={paiementType}
                                                label="Type de paiement"
                                                onChange={handleChangePaiementType}
                                                error={errorForm.typePaiement}
                                            >
                                                {TypeDePaiement.map(type => {
                                                    return <MenuItem key={type} value={type}>{type}</MenuItem>
                                                })}
                                            </Select>
                                            {errorForm.typePaiement &&
                                                <FormHelperText error={errorForm.typePaiement}>Selectionner type de
                                                    paiement</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id="outlined-number"
                                            label="Montant"
                                            type="number"
                                            value={montantPaiement}
                                            onChange={(event) => setMontantPaiement(event.target.value)}
                                            error={errorForm.montant}
                                            fullWidth
                                            helperText={errorForm.montant ? 'Saisir un montant' : ''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="outlined-number"
                                    label="Commentaire"
                                    value={commentaire}
                                    onChange={(event) => setCommentaire(event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            {errorAlert && <Grid item xs={12} sm={12}>
                                <Alert severity="error">{errorAlertMessage}</Alert>
                            </Grid>}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton loading={isLoadingValiderFacture} variant="contained"
                                       onClick={handleValiderPaiement}>valider</LoadingButton>
                        <Button variant="contained" color="error" onClick={() => handleCLose()}>annuler
                            encaissement</Button>
                    </DialogActions>
                </Dialog>}
        </Fragment>
    )
}