import {Fragment, useEffect, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import ListInscription from "../ListInscription";
import {useSelectEvenementQuery} from "../../../feature/utilisateur/utilisateurApiSlice";
import {useListAllInscriptionQuery} from "../../../feature/inscription/inscriptionApiSlice";
import {useListSerieEvenementQuery} from "../../../feature/serie/serieApiSlice";

export default () => {

    const [skipFetch, setSkipFetch] = useState(true);
    const [evenementID, setEvenementID] = useState('');

    const {
        data: currentSelectedEvenement,
        isSuccess: SucessCurrentSelectedEvenement,
        isError: isErreurCurrentSelectedEvenement
    } = useSelectEvenementQuery(null);

    const {
        data: listAllInsciption,
        isSuccess: SucessListAllInsciption,
        isError: isErreurListAllInsciption
    } = useListAllInscriptionQuery(evenementID, {skip: skipFetch});

    const {
        data: listSerie,
        isSuccess: SucessListSerie,
        isError: isErreurListSerie
    } = useListSerieEvenementQuery(evenementID, {skip: skipFetch});


    useEffect(() => {
        if (SucessCurrentSelectedEvenement && currentSelectedEvenement !== null) {
            setEvenementID(currentSelectedEvenement.id);
            setSkipFetch(false);
        }
    }, [SucessCurrentSelectedEvenement, currentSelectedEvenement]);

    const allSuccessLoad = SucessListSerie && SucessListAllInsciption && SucessCurrentSelectedEvenement;

    return (
        <Fragment>
            {!allSuccessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop> :
                <ListInscription
                    evenementID={evenementID}
                    listInscription={listAllInsciption}
                    listSerie={listSerie}/>
            }
        </Fragment>
    )
}