import Paper from "@mui/material/Paper";
import {Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CardStatutDossier from "../gestionInscription/CardStatutDossier";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";
import VerifiedIcon from '@mui/icons-material/Verified';
import IconButton from "@mui/material/IconButton";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import Grid from "@mui/material/Grid";
import * as Constants from "../../Constants/ContantesDossier";

export default (props) => {

    const {dossierRecherche} = props;
    const listDossierRouleur = dossierRecherche.listRouleur;

    {/*//TODO quand dossier benevole seras fais*/
    }
    const listDossierBenevole = dossierRecherche.listBenevole;

    const navigate = useNavigate();

    const lienVersDossierRouleur = (idDossier) => {
        navigate(`/inscription/${idDossier}`);
    };

    const validerDossier = (barCode) => {
        props.validerDossier(barCode);
    }

    return (
        <Grid justifyContent="center" container spacing={2}>
            <Grid item xs={12} lg={12} md={12}>
                <Divider sx={{fontWeight: 'bold'}}>Dossier Rouleur</Divider>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: 'bold'}} align="center">Numero Dossier</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="center">Nom</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="center">Prénom</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="center">Statut</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="center">Série</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listDossierRouleur.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row" align="center">
                                        <Link sx={{fontWeight: 'bold'}}
                                              onClick={() => lienVersDossierRouleur(row.id)}>{row.barcode}</Link>
                                    </TableCell>
                                    <TableCell align="center">{row.utilisateur.nom}</TableCell>
                                    <TableCell align="center">{row.utilisateur.prenom}</TableCell>
                                    <TableCell align="center" sx={{textTransform: 'lowercase'}}><CardStatutDossier
                                        statut={row.statut}/></TableCell>
                                    <TableCell
                                        align="center">{row.serie !== null ? `${row.serie.libelle} (${row.serie.premierdossard})` :
                                        <IconButton aria-label="valider dossier" disabled={true}>
                                            <DoNotDisturbAltIcon/>
                                        </IconButton>}</TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="valider dossier" color="success"
                                                    // disabled={row.statut !== Constants.STATUT_DOSSIER_PAYER}
                                                    onClick={() => {
                                                        validerDossier(row.barcode)
                                                    }}>
                                            <VerifiedIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
                <Divider sx={{fontWeight: 'bold'}}>Dossier Bénévole</Divider>
            </Grid>
        </Grid>
    )
}