import {Fragment} from "react";
import {createTheme, Divider, Snackbar} from "@mui/material";
import {useEffect} from "react";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import ContactDossier from "../../components/gestionInscription/ContactDossier";
import InformationDossier from "../../components/gestionInscription/InformationDossier";
import ExperienceDossier from "../../components/gestionInscription/ExperienceDossier";
import AssuranceDossier from "../../components/gestionInscription/AssuranceDossier";
import MotoDossier from "../../components/gestionInscription/MotoDossier";
import ModalMoto from "../../components/gestionInscription/ModalMoto";
import InformationPilote from "../../components/gestionInscription/InformationPilote";
import CardStatutDossier from "../../components/gestionInscription/CardStatutDossier";
import InscriptionTypeDossier from "../../components/gestionInscription/InscriptionTypeDossier";
import formUtils from "../../utils/formUtils";
import GroupePilote from "../../components/gestionInscription/GroupePilote";
import ValidationDossier from "../../components/gestionInscription/ValidationDossier";
import {
    useAbandonInscriptionMutation,
    useAnnulerInscriptionMutation,
    useAssocierSerieInscriptionMutation,
    useChangerDossardInscriptionMutation,
    useConfirmerInscriptionMutation, useDupliquerInscriptionMutation,
    useEnregistrerInscriptionMutation,
    useInvaliderInscriptionMutation,
    useListAttenteInscriptionMutation, useModifierMotifDossierInvalideMutation, usePresentInscriptionMutation,
    useRefusInscriptionMutation,
    useValiderInscriptionMutation
} from "../../feature/inscription/inscriptionApiSlice";
import Alert from "@mui/material/Alert";
import requestContructor from "../../utils/requestContructor";
import ModalHistorique from "../../components/gestionInscription/ModalHistorique";
import * as Constants from "../../Constants/ContantesDossier";
import SerieDossier from "../../components/gestionInscription/SerieDossier";
import ModalSerieDossier from "../../components/gestionInscription/ModalSerieDossier";
import HistoriqueDate from "../../components/gestionInscription/HistoriqueDate";
import ComplementDossier from "../../components/gestionInscription/ComplementDossier";
import ModalChangementNumeroDossierInscription
    from "../../components/gestionInscription/ModalChangementNumeroDossierInscription";
import {useNavigate} from "react-router-dom";
import ModalAjoutMoto from "../../components/gestionInscription/ModalAjoutMoto";
import ModalMotifDossierInvalid from "../../components/gestionInscription/ModalMotifDossierInvalid";
import MotifDossierIncomplet from "../../components/gestionInscription/MotifDossierIncomplet";

const theme = createTheme();

export default (props) => {

    const [sucessAlert, setSucessAlert] = useState(false);
    const [sucessAlertMessage, setSucessAlertMessage] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const [enregistrerInscription, {isLoading: isLoadingEnregistrerInscription},] = useEnregistrerInscriptionMutation();
    const [validerInscription, {isLoading: isLoadingValiderInscription}] = useValiderInscriptionMutation();
    const [associerSerieInscription] = useAssocierSerieInscriptionMutation();
    const [confirmerInscription, {isLoading: isLoadingConfirmerInscription}] = useConfirmerInscriptionMutation();
    const [invaliderInscription, {isLoading: isLoadingInvaliderInscription}] = useInvaliderInscriptionMutation();
    const [changerDossardInscription, {isLoading: isChangerDossardInscription}] = useChangerDossardInscriptionMutation();
    const [listAttenteInscription, {isLoading: isLoadingListAttenteInscription}] = useListAttenteInscriptionMutation();
    const [refusInscription, {isLoading: isLoadingRefusInscription}] = useRefusInscriptionMutation();
    const [annulerInscription, {isLoading: isLoadingAnnulerInscription}] = useAnnulerInscriptionMutation();
    const [dupliquerInscription, {isLoading: isLoadingDupliquerInscription}] = useDupliquerInscriptionMutation();
    const [modifierMotifDossierInvalide, {isLoading: isLoadingModifierMotifDossierInvalide}] = useModifierMotifDossierInvalideMutation();
    const [presentInscription, {isLoading: isLoadingPresentInscription}] = usePresentInscriptionMutation();
    const [abandonInscription, {isLoading: isLoadingAbandonInscription}] = useAbandonInscriptionMutation();

    const loadingChangementStatut = isLoadingConfirmerInscription || isLoadingValiderInscription ||
        isLoadingInvaliderInscription || isLoadingListAttenteInscription || isLoadingRefusInscription ||
        isLoadingAnnulerInscription || isLoadingDupliquerInscription || isLoadingModifierMotifDossierInvalide ||
        isLoadingPresentInscription || isLoadingAbandonInscription;

    const initialState = {
        id: props.dossierInsciption.id,
        barcode: props.dossierInsciption.barcode,
        statut: props.dossierInsciption.statut,
        utilisateur: props.dossierInsciption.utilisateur,
        dossard: props.dossierInsciption.dossard,
        suiviMarshall: props.dossierInsciption.suivimarshall !== null ? props.dossierInsciption.suivimarshall : false,
        commentaire: props.dossierInsciption.commentutil !== null ? props.dossierInsciption.commentutil : '',
        bapteme: props.dossierInsciption.bapteme !== null ? props.dossierInsciption.bapteme : false,
        incident: props.dossierInsciption.incident !== null ? props.dossierInsciption.incident : false,
        contact: {
            nom: props.dossierInsciption.nomcontact != null ? props.dossierInsciption.nomcontact : '',
            prenom: props.dossierInsciption.prenomcontact != null ? props.dossierInsciption.prenomcontact : '',
            numero: props.dossierInsciption.numerocontact != null ? props.dossierInsciption.numerocontact : ''
        },
        information: {
            numeropermis: props.dossierInsciption.numeropermis != null ? props.dossierInsciption.numeropermis : '',
            numerolicence: props.dossierInsciption.numerolicence != null ? props.dossierInsciption.numerolicence : ''
        },
        experience: {
            experienceID: props.dossierInsciption.id_experience != null ? props.dossierInsciption.id_experience : '',
            experienceRoulage: props.dossierInsciption.circuitfavoris != null ? props.dossierInsciption.circuitfavoris : '',
            competition: props.dossierInsciption.competition != null ? props.dossierInsciption.competition : false,
            stage: props.dossierInsciption.stage != null ? props.dossierInsciption.stage : false,
            oldParticipation: props.dossierInsciption.dejapresent != null ? props.dossierInsciption.dejapresent : false
        },
        assurance: {
            assuranceID: props.dossierInsciption.id_assurance != null ? props.dossierInsciption.id_assurance : ''
        },
        moto: [...props.dossierInsciption.motos],
        inscriptionType: {
            inscriptionTypeId: props.dossierInsciption.id_inscriptiontype !== null ? props.dossierInsciption.id_inscriptiontype : '',
            inferno: props.dossierInsciption.inferno != null ? props.dossierInsciption.inferno : false,
            partageGuidon: props.dossierInsciption.inscription_extra_pilotes.length > 0 ? formUtils.extractExtraPilote(props.dossierInsciption.inscription_extra_pilotes, 'SECOND')[0].id !== null : false,
            secondGuidon: props.dossierInsciption.inscription_extra_pilotes.length > 0 ? formUtils.extractExtraPilote(props.dossierInsciption.inscription_extra_pilotes, 'SECOND')[0] : formUtils.generateExtraPilote('SECOND'),
            passagerSide: props.dossierInsciption.inscription_extra_pilotes.length > 0 ? formUtils.extractExtraPilote(props.dossierInsciption.inscription_extra_pilotes, 'SIDE')[0] : formUtils.generateExtraPilote('SIDE'),
        },
        groupePilote: {
            id: props.dossierInsciption.groupe_pilote !== null ? props.dossierInsciption.groupe_pilote.id : '',
            libelle: props.dossierInsciption.groupe_pilote !== null ? props.dossierInsciption.groupe_pilote.libelle : '',
            temp: props.dossierInsciption.groupe_pilote !== null ? props.dossierInsciption.groupe_pilote.temp : false,
            benevoles: props.dossierInsciption.groupe_pilote !== null ? props.dossierInsciption.groupe_pilote.benevoles : [],
            inscriptions: props.dossierInsciption.groupe_pilote !== null ? props.dossierInsciption.groupe_pilote.inscriptions : [],
        },
        historique: props.dossierInsciption.trace_inscriptions.length > 0 ? props.dossierInsciption.trace_inscriptions : [],
        serie: {
            id: props.dossierInsciption.serie !== null ? props.dossierInsciption.serie.id : '',
            libelle: props.dossierInsciption.serie !== null ? props.dossierInsciption.serie.libelle : '',
            premierdossard: props.dossierInsciption.serie !== null ? props.dossierInsciption.serie.premierdossard : '',
            prix: props.dossierInsciption.serie !== null ? props.dossierInsciption.serie.prix : '',
            nbmax: props.dossierInsciption.serie !== null ? props.dossierInsciption.serie.nbmax : '',
            nombreDossier: props.dossierInsciption.serie !== null ? props.dossierInsciption.serie.nombreDossier : 0,
            scorePilote: props.dossierInsciption.serie !== null ? props.dossierInsciption.serie.scorePilote : 0,
        },
        motifDossierInvalide: props.dossierInsciption.motifInvalid !== null ? props.dossierInsciption.motifInvalid : null,
        dateHistorique: {
            dateValidation: props.dossierInsciption.datevalidation,
            dateConfirmation: props.dossierInsciption.dateconfirmation,
            datePaiement: props.dossierInsciption.datepaiement,
            dateRelance: props.dossierInsciption.daterelance,
            dateAttente: props.dossierInsciption.dateattente,
            dateRefus: props.dossierInsciption.daterefus,
            dateAnnulation: props.dossierInsciption.dateannulation,
            dateInscription: props.dossierInsciption.dateinscription,
            dateCreation: props.dossierInsciption.createdAt,
            datePresence: props.dossierInsciption.datepresence,
            dateAbandon: props.dossierInsciption.dateabandon,
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Inscription';
    }, []);

    const dataEvenement = props.dataEvenement;
    useEffect(() => {
        setInscriptionData(initialState);
    }, [props.dossierInsciption]);

    const [inscriptionData, setInscriptionData] = useState(initialState);
    const [showModal, setShowModal] = useState({
        modalMoto: {
            show: false, motoData: {}
        }, modalHistorique: {
            show: false,
        }, modalSerie: {
            show: false
        }, modalChangementDossard: {
            show: false
        }, modalAjoutMoto: {
            show: false
        }, modalMotifDossierInvalid: {
            show: false
        }
    });

    const handleChangeInfoContact = (champs, data) => {
        setInscriptionData({
            ...inscriptionData, contact: {
                nom: champs === 'nom' ? data : inscriptionData.contact.nom,
                prenom: champs === 'prenom' ? data : inscriptionData.contact.prenom,
                numero: champs === 'numero' ? data : inscriptionData.contact.numero,
            }
        });
    };
    const handleChangeInformation = (champs, data) => {
        setInscriptionData({
            ...inscriptionData, information: {
                numeropermis: champs === 'permis' ? data : inscriptionData.information.numeropermis,
                numerolicence: champs === 'licence' ? data : inscriptionData.information.numerolicence,
            }
        });
    };
    const handleChangeExperience = (champs, data) => {
        setInscriptionData({
            ...inscriptionData, experience: {
                experienceID: champs === 'experience' ? data : inscriptionData.experience.experienceID,
                stage: champs === 'stage' ? data : inscriptionData.experience.stage,
                competition: champs === 'competition' ? data : inscriptionData.experience.competition,
                oldParticipation: champs === 'participation' ? data : inscriptionData.experience.oldParticipation,
                experienceRoulage: champs === 'roulage' ? data : inscriptionData.experience.experienceRoulage,
            }
        });
    };
    const handleChangeAssurance = (data) => {
        setInscriptionData({
            ...inscriptionData, assurance: {
                assuranceID: data,
            }
        });
    };
    const handleChangeMoto = (champs, data) => {
        if (champs === 'validation') {
            setInscriptionData({
                ...inscriptionData, moto: [...inscriptionData.moto.map(moto => {
                    if (moto.id === data.motoID) {
                        return {
                            ...moto, motoinscription: {
                                validation: !moto.motoinscription.validation
                            }
                        };
                    } else {
                        return moto;
                    }
                })]
            });
        } else if (champs === 'modal') {
            setShowModal({
                ...showModal, modalMoto: {
                    show: data.show,
                    motoData: data.motoID !== null ? inscriptionData.moto.find(val => val.id === data.motoID) : {}
                }
            });
        } else if (champs === 'data') {
            setInscriptionData({
                ...inscriptionData, moto: [...inscriptionData.moto.map(moto => {
                    if (moto.id === data.motoID) {
                        return {
                            ...moto,
                            marque: data.marque,
                            modele: data.modele,
                            cylindree: data.cylindree,
                            annee: data.annee,
                            partager: data.partager,
                            preparer: data.preparer,
                            serialnumber: data.serialNumber,
                            policeassurance: data.assurance,
                            id_type: data.typeMoto,
                            mototype: dataEvenement.mototypes.find(val => val.id === data.typeMoto)
                        };
                    } else {
                        return moto;
                    }
                })]
            });
        } else if (champs === 'delete') {
            setInscriptionData({
                ...inscriptionData, moto: [
                    ...inscriptionData.moto.filter(moto => moto.id !== data.motoID)
                ]
            });
        }
    };
    const handleChangeInscriptionType = (champs, data) => {
        setInscriptionData({
            ...inscriptionData, inscriptionType: {
                ...inscriptionData.inscriptionType,
                inscriptionTypeId: champs === 'typeInscription' ? data : inscriptionData.inscriptionType.inscriptionTypeId,
                inferno: champs === 'inferno' ? data : inscriptionData.inscriptionType.inferno,
                partageGuidon: champs === 'partageGuidon' ? data : inscriptionData.inscriptionType.partageGuidon,
                passagerSide: {
                    ...inscriptionData.inscriptionType.passagerSide,
                    nom: champs === 'passagerSide-nom' ? data : inscriptionData.inscriptionType.passagerSide.nom,
                    prenom: champs === 'passagerSide-prenom' ? data : inscriptionData.inscriptionType.passagerSide.prenom,
                    licence: champs === 'passagerSide-licence' ? data : inscriptionData.inscriptionType.passagerSide.licence,
                    id_assurance: champs === 'passagerSide-assurance' ? data : inscriptionData.inscriptionType.passagerSide.id_assurance,
                    mail: champs === 'passagerSide-mail' ? data : inscriptionData.inscriptionType.passagerSide.mail,
                },
                secondGuidon: {
                    ...inscriptionData.inscriptionType.secondGuidon,
                    nom: champs === 'secondGuidon-nom' ? data : inscriptionData.inscriptionType.secondGuidon.nom,
                    prenom: champs === 'secondGuidon-prenom' ? data : inscriptionData.inscriptionType.secondGuidon.prenom,
                    numeropermis: champs === 'secondGuidon-permis' ? data : inscriptionData.inscriptionType.secondGuidon.numeropermis,
                    licence: champs === 'secondGuidon-licence' ? data : inscriptionData.inscriptionType.secondGuidon.licence,
                    id_assurance: champs === 'secondGuidon-assurance' ? data : inscriptionData.inscriptionType.secondGuidon.id_assurance,
                    mail: champs === 'secondGuidon-mail' ? data : inscriptionData.inscriptionType.secondGuidon.mail,
                }
            }
        });
    };
    const handleChangeComplement = (champs, data) => {
        setInscriptionData({
            ...inscriptionData,
            suiviMarshall: champs === 'marshall' ? data : inscriptionData.suiviMarshall,
            bapteme: champs === 'bapteme' ? data : inscriptionData.bapteme,
            incident: champs === 'incident' ? data : inscriptionData.incident,
            commentaire: champs === 'message' ? data : inscriptionData.commentaire
        });
    };
    const handleSaveDossierInscription = async () => {
        await enregistrerInscription(requestContructor.requestEnregistrerInscription(inscriptionData, dataEvenement.inscriptiontypes)).unwrap()
            .then(() => {
                setSucessAlertMessage('Dossier enregistrer avec succès !');
                setSucessAlert(true);
            })
            .catch(() => {
                handleOpenCloseModalSerieDossier(false);
                setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                setErrorAlert(true);
            })
    };
    const handleCloseSucessSave = () => {
        setSucessAlert(false);
    };
    const handleCloseErrorSave = () => {
        setErrorAlert(false);
    };
    const handleOpenCloseModalHistorique = (choix) => {
        setShowModal({
            ...showModal, modalHistorique: {
                ...showModal.modalHistorique, show: choix
            }
        })
    };
    const handleChangementStatutDossier = async (statut) => {
        if (statut === Constants.STATUT_DOSSIER_VALIDER) {
            await validerInscription(inscriptionData.id).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
        } else if (statut === Constants.STATUT_DOSSIER_CONFIRMER) {
            //vérification pour savoir si une série est assosier au dossier
            if (inscriptionData.serie === null) {
                setErrorAlertMessage('Veuillez associer une serie au dossier');
                setErrorAlert(true);
            } else {
                await confirmerInscription(inscriptionData.id).unwrap()
                    .then(() => {
                        setSucessAlertMessage('Dossier enregistrer avec succès !');
                        setSucessAlert(true);
                    })
                    .catch(error => {
                        if (error.status === 406) {
                            setErrorAlertMessage(error.data);
                            setErrorAlert(true);
                        } else {
                            setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                            setErrorAlert(true);
                        }
                    })
            }
        } else if (statut === Constants.STATUT_DOSSIER_INCOMPLET) {
            handleOpenCloseModalMotifDossierInvalid(true);
        } else if (statut === Constants.STATUT_DOSSIER_ATTENTE) {
            await listAttenteInscription(inscriptionData.id).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
        } else if (statut === Constants.STATUT_DOSSIER_REFUSER) {
            await refusInscription(inscriptionData.id).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
        } else if (statut === Constants.STATUT_DOSSIER_ANNULE) {
            await annulerInscription(inscriptionData.id).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
        } else if (statut === Constants.STATUT_DOSSIER_PRESENT) {
            await presentInscription(inscriptionData.id).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
        } else if (statut === Constants.STATUT_DOSSIER_ABANDON) {
            await abandonInscription(inscriptionData.id).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
        }
    };
    const handleOpenCloseModalSerieDossier = (choix) => {
        setShowModal({
            ...showModal, modalSerie: {
                ...showModal.modalSerie, show: choix
            }
        })
    };
    const handleOpenCloseModalChangementDossard = (choix) => {
        setShowModal({
            ...showModal, modalChangementDossard: {
                ...showModal.modalChangementDossard, show: choix
            }
        });
    };
    const handleOpenCloseModalAjoutMoto = (choix) => {
        setShowModal({
            ...showModal, modalAjoutMoto: {
                ...showModal.modalAjoutMoto, show: choix
            }
        });
    };
    const handleSaveSerieInscription = async (serieID) => {
        await associerSerieInscription({serieID: serieID, inscriptionID: inscriptionData.id}).unwrap()
            .then(() => {
                handleOpenCloseModalSerieDossier(false);
                setSucessAlertMessage('Dossier enregistrer avec succès !');
                setSucessAlert(true);
            })
            .catch(() => {
                handleOpenCloseModalSerieDossier(false);
                setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                setErrorAlert(true);
            })
    };
    const handleSaveNouveauDossardInscription = async (nouveauNumeroDossard) => {
        await changerDossardInscription({
            nouveauNumero: nouveauNumeroDossard,
            inscriptionID: inscriptionData.id
        }).unwrap()
            .then(() => {
                handleOpenCloseModalChangementDossard(false);
                setSucessAlertMessage('Dossier enregistrer avec succès !');
                setSucessAlert(true);
            })
            .catch(error => {
                handleOpenCloseModalChangementDossard(false);
                if (error.status === 406) {
                    setErrorAlertMessage(error.data);
                    setErrorAlert(true);
                } else {
                    setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                    setErrorAlert(true);
                }
            })
    };
    const handleFiltrerSerie = (listSerie) => {
        if (inscriptionData.serie.id.length === 0) {
            return listSerie.filter(serie => serie.seriebenevole === false);
        } else {
            return listSerie.filter(serie => serie.seriebenevole === false && serie.id !== inscriptionData.serie.id);
        }
    };
    const handleFiltrerMoto = (listMoto) => {
        if (listMoto.length === 0) {
            return props.listMotoUser;
        } else {
            return props.listMotoUser.filter(moto => listMoto.filter(motoDossier => motoDossier.id === moto.id).length === 0);
        }
    };
    const handleDuplicateInscription = async () => {
        await dupliquerInscription(inscriptionData.id).unwrap()
            .then((data) => {
                navigate(`/inscription/${data}`);
            })
            .catch(() => {
                handleOpenCloseModalSerieDossier(false);
                setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                setErrorAlert(true);
            })
    };
    const handleNewMotoInscription = (action, motoData) => {
        switch (action) {
            case 'new':
                setInscriptionData({
                    ...inscriptionData, moto: [...inscriptionData.moto, {
                        id: Math.random(),
                        marque: motoData.marque,
                        modele: motoData.modele,
                        cylindree: motoData.cylindree,
                        annee: motoData.annee,
                        partager: motoData.partager,
                        preparer: motoData.preparer,
                        serialnumber: motoData.serialNumber,
                        policeassurance: motoData.assurance,
                        id_type: motoData.typeMoto,
                        mototype: dataEvenement.mototypes.find(val => val.id === motoData.typeMoto),
                        motoinscription: {validation: motoData.validation}
                    }]
                });
                break;
            case 'exist' :
                let selectedMoto = [];
                motoData.forEach(IdMoto => {
                    selectedMoto.push({
                        ...props.listMotoUser.find(moto => moto.id === IdMoto),
                        motoinscription: {validation: false}
                    });
                });
                setInscriptionData({
                    ...inscriptionData,
                    moto: [...inscriptionData.moto].concat(selectedMoto)
                });
                break;
        }
        handleOpenCloseModalAjoutMoto(false);
    };
    const handleOpenCloseModalMotifDossierInvalid = (choice) => {
        setShowModal({
            ...showModal, modalMotifDossierInvalid: {
                ...showModal.modalMotifDossierInvalid, show: choice
            }
        });
    };
    const handleValiderMotifDossierInvalider = async (motif) => {
        if (Constants.STATUT_DOSSIER_INCOMPLET === inscriptionData.statut) {
            await modifierMotifDossierInvalide({
                id: inscriptionData.id,
                motif: motif
            }).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                    handleOpenCloseModalMotifDossierInvalid(false);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
            // modification du motif dossier incomplet
        } else if (Constants.STATUT_DOSSIER_ATTENTE_VALIDATION === inscriptionData.statut) {
            await invaliderInscription({
                id: inscriptionData.id,
                motif: motif
            }).unwrap()
                .then(() => {
                    setSucessAlertMessage('Dossier enregistrer avec succès !');
                    setSucessAlert(true);
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    }
                })
        }
    }

    return (<Fragment>
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" sx={{mb: 10, mt: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} md={12}>
                        <Typography textAlign="center" variant="h5">
                            Dossier Inscription N°{inscriptionData.barcode}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12}>
                        <CardStatutDossier
                            statut={inscriptionData.statut}/>
                    </Grid>
                </Grid>
                <Divider sx={{
                    marginTop: 2, marginBottom: 3
                }}>Information Pilote</Divider>
                <InformationPilote
                    utilisateur={inscriptionData.utilisateur}/>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Divider sx={{
                                    marginTop: 3
                                }}>Contact Pilote</Divider>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <ContactDossier
                                    dataContact={inscriptionData.contact}
                                    updateContact={handleChangeInfoContact}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} lg={6} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Divider sx={{
                                    marginTop: 3
                                }}>Information</Divider>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <InformationDossier
                                    informationDossier={inscriptionData.information}
                                    updateInformation={handleChangeInformation}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{
                    marginTop: 3, marginBottom: 2
                }}>Expérience</Divider>
                <ExperienceDossier
                    experienceData={inscriptionData.experience}
                    listExperience={dataEvenement.experiences}
                    updateExperience={handleChangeExperience}
                />
                <Divider sx={{
                    marginTop: 3, marginBottom: 2
                }}>Assurance</Divider>
                <AssuranceDossier
                    dataDossier={inscriptionData}
                    listAssurance={dataEvenement.assurances}
                    assuranceData={inscriptionData.assurance}
                    updateAssurance={handleChangeAssurance}/>
                <Divider sx={{
                    marginTop: 3, marginBottom: 2
                }}>Motos</Divider>
                <MotoDossier
                    listMoto={inscriptionData.moto}
                    updateValidation={handleChangeMoto}
                    openModalAjoutMoto={handleOpenCloseModalAjoutMoto}/>
                <Divider sx={{
                    marginTop: 3, marginBottom: 2
                }}>Type d'inscription</Divider>
                <InscriptionTypeDossier
                    listAssurance={dataEvenement.assurances}
                    listInscriptionType={dataEvenement.inscriptiontypes}
                    insciptionTypeData={inscriptionData.inscriptionType}
                    inscriptionID={inscriptionData.id}
                    compteClientID={inscriptionData.utilisateur.id}
                    updateInscriptionType={handleChangeInscriptionType}/>
                {props.dossierInsciption.groupe_pilote !== null && <Fragment>
                    <Divider sx={{
                        marginTop: 3, marginBottom: 2
                    }}>Groupe pilote</Divider>
                    <GroupePilote
                        GroupeInformation={inscriptionData.groupePilote}
                        inscriptionID={inscriptionData.id}/>
                </Fragment>}
                <Divider sx={{
                    marginTop: 3, marginBottom: 2
                }}>Complément</Divider>
                <ComplementDossier
                    dossierInsciption={inscriptionData}
                    listInscriptionType={dataEvenement.inscriptiontypes}
                    updateComplement={handleChangeComplement}/>
                {inscriptionData.statut === Constants.STATUT_DOSSIER_INCOMPLET && <Fragment>
                    <Divider sx={{
                        marginTop: 3, marginBottom: 2
                    }}>Motif Dossier incomplet</Divider>
                    <MotifDossierIncomplet
                        updateMotif={handleOpenCloseModalMotifDossierInvalid}
                        motifDossierInvalide={inscriptionData.motifDossierInvalide}
                    />
                </Fragment>}
                {inscriptionData.dateHistorique.dateValidation !== null && <Fragment>
                    <Divider sx={{
                        marginTop: 3, marginBottom: 2
                    }}>Série</Divider>
                    <SerieDossier
                        SerieInformation={inscriptionData.serie}
                        dateConfirmation={inscriptionData.dateHistorique.dateConfirmation}
                        datePaiement={inscriptionData.dateHistorique.datePaiement}
                        dateAnnulation={inscriptionData.dateHistorique.dateAnnulation}
                        dossardPilote={inscriptionData.dossard}
                        openModal={handleOpenCloseModalSerieDossier}
                        openModalChangementDossard={handleOpenCloseModalChangementDossard}
                        statutDossier={inscriptionData.statut}/>
                </Fragment>}
                <Divider sx={{
                    marginTop: 3, marginBottom: 2
                }}>Historique</Divider>
                <HistoriqueDate
                    historiqueDate={inscriptionData.dateHistorique}/>
                <ValidationDossier
                    statutDossier={inscriptionData.statut}
                    enregistrerDossier={handleSaveDossierInscription}
                    openModalHistorique={handleOpenCloseModalHistorique}
                    loadingEnregistrerDossier={isLoadingEnregistrerInscription}
                    loadingValiderDossier={loadingChangementStatut}
                    changementStatut={handleChangementStatutDossier}
                    dupliquerDossier={handleDuplicateInscription}/>
            </Container>
        </ThemeProvider>
        {showModal.modalMoto.show && <ModalMoto
            closeModal={handleChangeMoto}
            listTypeMoto={dataEvenement.mototypes}
            updateMoto={handleChangeMoto}
            dataMoto={showModal.modalMoto.motoData}/>}
        {showModal.modalHistorique.show && <ModalHistorique
            closeModal={handleOpenCloseModalHistorique}
            listHistorique={inscriptionData.historique}/>}
        {showModal.modalSerie.show && <ModalSerieDossier
            closeModal={handleOpenCloseModalSerieDossier}
            listSerie={handleFiltrerSerie(props.listSerie)}
            saveSerie={handleSaveSerieInscription}/>}
        {showModal.modalChangementDossard.show && <ModalChangementNumeroDossierInscription
            closeModal={handleOpenCloseModalChangementDossard}
            dossierInscription={inscriptionData}
            saveNouveauDossard={handleSaveNouveauDossardInscription}
            isLoading={isChangerDossardInscription}/>}
        {showModal.modalAjoutMoto.show && <ModalAjoutMoto
            closeModal={handleOpenCloseModalAjoutMoto}
            listMoto={handleFiltrerMoto(inscriptionData.moto)}
            listTypeMoto={dataEvenement.mototypes}
            actionMoto={handleNewMotoInscription}/>}
        {showModal.modalMotifDossierInvalid.show && <ModalMotifDossierInvalid
            closeModal={handleOpenCloseModalMotifDossierInvalid}
            saveMotif={handleValiderMotifDossierInvalider}
            motifDossierInvalide={inscriptionData.statut === Constants.STATUT_DOSSIER_INCOMPLET ? inscriptionData.motifDossierInvalide : ''}/>}
        <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={handleCloseSucessSave}>
            <Alert onClose={handleCloseSucessSave} severity="success" sx={{width: '100%'}}>
                {sucessAlertMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={errorAlert} autoHideDuration={3000} onClose={handleCloseErrorSave}>
            <Alert onClose={handleCloseErrorSave} severity="error" sx={{width: '100%'}}>
                {errorAlertMessage}
            </Alert>
        </Snackbar>
    </Fragment>)
}