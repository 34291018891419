import {Fragment, useEffect, useState} from "react";
import {Backdrop, Box, Chip, CircularProgress, createTheme, Snackbar, Stack} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LoadingButton} from "@mui/lab";
import {DataGrid, frFR} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
    useDeleteCompteAdminMutation,
    useListerUtilisateurAdminQuery
} from "../../feature/utilisateur/utilisateurApiSlice";
import {useSelector} from "react-redux";
import {selectCurrentUserID} from "../../feature/auth/authSlice";
import PopperDroitUtilisateur from "../../components/gestionUtilisateur/PopperDroitUtilisateur";
import {useNavigate} from "react-router-dom";
import Alert from "@mui/material/Alert";

const theme = createTheme();

export default () => {

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Utilisateur';
    }, []);

    const [loadingGlobal, setLoadingGlobal] = useState(true);
    const [pagesize, setPagesize] = useState(10);
    const [errorAlert, setErrorAlert] = useState(false);
    const currentUserID = useSelector(selectCurrentUserID);

    const navigate = useNavigate();

    const [deleteCompteAdmin] = useDeleteCompteAdminMutation();

    const {
        data: listUtilisateurAdmin,
        isSuccess: SucessListUtilisateurAdmin,
        isError: isErreurListUtilisateurAdmin
    } = useListerUtilisateurAdminQuery();

    const fetchData = SucessListUtilisateurAdmin;

    useEffect(() => {
        if (fetchData) {
            setLoadingGlobal(false);
        }
    }, [fetchData]);

    function renderCellExpand(params) {
        return (
            <PopperDroitUtilisateur value={params.value}/>
        );
    };
    const handleClickModifierUtilisateur = (cellValues) => {
        navigate(`/utilisateur/${cellValues.id}`);
    };
    const handleCreateNouvelleUtilisateur = () => {
        navigate(`/utilisateur`);
    }
    const handleDeleteUtilisateur = async (userID) => {
        deleteCompteAdmin(userID).unwrap()
            .catch(() => {
                setErrorAlert(true);
            })
    }

    const handleCloseErrorSave = () => {
        setErrorAlert(false);
    };
    const columns = [
        {
            field: 'role',
            headerName: 'Rôle',
            width: 170,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'adressemail',
            headerName: 'Adresse mail',
            width: 300,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'droits',
            headerName: 'Droits',
            width: 70,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: renderCellExpand,
        }, {
            field: 'tentative_password',
            headerName: 'Status',
            width: 100,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                const compte = cellValues.value >= 3;
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={`${compte ? 'Bloquer' : 'Actif'}`}
                              color={compte ? 'error' : 'success'}/>
                    </Stack>
                )
            },
        }, {
            field: 'modifier',
            headerName: 'Modifier',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton aria-label="edit"
                                onClick={() => handleClickModifierUtilisateur(cellValues)}
                    >
                        <EditIcon/>
                    </IconButton>)
            }
        }, {
            field: 'supprimer',
            headerName: 'Supprimer',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                let AdminAccount = cellValues.row.droits.find(val => val.code === 'ADMIN') === undefined;
                return (
                    <IconButton aria-label="edit" disabled={cellValues.id === currentUserID.userID || !AdminAccount}
                                onClick={() => handleDeleteUtilisateur(cellValues.id)}
                    >
                        <DeleteForeverIcon/>
                    </IconButton>)
            }
        },
    ];

    return (
        <Fragment>
            {loadingGlobal ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="md" sx={{mb: 10, mt: 2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Typography align='center' variant="h4">Gestion des utilisateurs</Typography>
                            </Grid>
                            <Grid item lg={12} sm={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant="contained" onClick={handleCreateNouvelleUtilisateur}>Créé
                                        Utilisateur</LoadingButton>
                                </Box>
                            </Grid>
                            <Grid item lg={12} sm={12}>
                                <DataGrid
                                    rows={listUtilisateurAdmin}
                                    columns={columns}
                                    pageSize={pagesize}
                                    rowsPerPageOptions={[5, 10, 50]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{newEditingApi: true}}
                                    autoHeight
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    onPageSizeChange={pageSize => setPagesize(pageSize)}
                                    showToolbar
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </ThemeProvider>}
            <Snackbar open={errorAlert} autoHideDuration={2000} onClose={handleCloseErrorSave}>
                <Alert onClose={handleCloseErrorSave} severity="error" sx={{width: '100%'}}>
                    Erreur technique essayer ultérieurement !
                </Alert>
            </Snackbar>
        </Fragment>
    )
}