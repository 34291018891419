import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import formUtils from "../../utils/formUtils";

export default (props) => {

    const [motifAbandon, setMotifAbandon] = useState('');
    const [errorMotif, setErrorMotif] = useState({error: false, message: ''});

    useEffect(() => {
        if (props.motifDossierInvalide.length > 0) {
            setMotifAbandon(props.motifDossierInvalide);
        }
    }, [])

    const handleClose = () => {
        props.closeModal(false);
    };

    const validerMotif = () => {
        if (motifAbandon.length > 0) {
            setErrorMotif({...errorMotif, message: '', error: false});
            props.saveMotif(motifAbandon);
        } else {
            setErrorMotif({...errorMotif, message: 'Veuillez renseigner un motif', error: true});
        }
    };
    return (
        <Dialog open={true} fullWidth maxWidth="sm">
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Motif dossier incomplet</DialogTitle>
            </Box>
            <DialogContent>
                <Container component="main">
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                name="Message-complementaire"
                                fullWidth
                                required
                                label="Motif"
                                multiline
                                rows={2}
                                value={motifAbandon}
                                error={errorMotif.error}
                                helperText={errorMotif.message}
                                onChange={event => {
                                    if (formUtils.verifAlpha(event.target.value)) {
                                        setMotifAbandon(event.target.value);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={validerMotif}>Valider</Button>
                <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}