import {Chip} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import * as Constants from "../../Constants/ContantesDossier";

export default (props) => {

    const [colorChip, setColorChip] = useState('info');
    const [load, setLoad] = useState(false);

    useEffect(() => {
        switch (props.statut) {
            case Constants.STATUT_DOSSIER_INIT :
                setColorChip('info');
                break;
            case Constants.STATUT_DOSSIER_IN_PROGRESS :
                setColorChip('primary');
                break;
            case Constants.STATUT_DOSSIER_ATTENTE_VALIDATION :
                setColorChip('warning');
                break;
            case Constants.STATUT_DOSSIER_VALIDER :
                setColorChip('success');
                break;
            case Constants.STATUT_DOSSIER_CONFIRMER :
                setColorChip('success');
                break;
            case Constants.STATUT_DOSSIER_INCOMPLET :
                setColorChip('error');
                break;
            case Constants.STATUT_DOSSIER_ATTENTE :
                setColorChip('secondary');
                break;
            case Constants.STATUT_DOSSIER_ATTENTE_PAIEMENT :
                setColorChip('warning');
                break;
            case Constants.STATUT_DOSSIER_ERREUR_PAIEMENT :
                setColorChip('error');
                break;
            case Constants.STATUT_DOSSIER_PAYER :
                setColorChip('success');
                break;
            case Constants.STATUT_DOSSIER_REFUSER :
                setColorChip('error');
                break;
            case Constants.STATUT_DOSSIER_PRESENT :
                setColorChip('success');
                break;
            case Constants.STATUT_DOSSIER_ANNULE :
                setColorChip('error');
                break;
        }
        setLoad(true);
    }, [props.statut])

    return <Fragment>
        {load &&
            <Chip color={colorChip} label={props.statut.toLowerCase()}/>
        }
    </Fragment>
}