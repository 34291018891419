import {useSelectEvenementQuery} from "../../../feature/utilisateur/utilisateurApiSlice";
import {useListDateEvenementQuery} from "../../../feature/evenement/evenementApiSlice";
import {useGetInscriptionQuery} from "../../../feature/inscription/inscriptionApiSlice";
import {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import GestionInscription from "../GestionInscription";
import {Backdrop, CircularProgress} from "@mui/material";
import {useListSerieEvenementQuery} from "../../../feature/serie/serieApiSlice";
import {useListAllMotoUserQuery} from "../../../feature/moto/motoApiSlice";

export default () => {

    const {inscriptionId} = useParams();

    const [skipFetchInscription, setSkipFetchInscription] = useState(true);
    const [skipFetchListMoto, setSkipFetchListMoto] = useState(true);
    const [evenementID, setEvenementID] = useState('');
    const [userID, setUserID] = useState('');

    const navigate = useNavigate();

    const {
        data: currentSelectedEvenement,
        isSuccess: SucessCurrentSelectedEvenement,
        isError: isErreurCurrentSelectedEvenement,
        error: errorCurrentSelectedEvenement
    } = useSelectEvenementQuery(null);

    const {
        data: getDataEvenement,
        isSuccess: SucessGetDataEvenement,
        isError: isErreurGetDataEvenement,
        error: errorGetDataEvenement
    } = useListDateEvenementQuery(evenementID, {skip: skipFetchInscription});

    const {
        data: getInscription,
        isSuccess: SucessGetInscription,
        isError: isErreurGetInscription,
        error: errorGetInscription
    } = useGetInscriptionQuery({inscriptionID: inscriptionId, evenementID: evenementID}, {skip: skipFetchInscription});

    const {
        data: getListSerieEvenement,
        isSuccess: SucessGetListSerieEvenement,
        isError: isErreurGetListSerieEvenement,
        error: errorGetListSerieEvenement
    } = useListSerieEvenementQuery(evenementID, {skip: skipFetchInscription});

    const {
        data: getListAllMotoUser,
        isSuccess: SucessGetListAllMotoUser,
        isError: isErreurListAllMotoUser,
        error: errorGetListAllMotoUser
    } = useListAllMotoUserQuery(userID, {skip: skipFetchListMoto});

    console.log(errorCurrentSelectedEvenement);

    useEffect(() => {
        if (SucessCurrentSelectedEvenement && currentSelectedEvenement !== null) {
            setEvenementID(currentSelectedEvenement.id);
            setSkipFetchInscription(false);
        }
    }, [SucessCurrentSelectedEvenement, currentSelectedEvenement]);

    useEffect(() => {
        if (SucessGetInscription && getInscription !== null) {
            setUserID(getInscription.utilisateur.id);
            setSkipFetchListMoto(false);
        }
        if (SucessGetInscription && getInscription === null) {
            navigate('/inscriptions');
        }
    }, [SucessGetInscription, getInscription]);

    const allSuccessLoad = SucessGetInscription && SucessGetDataEvenement && SucessGetListSerieEvenement && SucessGetListAllMotoUser;

    return (
        <Fragment>
            {!allSuccessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop> :
                <GestionInscription
                    isLoad={allSuccessLoad}
                    dossierInsciption={getInscription}
                    dataEvenement={getDataEvenement}
                    listSerie={getListSerieEvenement}
                    listMotoUser={getListAllMotoUser}/>
            }
        </Fragment>
    )
}