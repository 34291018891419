import {apiSlice} from "../../app/api/apiSlice";

const urlGroupePilote = "groupe-pilote";

export const groupePiloteApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => {
        return ({
            rechercheGroupePilote: builder.query({
                query: (data) => ({
                    url: `${urlGroupePilote}/recherche/${data.nom}/${data.evenementID}`,
                    method: 'GET',
                }),
                providesTags: (result) =>
                    result ? [{type: 'RechercheGroupePilote', ...result.id}, {
                            type: 'RechercheGroupePilote',
                            id: 'LIST'
                        }] :
                        [{type: 'RechercheGroupePilote', id: 'LIST'}],
            }),
            createGroupePilote: builder.mutation({
                query: (data) => ({
                    url: `${urlGroupePilote}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        evenementid: data.evenementID
                    }
                }),
                invalidatesTags: [{type: 'RechercheGroupePilote', id: 'LIST'}],
            }),
        });
    }
})

export const {
    useRechercheGroupePiloteQuery,
    useCreateGroupePiloteMutation,
} = groupePiloteApiSlice