import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

export default (props) => {
    const {infoPilote} = props;

    return (
        <Paper sx={{p: 1}}>
            <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={4} sm={4}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} sm={12}>
                            <Divider>Nom</Divider>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' fontWeight='bold'>
                                {infoPilote.utilisateur === null ?
                                    infoPilote.nom !== null ? infoPilote.nom : <CloseIcon/> :
                                    infoPilote.utilisateur.nom}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} sm={12}>
                            <Divider>Prenom</Divider>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' fontWeight='bold'>
                                {infoPilote.utilisateur === null ?
                                    infoPilote.prenom !== null ? infoPilote.prenom :
                                        <CloseIcon/> : infoPilote.utilisateur.prenom}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {infoPilote.type === 'SECOND' &&
                    <Grid item xs={4} sm={4}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12} sm={12}>
                                <Divider>Numéro permis</Divider>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography align='center' fontWeight='bold'>
                                    {infoPilote.numeropermis !== null ? infoPilote.numeropermis : <CloseIcon/>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>}
                <Grid item xs={4} sm={4}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} sm={12}>
                            <Divider>Numéro licence</Divider>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' fontWeight='bold'>
                                {infoPilote.licence !== null ? infoPilote.licence : <CloseIcon/>}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} sm={12}>
                            <Divider>Assurance</Divider>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' fontWeight='bold'>
                                {infoPilote.assurance !== null ? infoPilote.assurance.libellecourt : <CloseIcon/>}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}