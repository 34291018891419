import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/fr';
import JourHoraire from "../../../Constants/JourHoraire";
import Button from "@mui/material/Button";
import formUtils from "../../../utils/formUtils";

export default (props) => {

    const [locale] = useState('fr');
    const [heureDebut, setHeureDebut] = useState(dayjs('2022-01-01T00:00:00'));
    const [heureFin, setHeureFin] = useState(dayjs('2022-01-01T00:00:00'));
    const [selectJour, setSelectJour] = useState('');
    const [selectSerie, setSelectSerie] = useState('');
    const [errorForm, setErrorForm] = useState({
        serie: false,
        jour: false,
        heureDebut: false,
        heureFin: false,
    });

    const handleChangeHeureDebut = (newValue) => {
        setHeureDebut(newValue);
    };
    const handleChangeHeureFin = (newValue) => {
        setHeureFin(newValue);
    };
    const handleChangeJour = (event) => {
        setSelectJour(event.target.value);
    };
    const handleChangeSerie = (event) => {
        setSelectSerie(event.target.value);
    };
    const handleClose = () => {
        props.closeModal();
    };
    const handleAddHoraire = () => {
        if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
            if (!props.editMode) {
                props.addHoraire('new', {
                    jour: selectJour,
                    heuredebut: heureDebut,
                    heurefin: heureFin,
                    serieid: selectSerie
                });
            } else {
                props.addHoraire('update', {
                    horaireID: props.editItem.id,
                    jour: selectJour,
                    heuredebut: heureDebut,
                    heurefin: heureFin,
                    serieid: selectSerie
                });
            }
        }
    };

    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            jour: selectJour.length === 0,
            serie: selectSerie.length === 0,
            heureDebut: heureDebut.hour() === 0 && heureDebut.minute() === 0,
            heureFin: heureFin.hour() === 0 && heureFin.minute() === 0,
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    };

    useEffect(() => {
        setSelectSerie(props.serieSelect);
        if (props.editMode) {
            const spiltHourDebut = props.editItem.heuredebut.split(':');
            setHeureDebut(dayjs(`2022-01-01T${spiltHourDebut[0]}:${spiltHourDebut[1]}:00`));
            const spiltHourFin = props.editItem.heurefin.split(':');
            setHeureFin(dayjs(`2022-01-01T${spiltHourFin[0]}:${spiltHourFin[1]}:00`));
            setSelectJour(props.editItem.jourID);
        }
    }, []);

    return (
        <Dialog open={true} fullWidth maxWidth="lg" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>{props.editMode ? 'Modifier' : 'Ajouter'} Horaire</DialogTitle>
            </Box>
            <DialogContent>
                <Container component="main">
                    <Grid container spacing={2}>
                        <Grid item md={5}>
                            <FormControl fullWidth>
                                <InputLabel id="label-select-Serie" error={errorForm.serie}>Serie</InputLabel>
                                <Select
                                    labelId="label-select-Serie"
                                    id="select-Serie"
                                    value={selectSerie}
                                    label="Serie"
                                    onChange={handleChangeSerie}
                                    error={errorForm.serie}
                                >
                                    {props.listSerie.map(serie => {
                                        return <MenuItem key={serie.id} value={serie.id}>{serie.libelle}</MenuItem>
                                    })}
                                </Select>
                                {errorForm.serie && <FormHelperText error={errorForm.jour}>Veuillez selectionner un
                                    jour</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="label-select-Jour" error={errorForm.jour}>Jour</InputLabel>
                                <Select
                                    labelId="label-select-Jour"
                                    id="select-Jour"
                                    value={selectJour}
                                    label="Age"
                                    onChange={handleChangeJour}
                                    error={errorForm.jour}
                                >
                                    {JourHoraire.map(jour => {
                                        return <MenuItem key={jour} value={jour}>{jour}</MenuItem>
                                    })}
                                </Select>
                                {errorForm.jour && <FormHelperText error={errorForm.jour}>Veuillez selectionner un
                                    jour</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <TimePicker
                                    label="Heure de début"
                                    value={heureDebut}
                                    onChange={handleChangeHeureDebut}
                                    renderInput={(params) => <TextField {...params} error={errorForm.heureDebut}
                                                                        helperText={errorForm.heureDebut ? "veuillez renseigner l'heure" : ""}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <TimePicker
                                    label="Heure de fin"
                                    value={heureFin}
                                    onChange={handleChangeHeureFin}
                                    renderInput={(params) => <TextField {...params} error={errorForm.heureFin}
                                                                        helperText={errorForm.heureFin ? "veuillez renseigner l'heure" : ""}/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                        onClick={handleAddHoraire}>{props.editMode ? 'Enregistrer' : 'Ajouter'}</Button>
                <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}