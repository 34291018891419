import {createTheme} from "@mui/material/styles";
import {Backdrop, Box, CircularProgress, ThemeProvider} from "@mui/material";
import Container from "@mui/material/Container";
import {DataGrid, frFR} from '@mui/x-data-grid';
import {useInitEvenementMutation, useListAllEvenementsQuery} from "../../feature/evenement/evenementApiSlice";
import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import {LoadingButton} from "@mui/lab";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from "dayjs";
import ModalDeleteEvenement from "../../components/evenement/listEvenement/ModalDeleteEvenement";

const theme = createTheme();

const ListEvenement = () => {

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Evenement';
    }, []);

    const [loadingGlobal, setLoadingGlobal] = useState(true);
    const [loadingInitEvenement, setLoadingInitEvenement] = useState(false);
    const [pagesize, setPagesize] = useState(10);
    const [stateListEvenement, setStateListEvenement] = useState({
        listEvenements: []
    });
    const [showModal, setShowModal] = useState({
        modalSuppressionEvenement: {show: false, evenementID: ''}
    });

    const navigate = useNavigate();

    const [initEvenement] = useInitEvenementMutation();

    const {
        data: listEvenements,
        isSuccess: SucessListEvenements,
        isError: isErreurListEvenements
    } = useListAllEvenementsQuery();

    useEffect(() => {
        if (isErreurListEvenements) {
            navigate('/500');
        }
        if (listEvenements != null && SucessListEvenements) {
            setStateListEvenement({
                ...stateListEvenement,
                listEvenements: listEvenements
            });
            setLoadingGlobal(false);
        }
    }, [listEvenements, SucessListEvenements, isErreurListEvenements]);

    const handleClickModifierEvenement = (dataCell) => {
        navigate(`/evenement/${dataCell.id}`);
    };

    const handleInitEvenement = async (event) => {
        setLoadingInitEvenement(true);
        try {
            const evenement = await initEvenement().unwrap();
            navigate(`/evenement/${evenement.id_evenement}`);
            setLoadingInitEvenement(false);
        } catch (e) {
            setLoadingInitEvenement(false);
        }
    };
    const handleCloseModalDeleteEvenement = () => {
        setShowModal({
            ...showModal,
            modalSuppressionEvenement: {...showModal.modalSuppressionEvenement, show: false}
        });
    };
    const handleOpenDeleteEvenement = (cellValue) => {
        setShowModal({
            ...showModal,
            modalSuppressionEvenement: {...showModal.modalSuppressionEvenement, show: true, evenementID: cellValue.id}
        });
    }

    const columns = [
        {
            field: 'libelle',
            headerName: 'Evenement',
            width: 120,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'date',
            headerName: 'Date Evenement',
            type: 'date',
            width: 130,
            editable: false,
            valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('DD/MM/YYYY') : '',
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'rouleur',
            headerName: 'Rouleur',
            type: 'boolean',
            width: 80,
            editable: false,
        }, {
            field: 'benevole',
            headerName: 'Benevole',
            type: 'boolean',
            width: 80,
            editable: false,
        }, {
            field: 'zorga',
            headerName: 'Serie Zorga',
            type: 'boolean',
            width: 100,
            editable: false,
        }, {
            field: 'datedebutinsciption',
            headerName: 'Debut Inscription',
            type: 'date',
            width: 130,
            editable: false,
            valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('DD/MM/YYYY') : '',
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'datefininscription',
            headerName: 'Fin Inscription',
            type: 'date',
            width: 110,
            editable: false,
            valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('DD/MM/YYYY') : '',
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'modifier',
            headerName: 'Modifier',
            width: 70,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton edge="end" aria-label="edit"
                                onClick={() => handleClickModifierEvenement(cellValues)}>
                        <EditIcon/>
                    </IconButton>)
            }
        }, {
            field: 'supprimer',
            headerName: 'Supprimer',
            width: 85,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton edge="end" aria-label="edit"
                                onClick={() => handleOpenDeleteEvenement(cellValues)}>
                        <DeleteForeverIcon/>
                    </IconButton>)
            }
        },
    ];

    return (
        <Fragment>
            {loadingGlobal ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="lg" sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item lg={12} sm={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton onClick={handleInitEvenement} loading={loadingInitEvenement}
                                                   variant="contained">Ajouter Evenement</LoadingButton>
                                </Box>
                            </Grid>
                            <Grid item lg={12} sm={12}>
                                <DataGrid
                                    rows={stateListEvenement.listEvenements}
                                    columns={columns}
                                    pageSize={pagesize}
                                    rowsPerPageOptions={[5, 10, 50]}
                                    disableRowSelectionOnClick
                                    experimentalFeatures={{newEditingApi: true}}
                                    autoHeight
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    onPageSizeChange={pageSize => setPagesize(pageSize)}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    {showModal.modalSuppressionEvenement.show &&
                        <ModalDeleteEvenement
                            evenementID={showModal.modalSuppressionEvenement.evenementID}
                            closeModal={handleCloseModalDeleteEvenement}
                        />}
                </ThemeProvider>
            }
        </Fragment>
    )
};
export default ListEvenement;