import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default (props) => {

    const handleChangeNumeroPermis = (event) => {
        props.updateInformation('permis', event.target.value);
    }

    const handleChangeNumeroLicence = (event) => {
        props.updateInformation('licence', event.target.value);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <TextField
                    name="Numero de permis / CASM / BSR"
                    fullWidth
                    id="numero_permis"
                    label="Numero de permis / CASM / BSR"
                    value={props.informationDossier.numeropermis}
                    onChange={handleChangeNumeroPermis}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    name="Numero licence"
                    fullWidth
                    id="numero_licence"
                    label="Numero Licence"
                    onChange={handleChangeNumeroLicence}
                    value={props.informationDossier.numerolicence}
                />
            </Grid>
        </Grid>
    )
}