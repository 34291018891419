import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import PaidIcon from '@mui/icons-material/Paid';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

export default [
    {
        name: 'Evenement',
        url: '/evenements',
        access: ['ADMIN', 'EVENT'],
        logo: <EventIcon/>,
        view: true
    }, {
        name: 'Gestion Evenement',
        url: '/evenement',
        access: ['ADMIN', 'EVENT'],
        view: false
    }, {
        name: 'Insciption',
        url: '/inscriptions',
        access: ['ADMIN', 'INSCR'],
        logo: <SportsMotorsportsIcon/>,
        view: true
    }, {
        name: 'Gestion Insciption',
        url: '/inscription',
        access: ['ADMIN', 'INSCR'],
        view: false
    }, {
        name: 'Benevole',
        url: '/benevoles',
        access: ['ADMIN'],
        logo: <AccessibilityIcon/>,
        view: true
    }, {
        name: 'Utilisateur',
        url: '/utilisateurs',
        access: ['ADMIN', 'GEST_USER'],
        logo: <PersonIcon/>,
        view: true
    }, {
        name: 'Compte Utilisateur',
        url: '/utilisateur',
        access: ['ADMIN', 'GEST_USER'],
        view: false
    }, {
        name: 'Encaissement Inscrit',
        url: '/encaissement-inscrit',
        access: ['ADMIN', 'TRESO'],
        logo: <PaidIcon/>,
        view: true
    }, {
        name: 'Facture',
        url: '/facture',
        access: ['ADMIN', 'TRESO'],
        view: false
    }, {
        name: 'Configuration',
        url: '/configuration',
        access: ['ADMIN', 'CONFIG'],
        logo: <SettingsIcon/>,
        view: true
    }, {
        name: 'Contrôle Administratif',
        url: '/control-administratif',
        access: ['ADMIN', 'INSCR'],
        logo: <QrCodeScannerIcon/>,
        view: true
    }
]