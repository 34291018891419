import {Fragment} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default (props) => {

    const handleChangeScoreStage = (event) => {
        props.onChange('stage', event.target.value);
    }

    const handleChangeScoreCompetition = (event) => {
        props.onChange('competition', event.target.value);
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <TextField fullWidth id="score-stage-input" label="Score stage" variant="outlined" type="number"
                               value={props.data.scoreStage}
                               onChange={handleChangeScoreStage}
                               error={props.formError.scoreStage}
                               helperText={props.formError.scoreStage ? 'Veuillez rensigner le champ' : ''}/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField fullWidth id="score-competition-input" label="Score competition" variant="outlined"
                               type="number"
                               value={props.data.scoreCompetition}
                               onChange={handleChangeScoreCompetition}
                               error={props.formError.scoreCompetition}
                               helperText={props.formError.scoreCompetition ? 'Veuillez rensigner le champ' : ''}/>
                </Grid>
            </Grid>
        </Fragment>
    )
};