import {Fragment} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useState} from "react";
import StatutDossier from "./StatutDossier";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

export default (props) => {

    const [pagesize, setPagesize] = useState(5);

    const navigate = useNavigate();

    const handleClose = () => {
        props.closeModal(false);
    };

    const handleDossierInscription = (cellValue) => {
        navigate(`/inscription/${cellValue.id}`);
        props.closeModal(false);
    };

    const columnsInscription = [
        {
            field: 'barcode',
            headerName: 'N° dossier',
            width: 200,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <Button
                    onClick={() => handleDossierInscription(cellValues)}>{cellValues.row.barcode}</Button>
            }
        }, {
            field: 'nom',
            headerName: 'Info Personnel',
            width: 176,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <Stack direction="column">
                    <Typography sx={{fontSize: 14}}
                                variant="subtitle1">{cellValues.row.utilisateur.prenom} {cellValues.row.utilisateur.nom}</Typography>
                </Stack>
            }
        }, {
            field: 'statut',
            headerName: 'Statut',
            width: 170,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <StatutDossier statut={cellValues.row.statut}/>
            }
        }, {
            field: 'serie',
            headerName: 'Serie',
            width: 150,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? `${params.value.libelle}` : ''
        }, {
            field: 'dejapresent',
            headerName: 'Deja Present',
            width: 110,
            editable: false,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        }
    ];
    const columnsBenevole = [
        {
            field: 'barcode',
            headerName: 'N° dossier',
            width: 200,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <Button size="small">{cellValues.row.barcode}</Button>
            }
        }, {
            field: 'nom',
            headerName: 'Info Personnel',
            width: 176,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <Stack direction="column">
                    <Typography sx={{fontSize: 14}}
                                variant="subtitle1">{cellValues.row.utilisateur.prenom} {cellValues.row.utilisateur.nom}</Typography>
                </Stack>
            }
        }, {
            field: 'statut',
            headerName: 'Statut',
            width: 170,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <StatutDossier statut={cellValues.row.statut}/>
            }
        }, {
            field: 'equipePrincipale',
            headerName: 'Equipe Principale',
            width: 150,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? `${params.value.libelle}` : ''
        }, {
            field: 'equipeSecondaire',
            headerName: 'Equipe Secondaire',
            width: 150,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? `${params.value.libelle}` : ''
        }
    ];

    return (<Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <DialogTitle>Groupe Pilote ({props.groupe.libelle})</DialogTitle>
        </Box>
        <DialogContent>
            <Grid container spacing={3}>
                {props.groupe.inscriptions.length > 0 &&
                    <Fragment>
                        <Grid item md={12}>
                            <Divider>Rouleur</Divider>
                        </Grid>
                        <Grid item md={12}>
                            <DataGrid
                                rows={props.groupe.inscriptions}
                                columns={columnsInscription}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                            />
                        </Grid>
                    </Fragment>
                }
                {props.groupe.benevoles.length > 0 &&
                    <Fragment>
                        <Grid item md={12}>
                            <Divider>Benevole</Divider>
                        </Grid>
                        <Grid item md={12}>
                            <DataGrid
                                rows={props.groupe.benevoles}
                                columns={columnsBenevole}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                            />
                        </Grid>
                    </Fragment>
                }
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant="contained" color="error">fermer</Button>
        </DialogActions>
    </Dialog>)
}