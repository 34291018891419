import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Chip, CircularProgress,
    Menu,
    MenuItem, Stack
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {blueGrey} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import {logOut, selectCurrentToken, selectCurrentUser} from "../../feature/auth/authSlice";
import {useNavigate} from "react-router-dom";
import {Fragment, useEffect} from "react";
import MenuDrawer from "./MenuDrawer";
import {useSelectEvenementQuery} from "../../feature/utilisateur/utilisateurApiSlice";
import ModalSelectionEvenement from "./ModalSelectionEvenement";
import {apiSlice} from "../../app/api/apiSlice";

export default function NavBar() {

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [nomUserAvatar, setNomUserAvatar] = React.useState('');
    const [showModalEvenement, setShowModalEvenement] = React.useState({
        show: false,
        currentEvenementID: '',
    });


    const connected = useSelector(selectCurrentToken);
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const {
        data: currentSelectedEvenement,
        isSuccess: SucessCurrentSelectedEvenement,
        isError: isErreurCurrentSelectedEvenement
    } = useSelectEvenementQuery(null, {skip: !connected});

    let navigate = useNavigate();

    useEffect(() => {
        if (isErreurCurrentSelectedEvenement) {
            navigate('/500');
        }
        if (SucessCurrentSelectedEvenement) {
            if (currentSelectedEvenement === null) {
                handleOpenCloseSelectCurrentEvenement(true);
            }
        }
    }, [isErreurCurrentSelectedEvenement, SucessCurrentSelectedEvenement]);

    useEffect(() => {
        setNomUserAvatar(generateUserAvatar);
    }, [user]);

    const [drawerState, setDrawerState] = React.useState({anchor: 'left', open: false});

    const generateUserAvatar = () => {
        if (user != null) {
            const role = user.role.substring(0, 2);
            return role.toUpperCase();
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({...drawerState, anchor: anchor, open: open});
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        dispatch(apiSlice.util.resetApiState());
        dispatch(logOut());
        setAnchorElUser(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleConnexionButton = () => {
        navigate('/login');
    };

    const handleButtonMonCompte = () => {
        navigate('/mon-compte');
        setAnchorElUser(null);
    }

    const handleClickTitle = () => {
        navigate('/home');
    };

    const handleOpenCloseSelectCurrentEvenement = (choice) => {
        setShowModalEvenement({
            ...showModalEvenement,
            show: choice,
            currentEvenementID: currentSelectedEvenement != null ? currentSelectedEvenement.id : null
        });
        if (!choice) {
            window.location.reload();
        }
    }

    return (
        <div>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static" color="transparent">
                    <Toolbar>
                        <IconButton
                            disabled={!connected}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                            onClick={toggleDrawer('left', true)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}} onClick={handleClickTitle}>
                            Administration Trofeo Rosso
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={3}>
                            {connected && <Fragment>
                                {SucessCurrentSelectedEvenement ?
                                    <Chip
                                        label={currentSelectedEvenement != null ? currentSelectedEvenement.libelle : "pas d'évènement"}
                                        variant="outlined"
                                        onClick={() => {
                                            handleOpenCloseSelectCurrentEvenement(true)
                                        }}/> : <CircularProgress/>}
                            </Fragment>}
                            {!connected &&
                                <Button color="inherit" onClick={handleConnexionButton}>Connexion</Button>}
                            {connected && <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar sx={{bgcolor: blueGrey[500]}}>{nomUserAvatar}</Avatar>
                            </IconButton>}
                        </Stack>
                        {connected && <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key="perruchas" onClick={handleButtonMonCompte}>
                                <Typography textAlign="center">Mon Compte</Typography>
                            </MenuItem>
                            <MenuItem key="anthony" onClick={handleLogout}>
                                <Typography textAlign="center">Déconnexion</Typography>
                            </MenuItem>
                        </Menu>}
                    </Toolbar>
                </AppBar>
            </Box>
            {connected &&
                <MenuDrawer toggleDrawer={drawerState}/>
            }
            {showModalEvenement.show &&
                <ModalSelectionEvenement
                    closeWindows={handleOpenCloseSelectCurrentEvenement}
                    selectEvenement={showModalEvenement.currentEvenementID}
                />
            }
        </div>
    );
}