import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from '@mui/icons-material/Email';
import DangerousIcon from '@mui/icons-material/Dangerous';
import {Tooltip} from "@mui/material";
import {useSelector} from "react-redux";
import {selectCurrentUserDroit} from "../../feature/auth/authSlice";

export default (props) => {

    const user = useSelector(selectCurrentUserDroit);
    const disableLinkUser = user.droits.filter(droit => droit === 'COMPTE_CLIENT').length === 1;

    const handleChange = (newValue) => {
    };

    return (<Grid container spacing={2}>
        <Grid item xs={3} lg={3} md={3}>
            <TextField
                fullWidth
                disabled={true}
                value={props.utilisateur.nom}
                id="outlined-nom-pilote"
                label="Nom"
                variant="outlined"/>
        </Grid>
        <Grid item xs={3} lg={3} md={3}>
            <TextField
                fullWidth
                disabled={true}
                value={props.utilisateur.prenom}
                id="outlined-prenom-pilote"
                label="Prenom"
                variant="outlined"/>
        </Grid>
        <Grid item xs={4} lg={4} md={4}>
            <TextField
                fullWidth
                disabled={true}
                value={props.utilisateur.adresse}
                id="outlined-adresse-pilote"
                label="Adresse"
                variant="outlined"/>
        </Grid>
        <Grid item xs={2} lg={2} md={2}>
            <TextField
                fullWidth
                disabled={true}
                value={props.utilisateur.codepostale}
                id="outlined-code-postale-pilote"
                label="Code Postale"
                variant="outlined"/>
        </Grid>
        <Grid item xs={3.25} lg={3.25} md={3.25}>
            <TextField
                fullWidth
                disabled={true}
                value={props.utilisateur.ville}
                id="outlined-ville-pilote"
                label="Ville"
                variant="outlined"/>
        </Grid>
        <Grid item xs={2} lg={2} md={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                <DesktopDatePicker
                    label="Date de naissance"
                    disabled={true}
                    inputFormat="DD/MM/YYYY"
                    value={dayjs(props.utilisateur.datenaissance)}
                    onChange={handleChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={4.5} lg={4.5} md={4.5}>
            <TextField
                fullWidth
                disabled={true}
                value={props.utilisateur.motoclub != null ? props.utilisateur.motoclub.libelle : 'Pas de motoClub'}
                id="outlined-ville-pilote"
                label="Moto Club"
                variant="outlined"/>
        </Grid>
        <Grid item xs={0.75} lg={0.75} md={0.75}>
            <Tooltip
                title={props.utilisateur.inscriptions.length > 0 ? 'Compte avec un incident' : 'Compte sans incident'}>
                <IconButton aria-label="mail confirmer" size="large">
                    <DangerousIcon fontSize="inherit"
                                   color={props.utilisateur.inscriptions.length > 0 ? 'error' : 'success'}/>
                </IconButton>
            </Tooltip>
        </Grid>
        <Grid item xs={0.75} lg={0.75} md={0.75}>
            <Tooltip title={props.utilisateur.confirmadressemail ? 'E-mail vérifier' : 'E-mail non vérifier'}>
                <IconButton aria-label="mail confirmer" size="large">
                    <EmailIcon fontSize="inherit"
                               color={props.utilisateur.confirmadressemail ? 'success' : 'error'}/>
                </IconButton>
            </Tooltip>
        </Grid>
        <Grid item xs={0.75} lg={0.75} md={0.75}>
            <Tooltip title="Compte client">
                <span>
                    <IconButton aria-label="compte" size="large" disabled={!disableLinkUser}>
                        <AccountCircleIcon fontSize="inherit"/>
                    </IconButton>
                </span>
            </Tooltip>
        </Grid>
    </Grid>)
}