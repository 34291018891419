import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default (props) => {

    const handleChangeDelaiPaiement = (event) => {
        props.updateInfo('delais', event.target.value);
    };
    const handleChangeRelancePaiement = (event) => {
        props.updateInfo('relance', event.target.value);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} lg={6}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={10} lg={10}>
                        <TextField
                            id="outlined-number"
                            label="Délai de paiement"
                            type="number"
                            fullWidth
                            helperText="Délais de paiement en jour"
                            required
                            value={props.dataEvenement.delaiPaiement}
                            onChange={handleChangeDelaiPaiement}

                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={10} lg={10}>
                        <TextField
                            id="outlined-number"
                            label="Relance paiement"
                            type="number"
                            fullWidth
                            helperText="Relance de paiement en jour"
                            required
                            value={props.dataEvenement.relancePaiement}
                            onChange={handleChangeRelancePaiement}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}