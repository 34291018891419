import {apiSlice} from "../../app/api/apiSlice";

const urlLangue = "langue";

export const paysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllLangue: builder.query({
            query: () => ({
                url: `${urlLangue}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllLangue', ...result.id}, {type: 'ListAllLangue', id: 'LIST'}] :
                    [{type: 'ListAllLangue', id: 'LIST'}],
        }),
        addLangue: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlLangue}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        abreviation: data.abreviation
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllLangue', id: 'LIST'}],
        }),
        deleteLangue: builder.mutation({
            query: (langueID) => {
                return {
                    url: `${urlLangue}/${langueID}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: [{type: 'ListAllLangue', id: 'LIST'}],
        }),
        updateLangue: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlLangue}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libelle: data.libelle,
                        abreviation: data.abreviation
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllLangue', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllLangueQuery,
    useAddLangueMutation,
    useDeleteLangueMutation,
    useUpdateLangueMutation,
} = paysApiSlice