import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useDeleteEvenementMutation} from "../../../feature/evenement/evenementApiSlice";
import {useState} from "react";
import formUtils from "../../../utils/formUtils";
import {LoadingButton} from "@mui/lab";
import Alert from '@mui/material/Alert';

export default (props) => {

    const [deleteEvenement] = useDeleteEvenementMutation();

    const [password, setPassword] = useState('');
    const [errorForm, setErrorForm] = useState({
        password: false
    });
    const [loadingSave, setLoadingSave] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => {
        props.closeModal();
    };
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            password: password.length === 0,
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    };

    const handleDeleteEvenement = async () => {
        if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
            setLoadingSave(true);
            setError(false);
            deleteEvenement({evenementID: props.evenementID, password: password}).unwrap()
                .then(() => {
                    setLoadingSave(false);
                    handleClose();
                })
                .catch(error => {
                    setLoadingSave(false);
                    if (error.status === 450) {
                        setErrorMessage('Mot de passe invalide !');
                        setError(true);
                    } else if (error.status === 406) {
                        setErrorMessage(error.data);
                        setError(true);
                    } else {
                        setErrorMessage('Erreur technique essayer ultérieurement  !');
                        setError(true);
                    }
                })
        }
    }
    return (
        <Dialog open={true} fullWidth maxWidth="xs" onClose={handleClose}>
            <DialogTitle sx={{textAlign: 'center', fontWeight: 'bold'}}>Suppression Evenement</DialogTitle>
            <DialogContent>
                <Container component="main" maxWidth="xs" sx={{mt: 1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            Veuillez renseigner votre mot de passe pour supprimer l'évènement :
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                       id="input-field-label-serie"
                                       label="Mot de passe"
                                       variant="outlined"
                                       type="password"
                                       value={password}
                                       onChange={handleChangePassword}
                                       error={errorForm.password}
                                       helperText={errorForm.password ? 'Veuillez renseigner votre mot de passe' : ''}
                                       required
                            />
                        </Grid>
                        {error &&
                            <Grid item xs={12}>
                                <Alert severity="error">{errorMessage}</Alert>
                            </Grid>
                        }
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={loadingSave} variant="contained"
                               onClick={handleDeleteEvenement}>confirmer</LoadingButton>
                <Button variant="contained" color='error' onClick={handleClose}>Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}