import Grid from "@mui/material/Grid";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Popper} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from "@mui/material/Paper";
import {useRef, useState} from "react";
import boutonValidation from "../../utils/boutonValidation";
import HistoryIcon from '@mui/icons-material/History';
import Box from "@mui/material/Box";
import * as Constants from "../../Constants/ContantesDossier";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default (props) => {

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = () => {
        props.changementStatut(boutonValidation(props.statutDossier)[selectedIndex].statut);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleSaveDossier = () => {
        props.enregistrerDossier();
    };
    const handleClickDuplicationDossier = () => {
        props.dupliquerDossier();
    };
    const afficherBouton = props.statutDossier === Constants.STATUT_DOSSIER_ANNULE || props.statutDossier === Constants.STATUT_DOSSIER_REFUSER || props.statutDossier === Constants.STATUT_DOSSIER_ABANDON;
    return (
        <Grid container spacing={2} sx={{marginTop: 3}}>
            <Grid item xs={4} sm={4}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={8} sm={8}>
                        <LoadingButton
                            fullWidth
                            loading={props.loadingEnregistrerDossier}
                            variant="contained"
                            onClick={handleSaveDossier}
                        >
                            Sauvegarder Dossier
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5} sm={5}>
                {!afficherBouton ?
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Box sx={{textAlign: 'center'}}>
                                <ButtonGroup variant="contained" ref={anchorRef}
                                             color={boutonValidation(props.statutDossier)[selectedIndex].color}>
                                    <LoadingButton variant="contained"
                                                   loading={props.loadingValiderDossier}
                                                   color={boutonValidation(props.statutDossier)[selectedIndex].color}
                                                   onClick={handleClick}>{boutonValidation(props.statutDossier)[selectedIndex].libelle}</LoadingButton>
                                    <Button
                                        color={boutonValidation(props.statutDossier)[selectedIndex].color}
                                        aria-controls={open ? 'split-button-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={handleToggle}
                                    >
                                        <ArrowDropDownIcon/>
                                    </Button>
                                </ButtonGroup>
                                <Popper
                                    sx={{
                                        zIndex: 1,
                                    }}
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                    disablePortal
                                >
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom' ? 'center top' : 'center bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList id="split-button-menu" autoFocusItem>
                                                        {boutonValidation(props.statutDossier).map((option, index) => (
                                                            <MenuItem
                                                                key={option.statut}
                                                                selected={index === selectedIndex}
                                                                onClick={(event) => handleMenuItemClick(event, index)}
                                                            >
                                                                {option.libelle}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>
                        </Grid>
                    </Grid> :
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Box sx={{textAlign: 'center'}}>
                                <LoadingButton
                                    loading={props.loadingValiderDossier}
                                    variant="contained"
                                    color="success"
                                    onClick={() => handleClickDuplicationDossier()}
                                    endIcon={<ContentCopyIcon/>}
                                >
                                    Dupliquer dossier</LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>}
            </Grid>
            <Grid item xs={3} sm={3}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={5} sm={5}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => props.openModalHistorique(true)}
                            endIcon={<HistoryIcon/>}
                        >
                            Trace
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}