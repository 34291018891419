import {apiSlice} from "../../app/api/apiSlice";

const urlCompteClient = "compte-client";

export const compteClientApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        rechercheCompteClient: builder.query({
            query: (data) => ({
                url: `${urlCompteClient}/recherche/${data.mail}/${data.evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'RechercheCompteClient', ...result.id}, {type: 'RechercheCompteClient', id: 'LIST'}] :
                    [{type: 'RechercheCompteClient', id: 'LIST'}],
        }),
    })
})

export const {
    useRechercheCompteClientQuery,
} = compteClientApiSlice