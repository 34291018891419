import {Fragment} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import GestionFacture from "../GestionFacture";
import {useParams} from "react-router-dom";
import {
    useGetFactureDetaillerQuery,
    useGetFullInfoFactureQuery
} from "../../../feature/facture/factureApiSlice";

export default () => {

    const {factureId, clientId} = useParams();

    const {
        data: factureDetail,
        isSuccess: SucessFactureDetail,
        isError: isErreurFactureDetail,
        error: errorFactureDetail
    } = useGetFactureDetaillerQuery({factureID: factureId, userID: clientId});

    const {
        data: factureFullInfo,
        isSuccess: SucessFactureFullInfo,
        isError: isErreurFactureFullInfo,
        error: errorFactureFullInfo
    } = useGetFullInfoFactureQuery(factureId);

    const allSuccessLoad = SucessFactureDetail && SucessFactureFullInfo;

    return (
        <Fragment>
            {!allSuccessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop> :
                <GestionFacture
                    facture={factureDetail}
                    fullInfo={factureFullInfo}/>
            }
        </Fragment>
    )
}