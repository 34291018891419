import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import {useState} from "react";
import {LoadingButton} from "@mui/lab";

export default (props) => {

    const [nouveauDossard, setNouveauDossard] = useState('1');
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const handleClose = () => {
        props.closeModal(false);
    };

    const validerChangementDossard = () => {
        setErrorAlert(false);
        const dossardPrecedent = props.dossierInscription.dossard;
        const dossardNouveau = Number(nouveauDossard) + Number(props.dossierInscription.serie.premierdossard);
        if (Number(nouveauDossard) > 99) {
            setErrorAlertMessage('Numéro supérerieur a 99');
            setErrorAlert(true);
        } else if (!/^[0-9]+$/.test(nouveauDossard)) {
            setErrorAlertMessage('Caractère interdit dans la saisie');
            setErrorAlert(true);
        } else if (dossardNouveau === dossardPrecedent) {
            setErrorAlertMessage('Numero identique du dossier courant');
            setErrorAlert(true);
        } else {
            props.saveNouveauDossard(dossardNouveau);
        }
    }

    return (
        <Dialog open={true} fullWidth maxWidth="sm" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Changement Dossard</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    id="outlined-number"
                                    label="Dossard actuelle"
                                    disabled={true}
                                    type="number"
                                    value={props.dossierInscription.dossard}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    id="outlined-number"
                                    label="Premier dossard serie"
                                    disabled={true}
                                    type="number"
                                    value={props.dossierInscription.serie.premierdossard}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    id="outlined-number"
                                    label="Nouveau dossard"
                                    type="number"
                                    value={nouveauDossard}
                                    onChange={(event) => setNouveauDossard(event.target.value)}
                                    inputProps={{min: 1, max: 99, step: 1}}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Alert severity="info">Si le nouveau numéro est déjà affecté à un dossier il sera remplacé par
                            celui du dossier actuel</Alert>
                    </Grid>
                    {errorAlert && <Grid item xs={12} sm={12}>
                        <Alert severity="error">{errorAlertMessage}</Alert>
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={validerChangementDossard} variant="contained"
                               loading={props.isLoading}>valider</LoadingButton>
                <Button onClick={handleClose} variant="contained" color="error">fermer</Button>
            </DialogActions>
        </Dialog>
    )
}