import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import CheckOk from '../../picture/checked.png';
import CheckKo from '../../picture/cancel.png';
import styles from './PolicyPassword.module.css';

export default (props) => {

    const [passwordValidLenght, setpasswordValidLenght] = useState(false);
    const [passwordValidLowerCase, setpasswordValidLowerCase] = useState(false);
    const [passwordValidUpperCase, setpasswordValidUpperCase] = useState(false);
    const [passwordValidnumber, setpasswordValidnumber] = useState(false);

    useEffect(() => {
        const enteredNewPassword = props.password;
        /[a-z]+/.test(enteredNewPassword) ? setpasswordValidLowerCase(true) : setpasswordValidLowerCase(false);
        /[A-Z]+/.test(enteredNewPassword) ? setpasswordValidUpperCase(true) : setpasswordValidUpperCase(false);
        /[1-9]+/.test(enteredNewPassword) ? setpasswordValidnumber(true) : setpasswordValidnumber(false);
        String(enteredNewPassword).length >= 8 ? setpasswordValidLenght(true) : setpasswordValidLenght(false);
    }, [props.password]);

    return (
        <Box sx={{
            border: '2px solid black',
            borderRadius: 2,
            p: 1,
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="body1">Politique de mot de passe
                        : </Typography>
                </Grid>
                <Grid item xs={11} sm={11}>
                    <Typography>Longueur du mot de passe (8) </Typography>
                </Grid>
                <Grid item xs={1} sm={1}>
                    {passwordValidLenght && <img alt="test" src={CheckOk}/>}
                    {!passwordValidLenght && <img alt="test" src={CheckKo}/>}
                </Grid>
                <Grid item xs={11} sm={11}>
                    <Typography>Nombre de caractère minuscule (1)</Typography>
                </Grid>
                <Grid item xs={1} sm={1}>
                    {passwordValidLowerCase && <img alt="test" src={CheckOk}/>}
                    {!passwordValidLowerCase && <img alt="test" src={CheckKo}/>}
                </Grid>
                <Grid item xs={11} sm={11}>
                    <Typography>Nombre de caractère majuscule (1)</Typography>
                </Grid>
                <Grid item xs={1} sm={1}>
                    {passwordValidUpperCase && <img alt="test" src={CheckOk}/>}
                    {!passwordValidUpperCase && <img alt="test" src={CheckKo}/>}
                </Grid>
                <Grid item xs={11} sm={11}>
                    <Typography>Nombre de chiffre (1)</Typography>
                </Grid>
                <Grid item xs={1} sm={1}>
                    {passwordValidnumber && <img alt="test" src={CheckOk}/>}
                    {!passwordValidnumber && <img alt="test" src={CheckKo}/>}
                </Grid>
            </Grid>
        </Box>
    )
}