import {apiSlice} from "../../app/api/apiSlice";

const urlAssurance = "assurance";

export const assuranceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllAssurance: builder.query({
            query: () => ({
                url: `${urlAssurance}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllAssurance', ...result.id}, {type: 'ListAllAssurance', id: 'LIST'}] :
                    [{type: 'ListAllAssurance', id: 'LIST'}],
        }),
        listAssuranceEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlAssurance}/evenement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAssuranceEvenement', ...result.id}, {
                        type: 'ListAssuranceEvenement',
                        id: 'LIST'
                    }] :
                    [{type: 'ListAssuranceEvenement', id: 'LIST'}],
        }),
        removeAssuranceEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, assuranceID} = data;
                return {
                    url: `${urlAssurance}/evenement/${evenementID}`,
                    method: 'DELETE',
                    body: {
                        assuranceid: assuranceID
                    }
                }
            },
            invalidatesTags: [{type: 'ListAssuranceEvenement', id: 'LIST'}],
        }),
        addAssuranceEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, listAssurance} = data;
                return {
                    url: `${urlAssurance}/evenement/${evenementID}`,
                    method: 'POST',
                    body: {
                        assurances: listAssurance
                    }
                }
            },
            invalidatesTags: [{type: 'ListAssuranceEvenement', id: 'LIST'}],
        }),
        createAssurance: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlAssurance}/`,
                    method: 'POST',
                    body: {
                        libellecourt: data.nom,
                        libellelong: data.desciption,
                        prix: data.prix,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllAssurance', id: 'LIST'}],
        }),
        updateAssurance: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlAssurance}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libellecourt: data.nom,
                        libellelong: data.desciption,
                        prix: data.prix,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllAssurance', id: 'LIST'},{type: 'ListAssuranceEvenement', id: 'LIST'}],
        }),
        deleteAssurance: builder.mutation({
            query: (assuranceID) => {
                return {
                    url: `${urlAssurance}/${assuranceID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListAllAssurance', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllAssuranceQuery,
    useListAssuranceEvenementQuery,
    useRemoveAssuranceEvenementMutation,
    useAddAssuranceEvenementMutation,
    useCreateAssuranceMutation,
    useUpdateAssuranceMutation,
    useDeleteAssuranceMutation,
} = assuranceApiSlice