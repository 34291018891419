import {Fragment, useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Switch} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {DataGrid, frFR} from "@mui/x-data-grid";
import formUtils from "../../../utils/formUtils";
import IconButton from "@mui/material/IconButton";
import {DeleteForever} from "@mui/icons-material";

export default (props) => {

    const [newType, setNewType] = useState(false);
    const [oldType, setOldType] = useState(false);
    const [selectedType, setSelectedType] = useState([]);

    const [nomType, setNomType] = useState('');
    const [descriptionType, setDescriptionType] = useState('');
    const [serieSide, setSerieSide] = useState(false);

    const [errorForm, setErrorForm] = useState({
        libelle: false,
        desciption: false
    });

    const [pagesize, setPagesize] = useState(5);

    useEffect(() => {
        if (props.editMode) {
            setNomType(props.editItem.libelle);
            setDescriptionType(props.editItem.description);
            setSerieSide(props.editItem.side);
        }
    }, []);

    const columns = [
        {
            field: 'libelle',
            headerName: 'Nom',
            width: 200,
            editable: false,
        }, {
            field: 'description',
            headerName: 'Description',
            width: 420,
            editable: false,
        }, {
            field: 'side',
            headerName: 'Serie Side',
            type: 'boolean',
            width: 90,
            editable: false,
        },{
            field: 'supprimer',
            headerName: 'Supprimer',
            width: 85,
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton edge="end" aria-label="edit"
                                onClick={() => props.deleteInsciptionType(cellValues)}
                    >
                        <DeleteForever/>
                    </IconButton>)
            }
        },
    ];

    const handleNewType = () => {
        setNewType(true);
        setOldType(false);
    };
    const handleOldType = () => {
        setNewType(false);
        setOldType(true);
    };
    const handleClose = () => {
        props.closeModal();
    };
    const handleAddType = () => {
        if (oldType) {
            props.addType('old', selectedType);
        } else if (newType) {
            if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
                props.addType('new', {
                    libelle: nomType,
                    description: descriptionType,
                    side: serieSide
                });
            }
        } else if (props.editMode) {
            props.addType('update', {
                id: props.editItem.id,
                libelle: nomType,
                description: descriptionType,
                side: serieSide
            });
        }
    };

    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            libelle: nomType.length === 0,
            desciption: descriptionType.length === 0,
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    };

    const handleChangeNom = (event) => {
        setNomType(event.target.value);
    };
    const handleChangeDesciption = (event) => {
        setDescriptionType(event.target.value);
    };
    const handleChangeSide = (event) => {
        setSerieSide(event.target.checked);
    };

    return (
        <Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>{props.editMode ? 'Modifier' : 'Ajouter'} type d'inscription</DialogTitle>
            </Box>
            <DialogContent>
                {!props.editMode &&
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 4}}>
                        <Button onClick={handleNewType} variant="contained">Nouveau type d'inscription</Button>
                        <Button onClick={handleOldType} variant="contained">Ajouter Type d'inscription existant</Button>
                    </Box>
                }
                {(newType || props.editMode) &&
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <TextField fullWidth
                                           id="filled-nom"
                                           label="Nom" variant="outlined"
                                           onChange={handleChangeNom}
                                           value={nomType}
                                           error={errorForm.libelle}
                                           helperText={errorForm.libelle ? 'Veuillez renseigner le champs' : ''}/>
                            </Grid>
                            <Grid item md={6}>
                                <TextField fullWidth
                                           id="filled-desciption"
                                           label="Descrition"
                                           variant="outlined"
                                           onChange={handleChangeDesciption}
                                           value={descriptionType}
                                           error={errorForm.desciption}
                                           helperText={errorForm.desciption ? 'Veuillez renseigner le champs' : ''}/>
                            </Grid>
                            <Grid item md={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch onChange={handleChangeSide} checked={serieSide}/>}
                                        label="Serie side"/>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Fragment>
                }
                {oldType &&
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <DataGrid
                                rows={props.data}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                checkboxSelection
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                                onRowSelectionModelChange={value => setSelectedType(value)}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            {(newType || oldType || props.editMode) &&
                <DialogActions>
                    <Button variant="contained"
                            onClick={handleAddType}>{props.editMode ? 'Enregistrer' : 'Ajouter'}</Button>
                    <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
                </DialogActions>
            }
        </Dialog>
    )
};