import {apiSlice} from "../../app/api/apiSlice";

const urlTShirt = "tshirt";

export const tshirtApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllTShirt: builder.query({
            query: () => ({
                url: `${urlTShirt}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [...result.map(({id}) => ({type: 'ListAllTShirt', id})), {type: 'ListAllTShirt', id: 'LIST'}] :
                    [{type: 'ListAllTShirt', id: 'LIST'}],
        }),
        createTShirt: builder.mutation({
            query: (data) => ({
                url: `${urlTShirt}/`,
                method: 'POST',
                body: {
                    libelle: data.libelle,
                    taille: data.taille
                }
            }),
            invalidatesTags: [{type: 'ListAllTShirt', id: 'LIST'}],
        }),
        updateTShirt: builder.mutation({
            query: (data) => ({
                url: `${urlTShirt}/${data.id}`,
                method: 'POST',
                body: {
                    libelle: data.libelle,
                    taille: data.taille
                }
            }),
            invalidatesTags: [{type: 'ListAllTShirt', id: 'LIST'}],
        }),
        deleteTShirt: builder.mutation({
            query: (TShirtID) => ({
                url: `${urlTShirt}/${TShirtID}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'ListAllTShirt', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllTShirtQuery,
    useCreateTShirtMutation,
    useUpdateTShirtMutation,
    useDeleteTShirtMutation,
} = tshirtApiSlice