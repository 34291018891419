import {Fragment} from "react";
import {Card, List, ListItem, ListItemText, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export default (props) => {

    const handleDeleteType = (event, params) => {
        props.deleteExperience(params);
    };
    const handleEditType = (event, params) => {
        props.editExperience(params);
    };

    return (
        <Fragment>
            {props.listExperience.length > 0 &&
                <Card variant="outlined" sx={{marginTop: 2}}>
                    <List>
                        {props.listExperience.map(result => {
                            return <ListItem key={result.id} secondaryAction={
                                <Stack direction="row" spacing={2}>
                                    <IconButton edge="end" aria-label="edit"
                                                onClick={event => handleEditType(event, result.id)}
                                    >
                                        <ModeEditIcon/>
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete"
                                                onClick={event => handleDeleteType(event, result.id)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Stack>}>
                                <ListItemText
                                    primary={`${result.libelle}      (${result.score})`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Card>}
        </Fragment>
    )
}