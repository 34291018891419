import {apiSlice} from "../../app/api/apiSlice";
const urlNiveau = "niveau";

export const niveauApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllNiveau: builder.query({
            query: () => ({
                url: `${urlNiveau}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllNiveau', ...result.id}, {type: 'ListAllNiveau', id: 'LIST'}] :
                    [{type: 'ListAllNiveau', id: 'LIST'}],
        }),
        listNiveauEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlNiveau}/evenement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListNiveauEvenement', ...result.id}, {type: 'ListNiveauEvenement', id: 'LIST'}] :
                    [{type: 'ListNiveauEvenement', id: 'LIST'}],
        }),
        removeNiveauEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, niveauID} = data;
                return {
                    url: `${urlNiveau}/evenement/${evenementID}`,
                    method: 'DELETE',
                    body: {
                        niveauid: niveauID
                    }
                }
            },
            invalidatesTags: [{type: 'ListNiveauEvenement', id: 'LIST'}],
        }),
        addNiveauEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, listNiveau} = data;
                return {
                    url: `${urlNiveau}/evenement/${evenementID}`,
                    method: 'POST',
                    body: {
                        niveaux: listNiveau
                    }
                }
            },
            invalidatesTags: [{type: 'ListNiveauEvenement', id: 'LIST'}],
        }),
        createNiveau: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlNiveau}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllNiveau', id: 'LIST'}],
        }),
        updateNiveau: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlNiveau}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libelle: data.libelle,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllNiveau', id: 'LIST'},{type: 'ListNiveauEvenement', id: 'LIST'}],
        }),
        deleteNiveau: builder.mutation({
            query: (motoTypeID) => {
                return {
                    url: `${urlNiveau}/${motoTypeID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListAllNiveau', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllNiveauQuery,
    useListNiveauEvenementQuery,
    useRemoveNiveauEvenementMutation,
    useAddNiveauEvenementMutation,
    useCreateNiveauMutation,
    useUpdateNiveauMutation,
    useDeleteNiveauMutation,
} = niveauApiSlice