import {apiSlice} from "../../app/api/apiSlice";

const urlControleAdministratif = "controle-administratif";

export const ControleAdministratifApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        rechercheDossierBarCode: builder.query({
            query: (data) => ({
                url: `${urlControleAdministratif}/recherche-barcode/${data.barcode}/${data.evenementID}`,
                method: 'GET',
            }),
        }),
        validerListDossier: builder.mutation({
            query: (data) => ({
                url: `${urlControleAdministratif}/valider-dossier`,
                method: 'POST',
                body: {
                    listeDossierRouleur: data.listeDossierRouleur,
                    listeDossierBenevole: data.listeDossierBenevole
                }
            }),
            providesTags: () => [{type: 'DossierBarCode'}],
            invalidatesTags: [{type: 'GetInscription'}],
        }),
        rechercheDossierNomPrenom: builder.query({
            query: (data) => ({
                url: `${urlControleAdministratif}/recherche-nom-prenom/${data.evenementID}/${data.nom}/${data.prenom}`,
                method: 'GET',
            }),
            providesTags: () => [{type: 'ListDossierNomPrenom'}]
        }),
    })
})

export const {
    useRechercheDossierBarCodeQuery,
    useValiderListDossierMutation,
    useRechercheDossierNomPrenomQuery,
} = ControleAdministratifApiSlice