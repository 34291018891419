import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useState} from "react";

const AjoutDisponibiliter = (props) => {

    const [selectDispo] = useState('');

    const handleChangeDispo = (event) => {
        props.addDispo(event.target.value);
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="select-disponibiliter-label">Disponibiliter</InputLabel>
            <Select
                id="select-disponibiliter"
                value={selectDispo}
                label="Disponibiliter"
                onChange={handleChangeDispo}
            >
                {props.listDisponibiliter.map(dispo => {
                    return <MenuItem key={dispo.id} value={dispo.id}>{dispo.description}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
};
export default AjoutDisponibiliter;