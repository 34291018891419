import {Chip, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useState} from "react";
import Button from "@mui/material/Button";

export default (props) => {

    const [pagesize, setPagesize] = useState(5);

    const handleClose = () => {
        props.closeModal();
    };

    const columns = [
        {
            field: 'marque',
            headerName: 'Marque',
            width: 130,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'modele',
            headerName: 'Modele',
            width: 130,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'annee',
            headerName: 'Année',
            width: 70,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'partager',
            headerName: 'Moto Partager',
            type: 'boolean',
            width: 110,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'preparer',
            headerName: 'Moto Preparer',
            type: 'boolean',
            width: 110,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'mototype',
            headerName: 'Type de moto',
            width: 130,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value.libelle
        }, {
            field: 'motoinscription',
            headerName: 'Validation',
            width: 100,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <Chip color={cellValues.row.motoinscription.validation ? 'success' : 'error'}
                             label={cellValues.row.motoinscription.validation ? 'Accepter' : 'Refuser'}/>
            }
        }
    ];

    return (<Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <DialogTitle>Liste Moto</DialogTitle>
        </Box>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <DataGrid
                        rows={props.listeMoto}
                        columns={columns}
                        pageSize={pagesize}
                        rowsPerPageOptions={[5, 10, 50]}
                        experimentalFeatures={{newEditingApi: true}}
                        autoHeight
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        onPageSizeChange={pageSize => setPagesize(pageSize)}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant="contained" color="error">fermer</Button>
        </DialogActions>
    </Dialog>)
}