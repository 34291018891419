import {createTheme, Divider, Snackbar, ThemeProvider} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {Fragment, useState} from "react";
import Alert from "@mui/material/Alert";
import {LoadingButton} from "@mui/lab";
import {
    ControleAdministratifApiSlice,
    useRechercheDossierBarCodeQuery, useRechercheDossierNomPrenomQuery,
    useValiderListDossierMutation
} from "../../feature/controle-administratif/controleAdministratifApiSlice";
import InformationPilote from "../../components/gestionInscription/InformationPilote";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import ControleDossierRouleur from "../../components/controle-administratif/ControleDossierRouleur";
import {useDispatch} from 'react-redux';
import ListDossierRecherche from "../../components/controle-administratif/ListDossierRecherche";
import Box from "@mui/material/Box";


const theme = createTheme();

export default (props) => {

    const {evenementID} = props;

    const dispatch = useDispatch();

    const [sucessAlert, setSucessAlert] = useState(false);
    const [sucessAlertMessage, setSucessAlertMessage] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const [firstName, setFirstName] = useState({
        value: '',
        disable: false
    });
    const [lastName, setLastName] = useState({
        value: '',
        disable: false
    });
    const [folderCode, setFolderCode] = useState({
        value: '',
        disable: false
    });
    const [skipRechercheBarCode, setSkipRechercheBarCode] = useState(true);
    const [skipRechercheNomPrenom, setSkipRechercheNomPrenom] = useState(true);
    const [choixValidationDossier, setChoixValidationDossier] = useState([]);

    const [validerListDossier, {isLoading: isLoadingValiderListDossier},] = useValiderListDossierMutation();

    const navigate = useNavigate();

    const clearBarcode = (barcode) => {
        return barcode.replace(/\*/g, '');
    };

    const {
        data: dossierBarCode,
        isSuccess: SucessDossierBarCode,
        isError: isErreurDossierBarCode,
        isFetching: isFetchingDossierBarCode,
    } = useRechercheDossierBarCodeQuery({
        barcode: clearBarcode(folderCode.value),
        evenementID: evenementID
    }, {skip: skipRechercheBarCode});

    const {
        data: listDossierNomPrenom,
        isSuccess: SucessListDossierNomPrenom,
        isError: isErreurListDossierNomPrenom,
        isFetching: isFetchingListDossierNomPrenom,
    } = useRechercheDossierNomPrenomQuery({
        nom: lastName.value.trim().length === 0 ? null : lastName.value,
        prenom: firstName.value.trim().length === 0 ? null : firstName.value,
        evenementID: evenementID
    }, {skip: skipRechercheNomPrenom});

    const globalFetching = isFetchingDossierBarCode || isFetchingListDossierNomPrenom;

    const handleChangeNumeroDossier = (event) => {
        const {value} = event.target;
        let regex = /^\*.{23}\*$/;
        setFolderCode({...folderCode, value});
        const isDisabled = value.length > 0;
        setFirstName({...firstName, disable: isDisabled});
        setLastName({...lastName, disable: isDisabled});
        if (regex.test(value)) {
            setSkipRechercheBarCode(false);
        }
    };
    const resetChampsRecherche = () => {
        setSkipRechercheNomPrenom(true);
        setSkipRechercheBarCode(true);
        setFirstName({...firstName, value: '', disable: false});
        setLastName({...lastName, value: '', disable: false});
        setFolderCode({...folderCode, value: '', disable: false});
    };

    const handleChangeNomDossier = (event) => {
        const {value} = event.target;
        setLastName({...lastName, value});

        const isDisabled = value.length > 0;
        setFolderCode({...folderCode, disable: isDisabled});
    };
    const handleChangePrenomDossier = (event) => {
        const {value} = event.target;
        setFirstName({...firstName, value});

        const isDisabled = value.length > 0;
        setFolderCode({...folderCode, disable: isDisabled});
    };
    const handleRechercheDossierParNomPrenom = (event) => {
        event.preventDefault();
        if (firstName.value.trim().length === 0 && lastName.value.trim().length === 0) {
            setErrorAlertMessage("Veuillez renseigner au moins un champ !");
            setErrorAlert(true);
        } else {
            setSkipRechercheNomPrenom(false);
        }
    };

    const afficherDossierTest = SucessDossierBarCode || SucessListDossierNomPrenom;

    const handleChangeValidationDossier = (id, choixValidation) => {
        setChoixValidationDossier(prevState => {
            return prevState.map(choix =>
                choix.id === id
                    ? {...choix, validate: choixValidation}
                    : choix
            );
        });
    };
    const handleInitierChoixDossier = (id, type) => {
        setChoixValidationDossier(prevState => {
            const updatedArray = [...prevState];
            updatedArray.push({id: id, validate: true, type: type});
            return updatedArray;
        });
    };

    const filterUniqueById = (jsonArray) => {
        const uniqueMap = new Map();
        jsonArray.forEach((item) => {
            if (!uniqueMap.has(item.id)) {
                uniqueMap.set(item.id, item);
            }
        });
        return Array.from(uniqueMap.values());
    };
    const handleValiderDossier = async () => {
        const filterListDossier = filterUniqueById(choixValidationDossier);

        const {listeDossierRouleur, listeDossierBenevole} = filterListDossier.reduce((acc, value) => {
            if (value.type === 'ROULEUR' && value.validate) {
                acc.listeDossierRouleur.push(value.id);
            } else if (value.type === 'BENEVOLE' && value.validate) {
                acc.listeDossierBenevole.push(value.id);
            }
            return acc;
        }, {listeDossierRouleur: [], listeDossierBenevole: []});

        await validerListDossier({listeDossierRouleur, listeDossierBenevole}).unwrap()
            .then(() => {
                setSucessAlertMessage('Le ou les dossiers on été valider !');
                setSucessAlert(true);
                setTimeout(() => {
                    resetChampsRecherche();
                    dispatch(ControleAdministratifApiSlice.util.invalidateTags(['DossierBarCode', 'ListDossierNomPrenom']));
                }, 500);
            })
            .catch(error => {
                if (error.status === 406) {
                    setErrorAlertMessage(error.data);
                    setErrorAlert(true);
                } else {
                    setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                    setErrorAlert(true);
                }
            })
    };

    const handleCloseSucessSave = () => {
        setSucessAlert(false);
    };
    const handleCloseErrorSave = () => {
        setErrorAlert(false);
    };

    const handleNouvelleRecherche = () => {
        resetChampsRecherche();
        dispatch(ControleAdministratifApiSlice.util.invalidateTags(['DossierBarCode', 'ListDossierNomPrenom']));
    };

    const handleRechercheDossierBarcodeRecherche = (barcode) => {
        resetChampsRecherche();
        dispatch(ControleAdministratifApiSlice.util.invalidateTags(['DossierBarCode', 'ListDossierNomPrenom']));
        setFolderCode({...folderCode, value: `*${barcode}*`});
        setSkipRechercheBarCode(false);
    };
    return (
        <ThemeProvider theme={theme}>
            {afficherDossierTest ?
                <Fragment>
                    {SucessDossierBarCode &&
                        <Container component="main" maxWidth="lg" sx={{mb: 10, mt: 2}}>
                            <Grid justifyContent="center" container spacing={2}>
                                <Grid item xs={12} lg={12}>
                                    <Typography align='center' variant="h4">Contrôle Administratif</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Divider sx={{marginTop: 1, marginBottom: 1}}>Information Pilote</Divider>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <InformationPilote
                                        utilisateur={dossierBarCode.utilisateur}/>
                                </Grid>
                                <Grid item xs={6} lg={6} md={6}>
                                    <ControleDossierRouleur
                                        dossierRouleur={dossierBarCode}
                                        choixValiderDossier={handleChangeValidationDossier}
                                        initierChoix={handleInitierChoixDossier}/>
                                </Grid>
                                <Grid item xs={6} lg={6} md={6}>
                                    <Grid justifyContent="center" container spacing={2}>
                                        <Grid item xs={12} lg={12} md={12}>
                                            <Divider sx={{fontWeight: 'bold'}}>Dossier Bénévole</Divider>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12}>
                                            {/*//TODO quand dossier benevole seras fais*/}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sx={{textAlign: 'center', mt: 3}}>
                                    <Grid justifyContent="center" container spacing={2}>
                                        <Grid item xs={6} lg={6} md={6} sx={{textAlign: 'center', mt: 3}}>
                                            <Grid justifyContent="center" container spacing={2}>
                                                <Button variant="contained" color='success'
                                                        onClick={handleValiderDossier}>Valider
                                                    Dossier</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} lg={6} md={6}>
                                            <Button variant="contained" onClick={handleNouvelleRecherche}>Nouvelle
                                                recherche</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>}
                    {SucessListDossierNomPrenom &&
                        <Container component="main" maxWidth="lg" sx={{mb: 10, mt: 2}}>
                            <Grid item xs={12} lg={12}>
                                <Typography align='center' variant="h4">Contrôle Administratif</Typography>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sx={{textAlign: 'center', mt: 3}}>
                                <ListDossierRecherche
                                    dossierRecherche={listDossierNomPrenom}
                                    validerDossier={handleRechercheDossierBarcodeRecherche}/>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sx={{textAlign: 'center', mt: 3}}>
                                <Button variant="contained" onClick={handleNouvelleRecherche}>Nouvelle
                                    recherche</Button>
                            </Grid>
                        </Container>}
                </Fragment>
                :
                <Container component="main" maxWidth="md" sx={{mb: 10, mt: 2}}>
                    <Box component="form" onSubmit={handleRechercheDossierParNomPrenom} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Typography align='center' variant="h4">Contrôle Administratif</Typography>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Alert severity="info">Lors de la recherche d'un dossier, il est recommandé
                                            d'entrer
                                            le
                                            numéro du dossier via un scanner ou une saisie manuelle. Cependant, il est
                                            aussi
                                            possible de saisir le nom ou le prénom de la personne concernée. La
                                            recherche
                                            peut
                                            se faire uniquement sur un seul champ, que ce soit le numéro de dossier, le
                                            nom
                                            ou
                                            le prénom.</Alert>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container spacing={2} justifyContent='center'>
                                            <Grid item xs={6} lg={6}>
                                                <TextField
                                                    label="Numero Dossier"
                                                    value={folderCode.value}
                                                    disabled={folderCode.disable}
                                                    fullWidth
                                                    autoFocus={true}
                                                    onChange={handleChangeNumeroDossier}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Grid container spacing={2} justifyContent='center'>
                                            <Grid item xs={8} lg={8}>
                                                <TextField
                                                    label="Nom"
                                                    value={lastName.value}
                                                    disabled={lastName.disable}
                                                    fullWidth
                                                    onChange={handleChangeNomDossier}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Grid container spacing={2} justifyContent='center'>
                                            <Grid item xs={8} lg={8}>
                                                <TextField
                                                    label="Prénom"
                                                    value={firstName.value}
                                                    disabled={firstName.disable}
                                                    fullWidth
                                                    onChange={handleChangePrenomDossier}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={12} sx={{textAlign: 'center', mt: 3}}>
                                        <LoadingButton loading={globalFetching}
                                                       type="submit" variant="contained">
                                            Rechercher dossier</LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>}
            <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={handleCloseSucessSave}>
                <Alert onClose={handleCloseSucessSave} severity="success" sx={{width: '100%'}}>
                    {sucessAlertMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorAlert} autoHideDuration={3000} onClose={handleCloseErrorSave}>
                <Alert onClose={handleCloseErrorSave} severity="error" sx={{width: '100%'}}>
                    {errorAlertMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}