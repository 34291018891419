import Grid from "@mui/material/Grid";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export default (props) => {

    const handleChangeAssurance = (event) => {
        props.updateAssurance(event.target.value);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={5} lg={5}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Assurance Corporelle</InputLabel>
                            <Select
                                disabled={props.dataDossier.dateHistorique.datePaiement !== null}
                                labelId="assurance-dossier-select"
                                id="assurance-dossier-select"
                                value={props.assuranceData.assuranceID}
                                label="Assurance Corporelle"
                                onChange={handleChangeAssurance}
                            >
                                {props.listAssurance.map(assurance => {
                                    return <MenuItem key={assurance.id}
                                                     value={assurance.id}>{assurance.libellecourt}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}