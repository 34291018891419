import Grid from "@mui/material/Grid";
import {FormControl, FormControlLabel, FormGroup, Switch, TextField} from "@mui/material";
import Box from "@mui/material/Box";

export default (props) => {

    const asSerieSide = (inscriptionTypeID) => {
        if (inscriptionTypeID.length === 0) {
            return false;
        } else {
            return props.listInscriptionType.find(inscriptionType => inscriptionType.id === inscriptionTypeID).side;
        }
    };

    const handleChangeSuiviMarshall = (event) => {
        props.updateComplement('marshall', event.target.checked);
    };
    const handleChangeBapteme = (event) => {
        props.updateComplement('bapteme', event.target.checked);
    };
    const handleChangeIncident = (event) => {
        props.updateComplement('incident', event.target.checked);
    };
    const handleChangeMessageComplementaire = (event) => {
        props.updateComplement('message', event.target.value);
    };

    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} lg={12}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                control={<Switch checked={props.dossierInsciption.suiviMarshall}
                                                 onChange={handleChangeSuiviMarshall}/>}
                                label="Suivi par un marchall"
                                labelPlacement="end"
                            />
                            {asSerieSide(props.dossierInsciption.inscriptionType.inscriptionTypeId) && <FormControlLabel
                                control={<Switch checked={props.dossierInsciption.bapteme}
                                                 onChange={handleChangeBapteme}/>}
                                label="Partage side pour baptême"
                                labelPlacement="end"
                            />}
                            <FormControlLabel sx={{color: `${props.dossierInsciption.incident ? 'red' : 'black'}`}}
                                              control={<Switch checked={props.dossierInsciption.incident}
                                                               onChange={handleChangeIncident}/>}
                                              label="Incident lors l'évènement"
                                              labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={8} lg={8}>
                        <TextField
                            name="Message-complementaire"
                            fullWidth
                            id="Message-complementaire"
                            label="Message"
                            multiline
                            helperText="Message complémentaire"
                            onChange={handleChangeMessageComplementaire}
                            value={props.dossierInsciption.commentaire}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}