import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {Card, CardActions, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import warning from "../../picture/attention.png";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

const theme = createTheme();

const Error500 = () => {

    const navigate = useNavigate();

    const handleClickReturnHome = () => {
        navigate('/home');
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Card variant="outlined" sx={{
                    pl: 2,
                    pr: 2,
                    pb: 2,
                    mt: 8
                }}>
                    <CardContent sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 1,
                        alignItems: 'center',
                    }}>
                        <img src={warning} alt={"OK"} style={{
                            width: 45
                        }}/>
                        <Typography variant="h4">
                            Il y a eu un bug…
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Typography variant="body2">
                            Nous ne savons pas exactement ce qui s'est passé. Vous pouvez revenir en arrière ou de rafraîchir la page
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={handleClickReturnHome} size="small">Revenez à la page d'accueil</Button>
                    </CardActions>
                </Card>
            </Container>
        </ThemeProvider>
    )
};

export default Error500;