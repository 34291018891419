import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

export default (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Marque</TableCell>
                                <TableCell align="center">Modèle</TableCell>
                                <TableCell align="center">Cylindrée</TableCell>
                                <TableCell align="center">Année</TableCell>
                                <TableCell align="center">Assurance</TableCell>
                                <TableCell align="center">Validation</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.listMoto.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell align="center" component="th" scope="row">
                                        {row.marque}
                                    </TableCell>
                                    <TableCell align="center">{row.modele}</TableCell>
                                    <TableCell align="center">{row.cylindree}</TableCell>
                                    <TableCell align="center">{row.annee}</TableCell>
                                    <TableCell
                                        align="center">{row.policeassurance !== null ? row.policeassurance :
                                        <CloseIcon/>}</TableCell>
                                    <TableCell align="center">
                                        {row.motoinscription.validation ?
                                            <CheckCircleIcon color={'success'}/> :
                                            <NotInterestedIcon color={'error'}/>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}