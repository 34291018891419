import {apiSlice} from "../../app/api/apiSlice";

const urlDisponibiliter = "disponibiliter";

export const evenementApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllDisponibiliters: builder.query({
            query: () => ({
                url: `${urlDisponibiliter}/list`,
                method: 'GET',
            }),
            keepUnusedDataFor: 60,
            providesTags: (result) =>
                result ? [{type: 'ListAllDispo', ...result.id}, {type: 'ListAllDispo', id: 'LIST'}] :
                    [{type: 'ListAllDispo', id: 'LIST'}],
        }),
        listDisponibilitersEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlDisponibiliter}/evenement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListDispoEvenement', ...result.id}, {type: 'ListDispoEvenement', id: 'LIST'}] :
                    [{type: 'ListDispoEvenement', id: 'LIST'}],
        }),
        removeDisponibiliterEvenement: builder.mutation({
            query: (data) => ({
                url: `${urlDisponibiliter}/evenement/${data.evenementID}`,
                method: 'DELETE',
                body: {
                    disponibiliterid: data.disponibiliterID
                }
            }),
            invalidatesTags: [{type: 'ListDispoEvenement', id: 'LIST'},{type: 'ListDispoConfig', id: 'LIST'}],
        }),
        addDisponibiliterEvenement: builder.mutation({
            query: (data) => ({
                url: `${urlDisponibiliter}/evenement/${data.evenementID}`,
                method: 'POST',
                body: {
                    disponibiliterid: data.disponibiliterID
                }
            }),
            invalidatesTags: [{type: 'ListDispoEvenement', id: 'LIST'},{type: 'ListDispoConfig', id: 'LIST'}],
        }),
        moveDisponibiliterEvenement: builder.mutation({
            query: (data) => ({
                url: `${urlDisponibiliter}/evenement/${data.evenementID}`,
                method: 'PUT',
                body: {
                    disponibiliterid: data.disponibiliterID,
                    order: data.order
                }
            }),
            invalidatesTags: [{type: 'ListDispoEvenement', id: 'LIST'}],
        }),
        listAllDisponibilitersConfig: builder.query({
            query: () => ({
                url: `${urlDisponibiliter}/list/config`,
                method: 'GET',
            }),
            keepUnusedDataFor: 60,
            providesTags: (result) =>
                result ? [{type: 'ListDispoConfig', ...result.id}, {type: 'ListDispoConfig', id: 'LIST'}] :
                    [{type: 'ListDispoConfig', id: 'LIST'}],
        }),
        createDisponibiliter: builder.mutation({
            query: (data) => ({
                url: `${urlDisponibiliter}/`,
                method: 'POST',
                body: {
                    description: data.description
                }
            }),
            invalidatesTags: [{type: 'ListDispoConfig', id: 'LIST'},{type: 'ListAllDispo', id: 'LIST'}],
        }),
        updateDisponibiliter: builder.mutation({
            query: (data) => ({
                url: `${urlDisponibiliter}/${data.id}`,
                method: 'PUT',
                body: {
                    description: data.description
                }
            }),
            invalidatesTags: [{type: 'ListDispoConfig', id: 'LIST'},{type: 'ListAllDispo', id: 'LIST'}],
        }),
        deleteDisponibiliter: builder.mutation({
            query: (disponibiliterID) => ({
                url: `${urlDisponibiliter}/${disponibiliterID}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'ListDispoConfig', id: 'LIST'},{type: 'ListAllDispo', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllDisponibilitersQuery,
    useListDisponibilitersEvenementQuery,
    useRemoveDisponibiliterEvenementMutation,
    useAddDisponibiliterEvenementMutation,
    useMoveDisponibiliterEvenementMutation,
    useListAllDisponibilitersConfigQuery,
    useCreateDisponibiliterMutation,
    useUpdateDisponibiliterMutation,
    useDeleteDisponibiliterMutation,
} = evenementApiSlice