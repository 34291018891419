import {Fragment} from "react";
import {Card, List, ListItem, ListItemText, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default (props) => {

    const handleDeletePays = (event, params) => {
        props.deleteDispo(params);
    };
    const handleEditPays = (event, params) => {
        props.editDispo(params);
    };

    return (
        <Fragment>
            {props.listDispo.length > 0 &&
                <Card variant="outlined" sx={{marginTop: 1}}>
                    <List>
                        {props.listDispo.map(result => {
                            return <ListItem key={result.id} secondaryAction={
                                <Stack direction="row" spacing={2}>
                                    <IconButton edge="end" aria-label="edit"
                                                onClick={event => handleEditPays(event, result.id)}
                                    >
                                        <ModeEditIcon/>
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" disabled={!result.delete}
                                                onClick={event => handleDeletePays(event, result.id)}
                                    >
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </Stack>}>
                                <ListItemText
                                    primary={`${result.description}`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Card>}
        </Fragment>
    )
}