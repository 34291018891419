import {Dialog, DialogActions, DialogContent, DialogTitle, InputBase} from "@mui/material";
import Box from "@mui/material/Box";
import {DataGrid, frFR} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import {useSelectEvenementQuery} from "../../feature/utilisateur/utilisateurApiSlice";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
    useCreateGroupePiloteMutation,
    useRechercheGroupePiloteQuery
} from "../../feature/groupe-pilote/groupePiloteApiSlice";
import Button from "@mui/material/Button";
import {useAjoutGroupeDossierMutation} from "../../feature/inscription/inscriptionApiSlice";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";

export default (props) => {

    const handleClose = () => {
        props.closeModal(false);
    };

    const [pagesize, setPagesize] = useState(10);
    const [listGroupe, setListGroupe] = useState([]);
    const [skipRecherche, setSkipRecherche] = useState(true)
    const [valeurDeRecherche, setValeurDeRecherche] = useState(props.nomGroupe);
    const [valeurRequest, setValeurRequest] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');
    const [createGroupe, setCreateGroupe] = useState(false);

    const [ajoutGroupeDossier] = useAjoutGroupeDossierMutation();
    const [createGroupePilote] = useCreateGroupePiloteMutation();

    const {
        data: currentSelectedEvenement,
        isSuccess: SucessCurrentSelectedEvenement,
        isError: isErreurCurrentSelectedEvenement
    } = useSelectEvenementQuery(null);

    const {
        data: listGroupePiloteResponse,
        isSuccess: SucessListGroupePiloteResponse,
        isError: isErreurListGroupePiloteResponse
    } = useRechercheGroupePiloteQuery({
        nom: valeurRequest,
        evenementID: currentSelectedEvenement.id
    }, {skip: skipRecherche});

    const handleChangeRecherche = (event) => {
        setValeurDeRecherche(event.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setSkipRecherche(false);
        if (valeurDeRecherche.length > 0) {
            setValeurRequest(valeurDeRecherche);
        }
    };
    const handleAssocierGroupePilote = async () => {
        if (listGroupe.length === 0 && !createGroupe) {
            setErrorAlertMessage('Veuillez sélectionner au moins un groupe à associer');
            setErrorAlert(true);
        } else if (listGroupe.length > 1 && !createGroupe) {
            setErrorAlertMessage('Veuillez sélectionner un seul groupe à associer');
            setErrorAlert(true);
        } else {
            // appel du service d'association de l'utilisateur
            try {
                let groupID = null;
                if (createGroupe) {
                    groupID = await createGroupePilote({
                        libelle: valeurDeRecherche,
                        evenementID: currentSelectedEvenement.id
                    }).unwrap();
                }

                await ajoutGroupeDossier({
                    inscriptionID: props.inscriptionID,
                    groupePiloteID: createGroupe ? groupID : listGroupe[0]
                }).unwrap();
                props.closeModal(false);
            } catch (e) {
                setErrorAlertMessage("Erreur Technique Impossible d'associer le groupe !");
                setErrorAlert(true);
            }
        }
    };
    const handleClickCreationNouveauGroupe = () => {
        setValeurDeRecherche(props.nomGroupe);
        setCreateGroupe(true);
    }

    const columns = [
        {
            field: 'libelle',
            headerName: 'Nom du groupe',
            width: 150,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'inscriptions',
            headerName: 'Nb dossier inscription',
            width: 180,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'benevoles',
            headerName: 'Nb dossier bénévole',
            width: 150,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        },
    ];

    return (
        <Dialog open={true} fullWidth maxWidth="sm" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Associer Groupe</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container onSubmit={handleSubmit} justifyContent="center" spacing={2}>
                    {!createGroupe && <Grid item xs={7} sm={7}>
                        <Box noValidate sx={{mt: 3}}>
                            <Paper
                                component="form"
                                sx={{p: "5px 4px", display: "flex", alignItems: "center"}}
                            >
                                <InputBase
                                    sx={{ml: 1, flex: 1}}
                                    placeholder="Nom du groupe"
                                    inputProps={{"aria-label": "Nom du groupe"}}
                                    onChange={handleChangeRecherche}
                                    value={valeurDeRecherche}
                                    fullWidth
                                />
                                <IconButton type="button" sx={{p: "10px"}} aria-label="search" onClick={handleSubmit}>
                                    <SearchIcon/>
                                </IconButton>
                            </Paper>
                        </Box>
                    </Grid>}
                    {errorAlert && <Grid item xs={10} sm={10}>
                        <Alert severity="error">{errorAlertMessage}</Alert>
                    </Grid>}
                    {(SucessListGroupePiloteResponse && !createGroupe) && <Grid item xs={12} sm={12}>
                        <DataGrid
                            rows={listGroupePiloteResponse}
                            columns={columns}
                            pageSize={pagesize}
                            rowsPerPageOptions={[5, 10]}
                            rowHeight={40}
                            checkboxSelection
                            experimentalFeatures={{newEditingApi: true}}
                            autoHeight
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            onPageSizeChange={pageSize => setPagesize(pageSize)}
                            onRowSelectionModelChange={value => setListGroupe(value)}
                        />
                    </Grid>}
                    {createGroupe && <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            value={valeurDeRecherche}
                            id="outlined-nom-pilote"
                            label="Nom du groupe"
                            variant="outlined"
                            onChange={handleChangeRecherche}
                        />
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                {(SucessListGroupePiloteResponse && !createGroupe) &&
                    <Button variant="contained" onClick={handleClickCreationNouveauGroupe}
                            disabled={listGroupe.length === 1}>crée groupe</Button>}
                {(SucessListGroupePiloteResponse || createGroupe) &&
                    <Button onClick={handleAssocierGroupePilote} disabled={listGroupe.length !== 1 && !createGroupe}
                            variant="contained">associer groupe</Button>}
                <Button onClick={handleClose} variant="contained" color="error">fermer</Button>
            </DialogActions>
        </Dialog>
    )
}