import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';

export default (props) => {

    const handleChangeValidationMoto = (motoID) => {
        props.updateValidation('validation', {motoID: motoID});
    };

    const handleUpdateMoto = (motoID) => {
        props.updateValidation('modal', {show: true, motoID: motoID});
    };

    const handleDeleteMoto = (motoID) => {
        props.updateValidation('delete', {show: true, motoID: motoID});
    };

    const handleOpenAjoutMoto = () => {
        props.openModalAjoutMoto(true);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant="outlined" onClick={handleOpenAjoutMoto}>
                        Associer moto
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Marque</TableCell>
                                <TableCell align="center">Modèle</TableCell>
                                <TableCell align="center">Cylindrée</TableCell>
                                <TableCell align="center">Année</TableCell>
                                <TableCell align="center">Preparer</TableCell>
                                <TableCell align="center">Partager</TableCell>
                                <TableCell align="center">Numero de Série</TableCell>
                                <TableCell align="center">Assurance</TableCell>
                                <TableCell align="center">Type de Moto</TableCell>
                                <TableCell align="center">Validation</TableCell>
                                <TableCell align="center">Modifier</TableCell>
                                <TableCell align="center">Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.listMoto.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell align="center" component="th" scope="row">
                                        {row.modele}
                                    </TableCell>
                                    <TableCell align="center">{row.marque}</TableCell>
                                    <TableCell align="center">{row.cylindree}</TableCell>
                                    <TableCell align="center">{row.annee}</TableCell>
                                    <TableCell align="center">{row.preparer ?
                                        <CheckIcon/> : <CloseIcon/>}</TableCell>
                                    <TableCell align="center">{row.partager ? <CheckIcon/> : <CloseIcon/>}</TableCell>
                                    <TableCell align="center">{row.serialnumber}</TableCell>
                                    <TableCell
                                        align="center">{row.policeassurance !== null ? row.policeassurance :
                                        <CloseIcon/>}</TableCell>
                                    <TableCell align="center">{row.mototype.libelle}</TableCell>
                                    <TableCell align="center">
                                        <Chip color={row.motoinscription.validation ? 'success' : 'error'}
                                              label={row.motoinscription.validation ? 'Accepter' : 'Refuser'}
                                              onClick={() => handleChangeValidationMoto(row.id)}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="edit"
                                                    onClick={() => handleUpdateMoto(row.id)}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="edit"
                                                    onClick={() => handleDeleteMoto(row.id)}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}