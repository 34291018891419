import {Fragment, useEffect, useState} from "react";
import {Backdrop, CircularProgress, createTheme, Divider, Snackbar} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListPays from "../../components/configuration/pays/ListPays";
import {
    useAddPaysMutation,
    useDeletePaysMutation,
    useListAllPaysQuery,
    useUpdatePaysMutation
} from "../../feature/pays/paysApiSlice";
import ModalPays from "../../components/configuration/pays/ModalPays";
import Alert from "@mui/material/Alert";
import {
    useAddLangueMutation,
    useDeleteLangueMutation,
    useListAllLangueQuery, useUpdateLangueMutation
} from "../../feature/langue/langueApiSlice";
import ListLangue from "../../components/configuration/langue/ListLangue";
import ModalLangue from "../../components/configuration/langue/ModalLangue";
import {
    useCreateDisponibiliterMutation, useDeleteDisponibiliterMutation,
    useListAllDisponibilitersConfigQuery, useUpdateDisponibiliterMutation,
} from "../../feature/disponibiliter/disponibiliterApiSlice";
import ListDisponibiliterConfig from "../../components/configuration/disponibiliter/ListDisponibiliterConfig";
import ModalDisponibiliterConfig from "../../components/configuration/disponibiliter/ModalDisponibiliterConfig";
import {
    useCreateTShirtMutation, useDeleteTShirtMutation,
    useListAllTShirtQuery,
    useUpdateTShirtMutation
} from "../../feature/tshirt/tshirtApiSlice";
import ListTShirt from "../../components/configuration/tshirt/ListTShirt";
import ModalTShirt from "../../components/configuration/tshirt/ModalTShirt";
import Typography from "@mui/material/Typography";

const theme = createTheme();

export default () => {

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Configuration';
    }, []);

    const [addPays] = useAddPaysMutation();
    const [deletePays] = useDeletePaysMutation();
    const [updatePays] = useUpdatePaysMutation();

    const [addLangue] = useAddLangueMutation();
    const [deleteLangue] = useDeleteLangueMutation();
    const [updateLangue] = useUpdateLangueMutation();

    const [createDisponibiliter] = useCreateDisponibiliterMutation();
    const [updateDisponibiliter] = useUpdateDisponibiliterMutation();
    const [deleteDisponibiliter] = useDeleteDisponibiliterMutation();

    const [createTShirt] = useCreateTShirtMutation();
    const [updateTShirt] = useUpdateTShirtMutation();
    const [deleteTShirt] = useDeleteTShirtMutation();

    const [loadingGlobal, setLoadingGlobal] = useState(true);
    const [fonctionalErrorAlert, setFonctionalErrorAlert] = useState(false);
    const [technicalErrorAlert, setTechnicalErrorAlert] = useState(false);
    const [messageFonctionalErrorAlert, setMessageFonctionalErrorAlert] = useState('');
    const [showModal, setShowModal] = useState({
        modalPays: {
            show: false,
            edit: false,
            editItem: {}
        }, modalLangue: {
            show: false,
            edit: false,
            editItem: {}
        }, modalDisponibiliter: {
            show: false,
            edit: false,
            editItem: {}
        }, modalTShirt: {
            show: false,
            edit: false,
            editItem: {}
        }
    });

    const {
        data: listAllPays,
        isSuccess: SucessListAllPays,
        isError: isErreurListAllPays
    } = useListAllPaysQuery();

    const {
        data: listAllLangue,
        isSuccess: SucessListAllLangue,
        isError: isErreurListAllLangue
    } = useListAllLangueQuery();

    const {
        data: listDisponibiliter,
        isSuccess: SucessListDisponibiliter,
        isError: isErreurListDisponibiliter
    } = useListAllDisponibilitersConfigQuery();

    const {
        data: listTShirt,
        isSuccess: SucessListTShirt,
        isError: isErreurListTShirt
    } = useListAllTShirtQuery();

    const fetchData = SucessListAllPays && SucessListAllLangue && SucessListDisponibiliter && SucessListTShirt;

    useEffect(() => {
        if (fetchData) {
            setLoadingGlobal(false);
        }
    }, [fetchData]);

    const handleOpenClosePays = (choice) => {
        setShowModal({
            ...showModal,
            modalPays: {...showModal.modalPays, show: choice, edit: false}
        });
    };
    const handleAddPays = (type, value) => {
        if (type === 'new') {
            addPays(value).unwrap()
                .then(() => {
                    handleOpenClosePays(false);
                })
                .catch()
        } else if (type === 'update') {
            updatePays(value).unwrap()
                .then(() => {
                    handleOpenClosePays(false);
                })
        }
    };
    const handleEditPays = (paysID) => {
        setShowModal({
            ...showModal,
            modalPays: {
                edit: true,
                show: true,
                editItem: listAllPays.find(val => val.id === paysID)
            }
        });
    };
    const handleDeletePays = (paysID) => {
        deletePays(paysID).unwrap()
            .catch((error) => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleOpenCloseLangue = (choice) => {
        setShowModal({
            ...showModal,
            modalLangue: {...showModal.modalLangue, show: choice, edit: false}
        });
    };
    const handleAddLangue = (type, value) => {
        if (type === 'new') {
            addLangue(value).unwrap()
                .then(() => {
                    handleOpenCloseLangue(false);
                })
                .catch()
        } else if (type === 'update') {
            updateLangue(value).unwrap()
                .then(() => {
                    handleOpenCloseLangue(false);
                })
        }
    };
    const handleEditLangue = (langueID) => {
        setShowModal({
            ...showModal,
            modalLangue: {
                edit: true,
                show: true,
                editItem: listAllLangue.find(val => val.id === langueID)
            }
        });
    };
    const handleDeleteLangue = (langueID) => {
        deleteLangue(langueID).unwrap()
            .catch((error) => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleOpenCloseDisponibiliter = (choice) => {
        setShowModal({
            ...showModal,
            modalDisponibiliter: {...showModal.modalDisponibiliter, show: choice, edit: false}
        });
    };
    const handleCreateDisponibiliter = (type, value) => {
        if (type === 'new') {
            createDisponibiliter(value).unwrap()
                .then(() => {
                    handleOpenCloseDisponibiliter(false);
                })
                .catch()
        } else if (type === 'update') {
            updateDisponibiliter(value).unwrap()
                .then(() => {
                    handleOpenCloseDisponibiliter(false);
                })
        }
    };
    const handleEditDisponibiliter = (dispoID) => {
        setShowModal({
            ...showModal,
            modalDisponibiliter: {
                edit: true,
                show: true,
                editItem: listDisponibiliter.find(val => val.id === dispoID)
            }
        });
    };
    const handleDeleteDisponibiliter = (dispoID) => {
        deleteDisponibiliter(dispoID).unwrap()
            .catch((error) => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleOpenCloseTShirt = (choice) => {
        setShowModal({
            ...showModal,
            modalTShirt: {...showModal.modalTShirt, show: choice, edit: false}
        });
    };
    const handleCreateTShirt = (type, value) => {
        if (type === 'new') {
            createTShirt(value).unwrap()
                .then(() => {
                    handleOpenCloseTShirt(false);
                })
                .catch()
        } else if (type === 'update') {
            updateTShirt(value).unwrap()
                .then(() => {
                    handleOpenCloseTShirt(false);
                })
        }
    };
    const handleEditTShirt = (tshirtID) => {
        setShowModal({
            ...showModal,
            modalTShirt: {
                edit: true,
                show: true,
                editItem: listTShirt.find(val => val.id === tshirtID)
            }
        });
    };
    const handleDeleteTShirt = (tShirtID) => {
        deleteTShirt(tShirtID).unwrap()
            .catch((error) => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleCloseFonctionalError = () => {
        setFonctionalErrorAlert(false);
    };
    const handleCloseTechnicalError = () => {
        setTechnicalErrorAlert(false);
    };

    return (
        <Fragment>
            {loadingGlobal ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="md" sx={{mb: 10, mt: 2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Typography align='center' variant="h4">Configuration</Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Box sx={{textAlign: 'center'}}>
                                            <Divider textAlign="center">Pays</Divider>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Button variant="contained" size="small"
                                                    onClick={() => handleOpenClosePays(true)}>Ajout Pays</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={10}>
                                                <ListPays
                                                    listPays={listAllPays}
                                                    editPays={handleEditPays}
                                                    deletePays={handleDeletePays}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Box sx={{textAlign: 'center'}}>
                                            <Divider textAlign="center">Langue</Divider>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Button variant="contained" size="small"
                                                    onClick={() => handleOpenCloseLangue(true)}>Ajout Langue</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={10}>
                                                <ListLangue
                                                    listLangue={listAllLangue}
                                                    editLangue={handleEditLangue}
                                                    deleteLangue={handleDeleteLangue}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Box sx={{textAlign: 'center'}}>
                                    <Divider textAlign="center" sx={{
                                        marginTop: 2
                                    }}>Disponibiliter</Divider>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button variant="contained" size="small"
                                            onClick={() => handleOpenCloseDisponibiliter(true)}>Ajout
                                        Disponibiliter</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={12} lg={9}>
                                        <ListDisponibiliterConfig
                                            listDispo={listDisponibiliter}
                                            editDispo={handleEditDisponibiliter}
                                            deleteDispo={handleDeleteDisponibiliter}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Box sx={{textAlign: 'center'}}>
                                    <Divider textAlign="center" sx={{
                                        marginTop: 2
                                    }}>T-Shirt benevole</Divider>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button variant="contained" size="small"
                                            onClick={() => handleOpenCloseTShirt(true)}>Ajout
                                        T-Shirt</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={12} lg={9}>
                                        <ListTShirt
                                            listTShirt={listTShirt}
                                            editTshirt={handleEditTShirt}
                                            deleteTshirt={handleDeleteTShirt}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    {showModal.modalPays.show &&
                        <ModalPays
                            closeWindows={handleOpenClosePays}
                            addPays={handleAddPays}
                            editMode={showModal.modalPays.edit}
                            editItem={showModal.modalPays.editItem}/>}
                    {showModal.modalLangue.show &&
                        <ModalLangue
                            closeWindows={handleOpenCloseLangue}
                            addLangue={handleAddLangue}
                            editMode={showModal.modalLangue.edit}
                            editItem={showModal.modalLangue.editItem}/>}
                    {showModal.modalDisponibiliter.show &&
                        <ModalDisponibiliterConfig
                            closeWindows={handleOpenCloseDisponibiliter}
                            createDispo={handleCreateDisponibiliter}
                            editMode={showModal.modalDisponibiliter.edit}
                            editItem={showModal.modalDisponibiliter.editItem}/>}
                    {showModal.modalTShirt.show &&
                        <ModalTShirt
                            closeWindows={handleOpenCloseTShirt}
                            createTShirt={handleCreateTShirt}
                            editMode={showModal.modalTShirt.edit}
                            editItem={showModal.modalTShirt.editItem}/>}
                </ThemeProvider>
            }
            <Snackbar open={fonctionalErrorAlert} autoHideDuration={5000} onClose={handleCloseFonctionalError}>
                <Alert onClose={handleCloseFonctionalError} severity="error" sx={{width: '100%'}}>
                    {messageFonctionalErrorAlert}
                </Alert>
            </Snackbar>
            <Snackbar open={technicalErrorAlert} autoHideDuration={5000} onClose={handleCloseTechnicalError}>
                <Alert onClose={handleCloseTechnicalError} severity="error" sx={{width: '100%'}}>
                    Erreur technique essayer ultérieurement !
                </Alert>
            </Snackbar>
        </Fragment>
    )
}