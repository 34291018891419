import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export default (props) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Desciption</TableCell>
                        <TableCell align="right">Quantité</TableCell>
                        <TableCell align="right">Prix Unitaire</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.items.map(val => {
                        return <TableRow key={val.id}>
                            <TableCell sx={{fontWeight: 'bolder'}}>{val.libelle}</TableCell>
                            <TableCell align="right">{val.quantiter}</TableCell>
                            <TableCell align="right">{val.prix} €</TableCell>
                            <TableCell align="right">{val.prix_total} €</TableCell>
                        </TableRow>
                    })}
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell/>
                        <TableCell/>
                        <TableCell align="center" sx={{fontWeight: 'bolder'}}
                                   colSpan={1}>Total</TableCell>
                        <TableCell align="right"
                                   sx={{fontWeight: 'bolder'}}>{props.total} €</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}