import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useState} from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import formUtils from "../../utils/formUtils";

export default (props) => {

    const handleClose = () => {
        props.closeModal();
    };

    const handleCreateNewMoto = () => {
        setNewMoto(true);
        setSizeModal("sm");
    };

    const [pagesize, setPagesize] = useState(5);
    const [sizeModal, setSizeModal] = useState("md");
    const [selectedMoto, setSelectedMoto] = useState([]);
    const [newMoto, setNewMoto] = useState(false);
    const [errorMoto, setErrorMoto] = useState({
        typeMoto: {
            error: false,
            message: ''
        },
        marque: {
            error: false,
            message: ''
        },
        modele: {
            error: false,
            message: ''
        },
        cylindree: {
            error: false,
            message: ''
        },
        annee: {
            error: false,
            message: ''
        },
        serialNumber: {
            error: false,
            message: ''
        },
        assurance: {
            error: false,
            message: ''
        },
        partager: {
            error: false,
            message: ''
        },
        preparer: {
            error: false,
            message: ''
        }
    });
    const [infoMoto, setInfoMoto] = useState({
        id: '',
        typeMoto: '',
        marque: '',
        modele: '',
        cylindree: '',
        annee: '',
        serialNumber: '',
        assurance: '',
        partager: false,
        preparer: false
    });

    const verificationFormulaire = () => {
        let returnFormValidity;
        // vérification de champs s'il ne sont pas vide
        const champTypeMoto = formUtils.verifEmptyEntry(infoMoto.typeMoto);
        const champMarqueEmpty = formUtils.verifEmptyEntry(infoMoto.marque);
        const champModelEmpty = formUtils.verifEmptyEntry(infoMoto.modele);
        const champCylindreEmpty = formUtils.verifEmptyEntry(infoMoto.cylindree);
        const champAnneeEmpty = formUtils.verifEmptyEntry(infoMoto.annee);
        const champSerialNumberEmpty = formUtils.verifEmptyEntry(infoMoto.serialNumber);

        setErrorMoto({
            ...errorMoto,
            marque: {
                error: champMarqueEmpty,
                message: champMarqueEmpty ? 'Champs obligatoire' : ''
            },
            modele: {
                error: champModelEmpty,
                message: champModelEmpty ? 'Champs obligatoire' : ''
            },
            cylindree: {
                error: champCylindreEmpty,
                message: champCylindreEmpty ? 'Champs obligatoire' : ''
            },
            annee: {
                error: champAnneeEmpty,
                message: champAnneeEmpty ? 'Champs obligatoire' : ''
            },
            serialNumber: {
                error: champSerialNumberEmpty,
                message: champSerialNumberEmpty ? 'Champs obligatoire' : ''
            },
            typeMoto: {
                error: champTypeMoto,
                message: champTypeMoto ? 'Champs obligatoire' : ''
            }
        });

        returnFormValidity = !champMarqueEmpty && !champModelEmpty && !champCylindreEmpty && !champAnneeEmpty && !champSerialNumberEmpty && !champTypeMoto;

        return returnFormValidity;
    };

    const handleAddNouvelleMoto = () => {
        if (verificationFormulaire()) {
            props.actionMoto('new', {
                motoID: infoMoto.id,
                typeMoto: infoMoto.typeMoto,
                marque: infoMoto.marque,
                modele: infoMoto.modele,
                cylindree: infoMoto.cylindree,
                annee: infoMoto.annee,
                serialNumber: infoMoto.serialNumber,
                assurance: infoMoto.assurance === null || infoMoto.assurance.length === 0 ? null : infoMoto.assurance,
                partager: infoMoto.partager,
                preparer: infoMoto.preparer,
                validation: false
            });
        }
    };
    const handleAddExistMoto = () => {
        props.actionMoto('exist', selectedMoto);
    }

    const columns = [
        {
            field: 'marque',
            headerName: 'Marque',
            headerAlign: 'center',
            width: 150,
            editable: false,
            align: 'center',
        }, {
            field: 'modele',
            headerName: 'Modele',
            headerAlign: 'center',
            width: 130,
            editable: false,
            align: 'center',
        }, {
            field: 'cylindree',
            headerName: 'Cylindrée',
            headerAlign: 'center',
            width: 100,
            editable: false,
            align: 'center',
        }, {
            field: 'annee',
            headerName: 'Année',
            headerAlign: 'center',
            width: 80,
            editable: false,
            align: 'center'
        }, {
            field: 'type-moto',
            headerName: 'Type de moto',
            headerAlign: 'center',
            width: 150,
            editable: false,
            align: 'center',
            renderCell: (cellValues) => {
                return (cellValues.row.mototype.libelle);
            }
        }

    ];

    return (
        <Dialog open={true} fullWidth maxWidth={sizeModal} onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Ajout Moto</DialogTitle>
            </Box>
            {!newMoto &&
                <DialogContent>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item lg={10}>
                            <DataGrid
                                rows={props.listMoto}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                checkboxSelection
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                                onRowSelectionModelChange={value => setSelectedMoto(value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>}
            {newMoto &&
                <DialogContent>
                    <Container component="main">
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={5} lg={5}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={errorMoto.typeMoto.error}>Type moto</InputLabel>
                                            <Select
                                                id="demo-simple-select-type-moto"
                                                value={infoMoto.typeMoto}
                                                label="Type moto"
                                                onChange={event => {
                                                    setInfoMoto({
                                                        ...infoMoto,
                                                        typeMoto: event.target.value
                                                    });
                                                }}
                                                error={errorMoto.typeMoto.error}
                                                required
                                            >
                                                {props.listTypeMoto.map(type => {
                                                    return <MenuItem key={type.id}
                                                                     value={type.id}>{type.libelle}</MenuItem>
                                                })}
                                            </Select>
                                            {<FormHelperText
                                                error={errorMoto.typeMoto.error}>{errorMoto.typeMoto.message}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <TextField
                                    name="Marque"
                                    fullWidth
                                    id="marque_moto"
                                    label="Marque"
                                    value={infoMoto.marque}
                                    onChange={event => {
                                        setInfoMoto({
                                            ...infoMoto,
                                            marque: event.target.value
                                        });
                                    }}
                                    error={errorMoto.marque.error}
                                    helperText={errorMoto.marque.error ? errorMoto.marque.message : ''}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <TextField
                                    name="Modèle"
                                    fullWidth
                                    id="modèle_moto"
                                    label="Modèle"
                                    value={infoMoto.modele}
                                    onChange={event => {
                                        setInfoMoto({
                                            ...infoMoto,
                                            modele: event.target.value
                                        });
                                    }}
                                    error={errorMoto.modele.error}
                                    helperText={errorMoto.modele.error ? errorMoto.modele.message : ''}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <TextField
                                    required
                                    name="Cylindrée"
                                    fullWidth
                                    id="prenom"
                                    label="Cylindrée"
                                    type="number"
                                    value={infoMoto.cylindree}
                                    error={errorMoto.cylindree.error}
                                    helperText={errorMoto.cylindree.error ? errorMoto.cylindree.message : ''}
                                    onChange={event => {
                                        setInfoMoto({
                                            ...infoMoto,
                                            cylindree: Number(event.target.value)
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <TextField
                                    required
                                    name="Année"
                                    fullWidth
                                    id="prenom"
                                    label="Année"
                                    type="number"
                                    value={infoMoto.annee}
                                    error={errorMoto.annee.error}
                                    helperText={errorMoto.annee.error ? errorMoto.annee.message : ''}
                                    onChange={event => {
                                        setInfoMoto({
                                            ...infoMoto,
                                            annee: Number(event.target.value)
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <TextField
                                    required
                                    name="N° de série de ta moto"
                                    fullWidth
                                    id="prenom"
                                    label="N° de série de ta moto"
                                    value={infoMoto.serialNumber}
                                    error={errorMoto.serialNumber.error}
                                    helperText={errorMoto.serialNumber.error ? errorMoto.serialNumber.message : ''}
                                    onChange={event => {
                                        if (formUtils.verifAlpha(event.target.value)) {
                                            setInfoMoto({
                                                ...infoMoto,
                                                serialNumber: event.target.value
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <TextField
                                    name="N° police assurance"
                                    fullWidth
                                    id="prenom"
                                    label="N° police assurance"
                                    value={infoMoto.assurance !== null ? infoMoto.assurance : ''}
                                    error={errorMoto.assurance.error}
                                    helperText={errorMoto.assurance.error ? errorMoto.assurance.message : ''}
                                    onChange={event => {
                                        setInfoMoto({
                                            ...infoMoto,
                                            assurance: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={infoMoto.partager} onChange={event => {
                                            setInfoMoto({
                                                ...infoMoto,
                                                partager: event.target.checked
                                            })
                                        }}/>}
                                        label="Moto est partager"/>
                                    <FormControlLabel
                                        control={<Switch checked={infoMoto.preparer} onChange={event => {
                                            setInfoMoto({
                                                ...infoMoto,
                                                preparer: event.target.checked
                                            })
                                        }}/>}
                                        label="Moto est préparée spécialement pour rouler sur circuit"/>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>}
            <DialogActions>
                {!newMoto &&
                    <Button disabled={selectedMoto.length > 0} onClick={handleCreateNewMoto} variant="contained">Crée
                        moto</Button>}
                {!newMoto && <Button variant="contained" onClick={handleAddExistMoto}
                                     disabled={selectedMoto.length === 0}>Ajouter</Button>}
                {newMoto && <Button variant="contained" onClick={handleAddNouvelleMoto}>Enregistrer</Button>}
                <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}