import {Fragment, useEffect, useState} from "react";
import {createTheme, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useEnvoieMailConfirmationMutation,} from "../../feature/inscription/inscriptionApiSlice";
import {DataGrid, frFR, GridToolbar} from "@mui/x-data-grid";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import StatutDossier from "../../components/gestionInscription/StatutDossier";
import IconButton from "@mui/material/IconButton";
import GroupIcon from '@mui/icons-material/Group';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ModalListGroupeInscription from "../../components/gestionInscription/ModalListGroupeInscription";
import ModalListMoto from "../../components/gestionInscription/ModalListMoto";
import {useNavigate} from "react-router-dom";
import * as Constants from "../../Constants/ContantesDossier";
import {LISTE_STATUTS_DOSSIER} from "../../Constants/ContantesDossier";
import MenuAction from "../../components/listInscription/MenuAction";
import ModalConfirmationAction from "../../components/listInscription/ModalConfirmationAction";
import Alert from "@mui/material/Alert";
import SearchOffIcon from '@mui/icons-material/SearchOff';

const theme = createTheme();

export default (props) => {

    const {listInscription, listSerie, evenementID} = props;

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Inscription';
    }, []);

    const navigate = useNavigate();

    const initialFilter = {
        statut: '',
        serie: '',
        utilisateur: '',
        barcode: ''
    }

    const [pagesize, setPagesize] = useState(50);
    const [showModal, setShowModal] = useState({
        modalGroupe: {
            show: false,
            groupe: {}
        }, modalMoto: {
            show: false,
            listMoto: []
        }
    });
    const [filterFn, setFilterFn] = useState({
        fn: items => {
            return items;
        }
    });
    const [filter, setFilter] = useState(initialFilter);
    const [showModalConfirmAction, setShowModalConfirmAction] = useState({
        show: false,
        data: '',
        action: ''
    });
    const [sucessAlert, setSucessAlert] = useState(false);
    const [sucessAlertMessage, setSucessAlertMessage] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const [envoieMailConfirmation, {isLoading: isLoadingEnvoieMailConfirmation}] = useEnvoieMailConfirmationMutation();

    const loadingAction = isLoadingEnvoieMailConfirmation;

    const handleOpenModalGroupePilote = (groupe) => {
        setShowModal({
            ...showModal,
            modalGroupe: {
                ...showModal.modalGroupe,
                groupe: groupe,
                show: true
            }
        });
    };
    const handleCloseModalGroupePilote = () => {
        setShowModal({
            ...showModal,
            modalGroupe: {
                ...showModal.modalGroupe,
                show: false
            }
        })
    };

    const handleCloseModalMoto = () => {
        setShowModal({
            ...showModal,
            modalMoto: {
                ...showModal.modalMoto,
                show: false
            }
        })
    };
    const handleOpenModalMoto = (listMoto) => {
        setShowModal({
            ...showModal,
            modalMoto: {
                ...showModal.modalMoto,
                listMoto: listMoto,
                show: true
            }
        });
    };

    const formatGroupPilote = (group, idInscription) => {
        return {
            ...group,
            inscriptions: group.inscriptions.length > 0 ? group.inscriptions.filter(val => val.id !== idInscription) : []
        }
    };
    const handleDossierInscription = (cellValue) => {
        navigate(`/inscription/${cellValue.id}`);
    };

    const columns = [
        {
            field: 'barcode',
            headerName: 'N° dossier',
            width: 200,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <Button size="small"
                               onClick={() => handleDossierInscription(cellValues)}>{cellValues.row.barcode}</Button>
            }
        }, {
            field: 'dossard',
            headerName: 'Dossard',
            width: 70,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        }, {
            field: 'dateinscription',
            headerName: 'Date inscription',
            width: 140,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('DD/MM/YYYY HH:mm') : ''
        }, {
            field: 'nom',
            headerName: 'Info Personnelle',
            width: 180,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                const test = `${cellValues.row.utilisateur.prenom} ${cellValues.row.utilisateur.nom}`;
                return <>{test}</>
            }
        }, {
            field: 'serie',
            headerName: 'Serie',
            width: 120,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? `${params.value.libelle}` : ''
        }, {
            field: 'statut',
            headerName: 'Statut',
            width: 170,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <StatutDossier statut={cellValues.row.statut}/>
            }
        }, {
            field: 'facture',
            headerName: 'Payé',
            width: 80,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.statut === Constants.STATUT_DOSSIER_PAYER || cellValues.row.statut === Constants.STATUT_DOSSIER_PRESENT || (cellValues.row.statut === Constants.STATUT_DOSSIER_ANNULE && cellValues.row.facture != null)) {
                    return `${cellValues.row.facture.montant} €`;
                } else {
                    return '0 €'
                }
            }
        }, {
            field: 'zorga',
            headerName: 'Bénevole',
            type: 'boolean',
            width: 75,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'dejapresent',
            headerName: 'Deja présent',
            type: 'boolean',
            width: 100,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'groupe',
            headerName: 'Groupe',
            width: 70,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                let groupFilter;
                let render = cellValues.row.groupe_pilote !== null;
                if (render) {
                    groupFilter = cellValues.row.groupe_pilote;
                    render = groupFilter.inscriptions.length > 0 || groupFilter.benevoles.length > 0 || groupFilter.temp === true
                }
                return (
                    <IconButton disabled={!render} onClick={() => handleOpenModalGroupePilote(groupFilter)}>
                        <GroupIcon/>
                    </IconButton>
                )
            }
        }, {
            field: 'motos',
            headerName: 'Motos',
            width: 70,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton onClick={() => handleOpenModalMoto(cellValues.row.motos)}>
                        <TwoWheelerIcon/>
                    </IconButton>
                )
            }
        }
    ];

    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value == "")
                    return items;
                else
                    return items.filter(x => x.fullName.toLowerCase().includes(target.value))
            }
        })
    }

    const handleActionMenuAction = (action) => {
        switch (action) {
            case 'mail-confirm':
                setShowModalConfirmAction({
                    ...showModalConfirmAction,
                    show: true,
                    data: 'Êtes-vous sûr de vouloir envoyer un mail de confirmation avec QR code à l\'ensemble des dossiers',
                    action: 'mail-confirm'
                });
                break;
        }
    };

    const handleCloseModalConfirmAction = () => {
        setShowModalConfirmAction({
            ...showModalConfirmAction,
            show: false,
            data: '',
            action: ''
        });
    };

    const handleConfirmAction = async (action) => {
        switch (action) {
            case 'mail-confirm':
                await envoieMailConfirmation(evenementID).unwrap()
                    .then(() => {
                        handleCloseModalConfirmAction();
                        setSucessAlertMessage('Mail envoyer avec succès !');
                        setSucessAlert(true);
                    })
                    .catch(() => {
                        handleCloseModalConfirmAction();
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieurement');
                        setErrorAlert(true);
                    })
                break;
        }
    };

    const handleCloseSucessAction = () => {
        setSucessAlert(false);
    };
    const handleCloseErrorAction = () => {
        setErrorAlert(false);
    };

    const handleClearFilter = () => {
        setFilter(initialFilter);
    };

    const filteredRows = listInscription.inscriptions.filter((row) => {
        return Object.keys(filter).every((key) => {
            if (!filter[key]) return true;

            // Vérification de la présence du champ dans row
            if (key in row) {
                let isMatch = false;
                switch (key) {
                    case 'statut':
                        isMatch = filter[key].length > 0 && row.statut.toLowerCase() === filter[key].toLowerCase();
                        break;
                    case 'serie':
                        // Supposons que id_serie soit directement sous row, ajustez selon la structure réelle
                        isMatch = filter[key].length > 0 && row.id_serie !== null && row.id_serie.toString().toLowerCase() === filter[key].toLowerCase();
                        break;
                    case 'utilisateur':
                        if (filter[key].length > 0 && row.utilisateur !== null && row.utilisateur.nom !== null && row.utilisateur.prenom !== null) {
                            const info = filter[key].toLowerCase();
                            isMatch = row.utilisateur.nom.toLowerCase().includes(info) || row.utilisateur.prenom.toLowerCase().includes(info);
                        }
                        break;
                    case 'barcode':
                        isMatch = filter[key].length > 0 && row.barcode.includes(filter[key]);
                        break;
                    default:
                        // Pour les autres cas, conversion en chaîne de caractères pour la comparaison
                        isMatch = row[key].toString().toLowerCase().includes(filter[key].toLowerCase());
                }
                return isMatch;
            }
            return true;
        });
    });


    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xl" sx={{mb: 10, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Typography align='center' variant="h4">Dossiers inscriptions</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} sx={{mt: 2}}>
                            <Grid container justifyContent="flex-end" alignItems="center" direction="row"
                                  spacing={2}>
                                <Grid item xs={1.5} lg={1.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Statut</InputLabel>
                                        <Select
                                            label="Statut"
                                            value={filter.statut}
                                            onChange={(event) => setFilter({...filter, statut: event.target.value})}
                                        >
                                            <MenuItem value="">
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {LISTE_STATUTS_DOSSIER.map(value => {
                                                return <MenuItem key={value} value={value}>{value}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5} lg={1.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-select-small-label">Serie</InputLabel>
                                        <Select
                                            label="Serie"
                                            value={filter.serie}
                                            onChange={(event) => setFilter({...filter, serie: event.target.value})}
                                        >
                                            <MenuItem value="">
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {listSerie.map(value => {
                                                return <MenuItem key={value.id}
                                                                 value={value.id}>{value.libelle}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.75} lg={1.75}>
                                    <TextField size="small"
                                               label="Information Personnelle" variant="outlined"
                                               value={filter.utilisateur}
                                               onChange={(event) => setFilter({
                                                   ...filter,
                                                   utilisateur: event.target.value
                                               })}/>
                                </Grid>
                                <Grid item xs={1.5} lg={1.5}>
                                    <TextField size="small" type="number"
                                               label="N° dossier" variant="outlined"
                                               value={filter.barcode}
                                               onChange={(event) => setFilter({
                                                   ...filter,
                                                   barcode: event.target.value
                                               })}/>
                                </Grid>
                                <Grid item xs={0.5} lg={0.5}>
                                    <IconButton onClick={handleClearFilter}>
                                        <SearchOffIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1} lg={1}>
                                    <MenuAction
                                        actionMenu={handleActionMenuAction}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <DataGrid
                                rows={filteredRows}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                disableRowSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                                slots={{toolbar: GridToolbar}}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
            {showModal.modalGroupe.show &&
                <ModalListGroupeInscription
                    groupe={showModal.modalGroupe.groupe}
                    closeModal={handleCloseModalGroupePilote}
                />
            }
            {showModal.modalMoto.show &&
                <ModalListMoto
                    listeMoto={showModal.modalMoto.listMoto}
                    closeModal={handleCloseModalMoto}
                />}
            {showModalConfirmAction.show &&
                <ModalConfirmationAction
                    data={showModalConfirmAction.data}
                    action={showModalConfirmAction.action}
                    closeModal={handleCloseModalConfirmAction}
                    actionConfirmed={handleConfirmAction}
                    loading={loadingAction}/>}

            <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={handleCloseSucessAction}>
                <Alert onClose={handleCloseSucessAction} severity="success" sx={{width: '100%'}}>
                    {sucessAlertMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorAlert} autoHideDuration={3000} onClose={handleCloseErrorAction}>
                <Alert onClose={handleCloseErrorAction} severity="error" sx={{width: '100%'}}>
                    {errorAlertMessage}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}