import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";

export default (props) => {

    const handleOnClickModifierMontant = () => {
        props.updateMotif(true);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant="outlined" onClick={handleOnClickModifierMontant}>
                        Modifier Motif
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <Card variant="outlined">
                            <CardContent sx={{marginBottom: -1.5}}>
                                <Typography variant="body1" gutterBottom sx={{fontWeight: 'bold'}}>
                                    {props.motifDossierInvalide}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}