import {apiSlice} from "../../app/api/apiSlice";
const urlHoraire = "horaire" ;

export const evenementApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllHoraire: builder.query({
            query: (evenementID) => ({
                url: `${urlHoraire}/list/${evenementID}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: (result) =>
                result ? [{type: 'ListAllHoraire', ...result.id}, {type: 'ListAllHoraire', id: 'LIST'}] :
                    [{type: 'ListAllHoraire', id: 'LIST'}],
        }),
        addHoraire: builder.mutation({
            query: (data) => {
                const {evenementID, dataHoraire} = data;
                return {
                    url: `${urlHoraire}/`,
                    method: 'POST',
                    body: {
                        jour: dataHoraire.jour,
                        heuredebut: dataHoraire.heuredebut,
                        heurefin: dataHoraire.heurefin,
                        serieid: dataHoraire.serieid,
                        evenementid: evenementID
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllHoraire', id: 'LIST'}],
        }),
        deleteHoraire: builder.mutation({
            query: (horaireID) => {
                return {
                    url: `${urlHoraire}/${horaireID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListAllHoraire', id: 'LIST'}],
        }),
        updateHoraire: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlHoraire}/${data.horaireID}`,
                    method: 'PUT',
                    body: {
                        jour: data.jour,
                        heuredebut: data.heuredebut,
                        heurefin: data.heurefin,
                        serieid: data.serieid,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllHoraire', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllHoraireQuery,
    useAddHoraireMutation,
    useDeleteHoraireMutation,
    useUpdateHoraireMutation,
} = evenementApiSlice