import {Fragment, useEffect, useState} from "react";
import {createTheme} from "@mui/material/styles";
import {
    Divider,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    ThemeProvider
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ArticlesEncaissement from "../../components/encaissement/ArticlesEncaissement";
import InformationPilote from "../../components/gestionInscription/InformationPilote";
import CardStatutDossier from "../../components/gestionInscription/CardStatutDossier";
import TypeDePaiement from "../../Constants/TypeDePaiement";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as Constants from "../../Constants/ContantesDossier";
import ModalRemboursementFacture from "../../components/gestionFacture/ModalRemboursementFacture";
import Alert from "@mui/material/Alert";
import CardStatutRemboursement from "../../components/gestionFacture/CardStatutRemboursement";
import ModalHistorique from "../../components/gestionInscription/ModalHistorique";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {useGetFraiCarteBancaireQuery} from "../../feature/facture/factureApiSlice";
import {LoadingButton} from "@mui/lab";

const theme = createTheme();

export default (props) => {

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Facture';
    }, []);

    const initialState = {
        typeRemboursement: props.fullInfo.remboursement_facture !== null ? props.fullInfo.remboursement_facture.remboursementType : null,
        dateRemboursement: props.fullInfo.remboursement_facture !== null ? props.fullInfo.remboursement_facture.date : null,
        montantRemboursement: props.fullInfo.remboursement_facture !== null ? props.fullInfo.remboursement_facture.montant : null,
        commentaireRemboursement: props.fullInfo.remboursement_facture !== null ? props.fullInfo.remboursement_facture.commentaire : null
    };

    const [showModal, setShowModal] = useState({
        modalRemboursement: false,
        modalHistorique: false
    });
    const [sucessAlert, setSucessAlert] = useState(false);
    const [sucessAlertMessage, setSucessAlertMessage] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');
    const [editRemboursement, setEditRemboursement] = useState(true);
    const [dataForm, setDataForm] = useState(initialState);
    const [skipFraiCarte, setSkipFraiCarte] = useState(true);

    const {
        data: fraiCarteBancaire,
        isSuccess: SucessFraiCarteBancaire,
        isLoading: isLoadingFraiCarteBancaire,
        isError: isErreurFraiCarteBancaire,
        error: errorFraiCarteBancaire
    } = useGetFraiCarteBancaireQuery(props.fullInfo.id, {skip: skipFraiCarte});

    useEffect(() => {
        setDataForm(initialState);
    }, [props.fullInfo]);

    useEffect(() => {
        if (SucessFraiCarteBancaire) {
            setShowModal({...showModal, modalRemboursement: true});
        }
    }, [SucessFraiCarteBancaire]);

    const [datePaiement, setDatePaiement] = useState(dayjs(props.fullInfo.dossierInscription.datepaiement));

    const handleChangeDatePaiement = (newValue) => {
        // obligatoire pour le composant
        setDatePaiement(newValue);
    };
    const handleOpenCloseModalRemboursement = (choix) => {
        setShowModal({...showModal, modalRemboursement: choix});
    };
    const handleFetchFraiCarteBancaire = () => {
        if (skipFraiCarte) {
            setSkipFraiCarte(false);
        } else {
            handleOpenCloseModalRemboursement(true);
        }
    };
    const handleOpenCloseModalHistorique = (choix) => {
        setShowModal({...showModal, modalHistorique: choix});
    };
    const handleCloseSucessSave = () => {
        setSucessAlert(false);
    };
    const showSucessSnackBar = (content) => {
        setSucessAlertMessage(content);
        setSucessAlert(true);
    };
    const showErrorSnackBar = (content) => {
        setErrorAlertMessage(content);
        setErrorAlert(true);
    };
    const handleCloseErrorSave = () => {
        setErrorAlert(false);
    };
    const handleEditRemboursement = (choix) => {
        setEditRemboursement(choix);
    };
    const handleChangeMontantRemboursement = (event) => {
        setDataForm({...dataForm, montantRemboursement: event.target.value});
    };
    const handleChangeTypePaiementRemboursement = (event) => {
        setDataForm({...dataForm, typeRemboursement: event.target.value});
    };
    const handleChangeCommentaireRemboursement = (event) => {
        setDataForm({...dataForm, commentaireRemboursement: event.target.value});
    };

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="lg" sx={{mb: 10, mt: 2}}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} lg={12}>
                            <Typography align='center' variant="h4">Gestion Facture</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <CardStatutDossier
                                statut={props.fullInfo.dossierInscription.statut}/>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={6} lg={6}>
                                    <ArticlesEncaissement items={props.facture.facture.items}
                                                          total={props.facture.facture.total}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Divider>Information Pilote</Divider>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <InformationPilote
                                utilisateur={props.fullInfo.dossierInscription.utilisateur}/>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Divider>Information Facture</Divider>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={3} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel disabled={true} id="select-paiement-label">Type de
                                            paiement</InputLabel>
                                        <Select
                                            required
                                            disabled={true}
                                            labelId="select-paiement"
                                            id="select-paiement"
                                            value={props.fullInfo.paiement}
                                            label="Type de paiement"
                                        >
                                            {TypeDePaiement.map(type => {
                                                return <MenuItem key={type} value={type}>{type}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    {props.fullInfo.dossierInscription.datepaiement !== null &&
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                                            <DateTimePicker
                                                disabled={true}
                                                label="Date de paiement"
                                                value={datePaiement}
                                                onChange={handleChangeDatePaiement}
                                                renderInput={(params) => <TextField fullWidth {...params}
                                                />}
                                            />
                                        </LocalizationProvider>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9} lg={9}>
                            <TextField fullWidth label="Commentaire" variant="outlined" disabled
                                       value={props.fullInfo.complement !== null ? props.fullInfo.complement : ''}/>
                        </Grid>
                        {props.fullInfo.dossierInscription.statut === Constants.STATUT_DOSSIER_ANNULE &&
                            <Fragment>
                                <Grid item xs={12} lg={12}>
                                    <Divider>Remboursement</Divider>
                                </Grid>
                                {props.fullInfo.remboursement_facture === null && <Grid item xs={12} lg={12}>
                                    <Box sx={{textAlign: 'center'}}>
                                        <LoadingButton variant="contained" loading={isLoadingFraiCarteBancaire}
                                                       onClick={() => handleFetchFraiCarteBancaire()}>Rembourser
                                            dossier</LoadingButton>
                                    </Box>
                                </Grid>}
                                {props.fullInfo.remboursement_facture !== null && <Fragment>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <CardStatutRemboursement
                                            statut={props.fullInfo.remboursement_facture.statut}/>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel disabled={editRemboursement}>Type de remboursement</InputLabel>
                                            <Select
                                                required
                                                disabled={editRemboursement}
                                                labelId="select-remboursement"
                                                id="select-remboursement"
                                                value={dataForm.typeRemboursement}
                                                onChange={handleChangeTypePaiementRemboursement}
                                                label="Type de remboursement"
                                            >
                                                {TypeDePaiement.map(type => {
                                                    return <MenuItem key={type} value={type}>{type}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                                            <DateTimePicker
                                                disabled={true}
                                                label="Date de remboursement"
                                                value={dataForm.dateRemboursement}
                                                onChange={handleChangeDatePaiement}
                                                renderInput={(params) => <TextField fullWidth {...params}
                                                />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={2} sm={2}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel disabled={editRemboursement}
                                                        htmlFor="outlined-adornment-password">Montant
                                                remboursement</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                value={dataForm.montantRemboursement}
                                                onChange={handleChangeMontantRemboursement}
                                                disabled={editRemboursement}
                                                inputProps={{
                                                    'type': 'number',
                                                    'max': props.montantFacture,
                                                    'min': 0
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">€
                                                    </InputAdornment>
                                                }
                                                label="Montant remboursement"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={9} lg={9}>
                                        <TextField fullWidth label="Commentaire" variant="outlined"
                                                   onChange={handleChangeCommentaireRemboursement}
                                                   disabled={editRemboursement}
                                                   value={dataForm.commentaireRemboursement !== null ? dataForm.commentaireRemboursement : ''}/>
                                    </Grid>
                                </Fragment>}
                            </Fragment>}
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                    {!editRemboursement && <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            onClick={() => handleEditRemboursement(true)}
                                            endIcon={<CloseIcon/>}
                                        >
                                            Annuler
                                        </Button>
                                    </Box>}
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    {props.fullInfo.remboursement_facture !== null &&
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleEditRemboursement(false)}
                                                endIcon={editRemboursement ? <EditIcon/> : <SendIcon/>}
                                            >
                                                {editRemboursement ? 'Modifier' : 'Sauvegarder'} remboursement
                                            </Button>
                                        </Box>}
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleOpenCloseModalHistorique(true)}
                                            endIcon={<HistoryIcon/>}
                                        >
                                            Trace
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={handleCloseSucessSave}>
                    <Alert onClose={handleCloseSucessSave} severity="success" sx={{width: '100%'}}>
                        {sucessAlertMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={errorAlert} autoHideDuration={3000} onClose={handleCloseErrorSave}>
                    <Alert onClose={handleCloseErrorSave} severity="error" sx={{width: '100%'}}>
                        {errorAlertMessage}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
            {showModal.modalRemboursement && <ModalRemboursementFacture
                factureID={props.fullInfo.id}
                paiementType={props.fullInfo.paiement}
                montantFacture={props.facture.facture.total}
                paiementCB={props.fullInfo.paiement_cb}
                montantFraiCarte={fraiCarteBancaire}
                closeModal={handleOpenCloseModalRemboursement}
                sucessSave={showSucessSnackBar}
                errorSave={showErrorSnackBar}/>}
            {showModal.modalHistorique && <ModalHistorique
                closeModal={handleOpenCloseModalHistorique}
                listHistorique={props.fullInfo.dossierInscription.trace_inscriptions}/>}
        </Fragment>
    )
}