import Grid from "@mui/material/Grid";
import {FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";

export default (props) => {

    const handleChangeExperience = (event) => {
        props.updateExperience('experience', event.target.value);
    };
    const handleChangeStage = (event) => {
        props.updateExperience('stage', event.target.checked);
    };
    const handleChangeCompetition = (event) => {
        props.updateExperience('competition', event.target.checked);
    };
    const handleChangeAncienneParticipation = (event) => {
        props.updateExperience('participation', event.target.checked);
    };
    const handleChangeRoulage = (event) => {
        props.updateExperience('roulage', event.target.value);
    };

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3} lg={3}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Expérience</InputLabel>
                    <Select
                        labelId="experience-dossier-select"
                        id="experience-dossier-select"
                        value={props.experienceData.experienceID}
                        label="Expérience"
                        onChange={handleChangeExperience}
                    >
                        {props.listExperience.map(experience => {
                            return <MenuItem key={experience.id} value={experience.id}>{experience.libelle}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9} lg={9}>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            control={<Switch checked={props.experienceData.stage} onChange={handleChangeStage}/>}
                            label="Suivi un stage de piste"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={<Switch checked={props.experienceData.competition}
                                             onChange={handleChangeCompetition}/>}
                            label="Roule en compétition"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            control={<Switch checked={props.experienceData.oldParticipation}
                                             onChange={handleChangeAncienneParticipation}/>}
                            label="Ancienne participation au Trofeo Rosso"
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={8} lg={8}>
                        <TextField
                            name="Roulage-pilote"
                            fullWidth
                            id="Roulage-pilote"
                            label="Roulage"
                            multiline
                            helperText="Roulages auquels tu as participé ces 5 dernières années ? (Année / Circuit / Meilleur temps)"
                            onChange={handleChangeRoulage}
                            value={props.experienceData.experienceRoulage}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}