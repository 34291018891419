import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useState} from "react";
import dayjs from "dayjs";
import Button from "@mui/material/Button";

export default (props) => {

    const [pagesize, setPagesize] = useState(5);

    const handleClose = () => {
        props.closeModal(false);
    };

    const columns = [
        {
            field: 'email',
            headerName: 'Email Compte',
            width: 250,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'role',
            headerName: 'Rôle',
            width: 150,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'action',
            headerName: 'Action',
            width: 350,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'commentaire',
            headerName: 'Commentaire',
            width: 500,
            editable: false,
            align: 'center',
            headerAlign: 'center',
        }, {
            field: 'createdAt',
            headerName: 'Date action',
            width: 140,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('DD/MM/YYYY HH:mm') : ''
        },
    ];

    return (
        <Dialog open={true} fullWidth maxWidth="xl" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Historique dossier</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12}>
                        <DataGrid
                            rows={props.listHistorique}
                            columns={columns}
                            pageSize={pagesize}
                            rowsPerPageOptions={[5, 10]}
                            experimentalFeatures={{newEditingApi: true}}
                            autoHeight
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            onPageSizeChange={pageSize => setPagesize(pageSize)}
                        />
                    </Grid>
                    <Grid item xl={12} lg={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={handleClose} variant="contained" color="error">Fermer</Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}