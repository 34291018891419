import * as Constants from "../Constants/ContantesDossier";

export default (statut) => {
    let array = [];
    if (statut === Constants.STATUT_DOSSIER_ATTENTE_VALIDATION) {
        array.push(
            {libelle: 'Valider dossier', statut: Constants.STATUT_DOSSIER_VALIDER, color: 'success'},
            {libelle: 'Incomplet dossier', statut: Constants.STATUT_DOSSIER_INCOMPLET, color: 'error'},
            {libelle: 'Refuser dossier', statut: Constants.STATUT_DOSSIER_REFUSER, color: 'error'},
            {libelle: 'Annuler dossier', statut: Constants.STATUT_DOSSIER_ANNULE, color: 'error'},
        );
    } else if (statut === Constants.STATUT_DOSSIER_VALIDER) {
        array.push(
            {libelle: 'Confirmer dossier', statut: Constants.STATUT_DOSSIER_CONFIRMER, color: 'success'},
            {
                libelle: 'Mise en liste d\'attente du dossier',
                statut: Constants.STATUT_DOSSIER_ATTENTE,
                color: 'warning'
            },
            {libelle: 'Refuser dossier', statut: Constants.STATUT_DOSSIER_REFUSER, color: 'error'},
            {libelle: 'Annuler dossier', statut: Constants.STATUT_DOSSIER_ANNULE, color: 'error'},
        );
    } else if (statut === Constants.STATUT_DOSSIER_ATTENTE) {
        array.push(
            {libelle: 'Confirmer dossier', statut: Constants.STATUT_DOSSIER_CONFIRMER, color: 'success'},
            {libelle: 'Annuler dossier', statut: Constants.STATUT_DOSSIER_ANNULE, color: 'error'},
        );
    } else if (statut === Constants.STATUT_DOSSIER_INIT ||
        statut === Constants.STATUT_DOSSIER_IN_PROGRESS ||
        statut === Constants.STATUT_DOSSIER_ATTENTE_PAIEMENT ||
        statut === Constants.STATUT_DOSSIER_ERREUR_PAIEMENT ||
        statut === Constants.STATUT_DOSSIER_CONFIRMER ||
        statut === Constants.STATUT_DOSSIER_INCOMPLET) {
        array.push(
            {libelle: 'Annuler dossier', statut: Constants.STATUT_DOSSIER_ANNULE, color: 'error'},
        );
    } else if (statut === Constants.STATUT_DOSSIER_PAYER) {
        array.push(
            {libelle: 'Pilote présent', statut: Constants.STATUT_DOSSIER_PRESENT, color: 'success'},
            {libelle: 'Annuler dossier', statut: Constants.STATUT_DOSSIER_ANNULE, color: 'error'},
        );
    } else if (statut === Constants.STATUT_DOSSIER_PRESENT) {
        array.push(
            {libelle: 'Annuler dossier', statut: Constants.STATUT_DOSSIER_ANNULE, color: 'error'},
            {libelle: 'Abandon pilote', statut: Constants.STATUT_DOSSIER_ABANDON, color: 'error'},
        );
    }

    return array;
}