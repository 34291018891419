import {apiSlice} from "../../app/api/apiSlice";
const urlSerie = "serie";

export const niveauApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllSerie: builder.query({
            query: () => ({
                url: `${urlSerie}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllSerie', ...result.id}, {type: 'ListAllSerie', id: 'LIST'}] :
                    [{type: 'ListAllSerie', id: 'LIST'}],
        }),
        listSerieEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlSerie}/evenement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListSerieEvenement', ...result.id}, {type: 'ListSerieEvenement', id: 'LIST'}] :
                    [{type: 'ListSerieEvenement', id: 'LIST'}],
        }),
        removeSerieEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, serieID} = data;
                return {
                    url: `${urlSerie}/evenement/${evenementID}`,
                    method: 'DELETE',
                    body: {
                        serieid: serieID
                    }
                }
            },
            invalidatesTags: [{type: 'ListSerieEvenement', id: 'LIST'},{type: 'ListAllHoraire', id: 'LIST'}],
        }),
        addSerieEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, listSerie} = data;
                return {
                    url: `${urlSerie}/evenement/${evenementID}`,
                    method: 'POST',
                    body: {
                        series: listSerie
                    }
                }
            },
            invalidatesTags: [{type: 'ListSerieEvenement', id: 'LIST'},{type: 'ListAllHoraire', id: 'LIST'}],
        }),
        createSerie: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlSerie}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        nbacces: data.nbacces,
                        desciption: data.desciption,
                        prix: data.prix,
                        nbmax: data.nbmax,
                        premierdossard: data.premierdossard,
                        scoremin: data.scoremin,
                        scoremax: data.scoremax,
                        inscriptiontypeid: data.inscriptiontypeid,
                        listniveau: data.listniveau,
                        listmototype: data.listmototype,
                        seriebenevole: data.seriebenevole
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllSerie', id: 'LIST'}],
        }),
        updateSerie: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlSerie}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libelle: data.libelle,
                        nbacces: data.nbacces,
                        desciption: data.desciption,
                        prix: data.prix,
                        nbmax: data.nbmax,
                        premierdossard: data.premierdossard,
                        scoremin: data.scoremin,
                        scoremax: data.scoremax,
                        inscriptiontypeid: data.inscriptiontypeid,
                        listniveau: data.listniveau,
                        listmototype: data.listmototype,
                        seriebenevole: data.seriebenevole
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllSerie', id: 'LIST'},{type: 'ListSerieEvenement', id: 'LIST'}],
        }),
        deleteSerie: builder.mutation({
            query: (serieID) => {
                return {
                    url: `${urlSerie}/${serieID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListAllSerie', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllSerieQuery,
    useListSerieEvenementQuery,
    useRemoveSerieEvenementMutation,
    useAddSerieEvenementMutation,
    useCreateSerieMutation,
    useUpdateSerieMutation,
    useDeleteSerieMutation,
} = niveauApiSlice