import Grid from "@mui/material/Grid";
import {
    CardContent, Dialog,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useState, useEffect} from "react";
import formUtils from "../../../utils/formUtils";

const AddRepas = (props) => {

    const [dataRepas, setDataRepas] = useState({
        id: '',
        libelle: '',
        prixPublic: '',
        prixComplet: ''
    });
    const [error, setError] = useState({
        libelle: false,
        prixPublic: false,
        prixComplet: false
    });

    useEffect(() => {
        if (props.editMode) {
            setDataRepas({
                id: props.editItem.id,
                libelle: props.editItem.libelle,
                prixPublic: props.editItem.prixpublic,
                prixComplet: props.editItem.prixcomplet,
            });
        }
    }, []);

    const handleEnregistrer = () => {
        if (formUtils.verifErreurFormulaire(VerifierChamps())) {
            if (props.editMode) {
                props.ajouterRepas('update', dataRepas);
            } else {
                props.ajouterRepas('new', dataRepas);
            }
            props.closeWindows(false);
        }
    };
    const VerifierChamps = () => {
        let ObjVerif;
        ObjVerif = {
            libelle: dataRepas.libelle.length === 0,
            prixPublic: dataRepas.prixPublic.length === 0 || dataRepas.prixPublic === 0,
            prixComplet: dataRepas.prixComplet.length === 0 || dataRepas.prixComplet === 0,
        }
        setError({
            ...error,
            ...ObjVerif
        });
        return ObjVerif;
    };
    const handleClose = () => {
        props.closeWindows(false);
    };

    return (
        <Dialog open={true} fullWidth maxWidth="xs" onClose={handleClose}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={12}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography align='center'
                                            variant="h6">{props.editMode ? 'Modifier' : 'Ajouter'} Repas</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="repas"
                                    label="Repas"
                                    value={dataRepas.libelle}
                                    onChange={event => setDataRepas({...dataRepas, libelle: event.target.value})}
                                    error={error.libelle}
                                    helperText={error.libelle ? 'Veuillez renseigner le champs' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-prix-publique" error={error.prixPublic}>Prix
                                        publique</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-prix-publique"
                                        type="number"
                                        label="Prix publique"
                                        value={dataRepas.prixPublic}
                                        onChange={event => setDataRepas({
                                            ...dataRepas,
                                            prixPublic: Number(event.target.value)
                                        })}
                                        endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                        error={error.prixPublic}
                                    />
                                    {error.prixPublic &&
                                        <FormHelperText id="outlined-error-prix-publique"
                                                        error={error.prixPublic}>Veuillez renseigner le
                                            champs</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-prix-complet" error={error.prixComplet}>Prix
                                        complet</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-prix-complet"
                                        type="number"
                                        label="Prix complet"
                                        value={dataRepas.prixComplet}
                                        onChange={event => setDataRepas({
                                            ...dataRepas,
                                            prixComplet: Number(event.target.value)
                                        })}
                                        endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                        error={error.prixComplet}
                                    />
                                    {error.prixComplet &&
                                        <FormHelperText id="outlined-error-prix-publique"
                                                        error={error.prixComplet}>Veuillez renseigner le
                                            champs</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button variant="contained" sx={{ml: 1}} size="small" color="error"
                                            onClick={() => props.closeWindows(false)}>Annuler</Button>
                                    <Button variant="contained" sx={{ml: 1}} size="small"
                                            onClick={handleEnregistrer}>{props.editMode ? 'Enregistrer' : 'Ajouter'}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Dialog>
    )
};

export default AddRepas;