import {Fragment} from "react";
import {Card, CardContent, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

export default (props) => {

    const handleClickAjouterHoraire = (idHoraire) => {
        props.AjouterHoraire(idHoraire);
    }
    const handleClickDeleteHoraire = (idHoraire) => {
        props.DeleteHoraire(idHoraire);
    }
    const handleClickEditHoraire = (idHoraire, idSerie, idJour) => {
        props.EditHoraire(idHoraire, idSerie, idJour);
    }

    return (
        <Fragment>
            {props.listHoraire.length > 0 &&
                <Grid container spacing={2}>
                    {props.listHoraire.map(serie => {
                        return <Grid key={serie.id} item xs={12} lg={4}>
                            <Card variant="outlined" sx={{marginTop: 2}}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <Typography sx={{fontWeight: 'bold'}} variant="h6">{serie.libelle}</Typography>
                                    {serie.horaires.map(jour => {
                                        return <Fragment key={jour.id}>
                                            <Typography sx={{mb: 1.5, mt: 1, fontWeight: 'bold'}}
                                                        color="text.secondary">
                                                {jour.jour}
                                            </Typography>
                                            <Card variant="outlined" sx={{marginTop: 2}}>
                                                <List>
                                                    {jour.horaires.map(result => {
                                                        return <ListItem key={result.id} secondaryAction={
                                                            <Stack direction="row" spacing={2}>
                                                                <IconButton edge="end" aria-label="edit"
                                                                            onClick={() => handleClickEditHoraire(result.id,serie.id,jour.id)}
                                                                >
                                                                    <ModeEditIcon/>
                                                                </IconButton>
                                                                <IconButton edge="end" aria-label="delete"
                                                                            onClick={() => handleClickDeleteHoraire(result.id)}
                                                                >
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Stack>}>
                                                            <ListItemText
                                                                primary={`${result.heuredebut.slice(0, -3)} - ${result.heurefin.slice(0, -3)}`}
                                                            />
                                                        </ListItem>
                                                    })}
                                                </List>
                                            </Card>
                                        </Fragment>
                                    })}
                                    <Button size="small" variant="contained" sx={{mt: 1}} fullWidth onClick={() => handleClickAjouterHoraire(serie.id)}>Ajouter Horaire</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            }
        </Fragment>
    )
}