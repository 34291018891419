import formUtils from "./formUtils";

const requestEnregistrerInscription = (stateInscription, listInsciptionType) => {
    return {
        inscriptionid: stateInscription.id,
        dejapresent: stateInscription.experience.oldParticipation,
        stage: stateInscription.experience.stage,
        competition: stateInscription.experience.competition,
        inferno: stateInscription.inscriptionType.inferno,
        nomcontact: stateInscription.contact.nom.length > 0 ? stateInscription.contact.nom : null,
        prenomcontact: stateInscription.contact.prenom.length > 0 ? stateInscription.contact.prenom : null,
        numerocontact: stateInscription.contact.numero.length > 0 ? stateInscription.contact.numero : null,
        numerolicence: stateInscription.information.numerolicence.length > 0 ? stateInscription.information.numerolicence : null,
        numeropermis: stateInscription.information.numeropermis.length > 0 ? stateInscription.information.numeropermis : null,
        roulage: stateInscription.experience.experienceRoulage.length > 0 ? stateInscription.experience.experienceRoulage : null,
        experienceid: stateInscription.experience.experienceID.length > 0 ? stateInscription.experience.experienceID : null,
        assuranceid: stateInscription.assurance.assuranceID.length > 0 ? stateInscription.assurance.assuranceID : null,
        motos: stateInscription.moto,
        inscriptiontypeid: stateInscription.inscriptionType.inscriptionTypeId.length > 0 ? stateInscription.inscriptionType.inscriptionTypeId : null,
        partageguidon: stateInscription.inscriptionType.partageGuidon,
        secondguidon: stateInscription.inscriptionType.partageGuidon ? formUtils.formatExtraPilote(stateInscription.inscriptionType.secondGuidon) : null,
        passagerside: verifPassagerSide(listInsciptionType, stateInscription.inscriptionType.inscriptionTypeId) ? formUtils.formatExtraPilote(stateInscription.inscriptionType.passagerSide) : null,
        suivimarshall: stateInscription.suiviMarshall,
        bapteme: stateInscription.bapteme,
        incident: stateInscription.incident,
        commentaire: stateInscription.commentaire.length > 0 ? stateInscription.commentaire : null
    }
};
const verifPassagerSide = (listInsciptionType, inscriptionTypeID) => {
    if (inscriptionTypeID.length > 0) {
        return listInsciptionType.find(insciptionType => insciptionType.id === inscriptionTypeID).side;
    } else {
        return false;
    }
}

export default {
    requestEnregistrerInscription,
}