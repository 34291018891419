import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {DeleteForever} from "@mui/icons-material";
import formUtils from "../../../utils/formUtils";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";

export default (props) => {

    const [nomAssurance, setNomAssurance] = useState('');
    const [desciptionAssurance, setDesciptionAssurance] = useState('');
    const [prixAssurance, setPrixAssurance] = useState('');
    const [newAssurance, setNewAssurance] = useState(false);
    const [oldAssurance, setOldAssurance] = useState(false);
    const [selectedAssurance, setSelectedAssurance] = useState([]);
    const [pagesize, setPagesize] = useState(5);
    const [errorForm, setErrorForm] = useState({
        nom: false,
        desciption: false,
        prix: false,
    });

    useEffect(() => {
        if (props.editMode) {
            setNomAssurance(props.editItem.libellecourt);
            setDesciptionAssurance(props.editItem.libellelong);
            setPrixAssurance(props.editItem.prix);
        }
    }, []);

    const handleClose = () => {
        props.closeModal();
    };
    const handleNewAssurance = () => {
        setNomAssurance('');
        setDesciptionAssurance('');
        setPrixAssurance('');
        setNewAssurance(true);
        setOldAssurance(false);
    };
    const handleOldAssurance = () => {
        setNewAssurance(false);
        setOldAssurance(true);
        setSelectedAssurance([]);
    };
    const handleTemplateAssurance = () => {
        const objectType = props.data.filter(val => val.id === selectedAssurance[0])[0];
        setNomAssurance(objectType.libellecourt);
        setDesciptionAssurance(objectType.libellelong);
        setPrixAssurance(objectType.prix);
        setNewAssurance(true);
        setOldAssurance(false);
    };

    const handleAddAssurance = () => {
        if (oldAssurance) {
            props.addAssurance('old', selectedAssurance);
        } else if (newAssurance) {
            if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
                props.addAssurance('new', {
                    nom: nomAssurance,
                    desciption: desciptionAssurance,
                    prix: prixAssurance
                });
            }
        } else if (props.editMode) {
            if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
                props.addAssurance('update', {
                    id: props.editItem.id,
                    nom: nomAssurance,
                    desciption: desciptionAssurance,
                    prix: prixAssurance
                });
            }
        }
    };
    const handleChangeNomAssurance = (event) => {
        setNomAssurance(event.target.value);
    };
    const handleChangeDesciptionAssurance = (event) => {
        setDesciptionAssurance(event.target.value);
    };
    const handleChangePrixAssurance = (event) => {
        setPrixAssurance(event.target.value);
    };

    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            nom: nomAssurance.length === 0,
            desciption: desciptionAssurance.length === 0,
            prix: prixAssurance.length === 0
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    };

    const columns = [
        {
            field: 'libellecourt',
            headerName: 'Nom',
            width: 150,
            editable: false,
        }, {
            field: 'libellelong',
            headerName: 'Desciption',
            width: 480,
            editable: false,
        }, {
            field: 'prix',
            headerName: 'Prix',
            width: 70,
            editable: false,
            valueGetter: (params) =>
                `${params.row.prix} €`,
        }, {
            field: 'supprimer',
            headerName: 'Supprimer',
            width: 85,
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton edge="end" aria-label="edit"
                                onClick={() => props.deleteAssurance(cellValues)}
                    >
                        <DeleteForever/>
                    </IconButton>)
            }
        },
    ];

    return (
        <Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>{props.editMode ? 'Modifier' : 'Ajouter'} Assurance</DialogTitle>
            </Box>
            <DialogContent>
                {!props.editMode &&
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 4}}>
                        <Button onClick={handleNewAssurance} variant="contained">Nouvelle assurance</Button>
                        <Button onClick={handleOldAssurance} variant="contained">Ajouter assurance existante</Button>
                    </Box>
                }
                {(newAssurance || props.editMode) &&
                    <Container component="main" maxWidth="md">
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item lg={6}>
                                <TextField
                                    fullWidth
                                    id="input-field-label-nom-assurance"
                                    label="Nom Assurance"
                                    variant="outlined"
                                    onChange={handleChangeNomAssurance}
                                    value={nomAssurance}
                                    error={errorForm.nom}
                                    helperText={errorForm.nom ? 'Veuillez renseigner le champs' : ''}
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <TextField
                                        id="input-field-label-prix-assurance"
                                        label="Prix Assurance"
                                        variant="outlined"
                                        type="number"
                                        onChange={handleChangePrixAssurance}
                                        value={prixAssurance}
                                        error={errorForm.prix}
                                        helperText={errorForm.prix ? 'Veuillez renseigner le champs' : ''}
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    id="input-field-label-desciption-assurance"
                                    label="Desciption Assurance"
                                    variant="outlined"
                                    onChange={handleChangeDesciptionAssurance}
                                    value={desciptionAssurance}
                                    error={errorForm.desciption}
                                    helperText={errorForm.desciption ? 'Veuillez renseigner le champs' : ''}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                }
                {oldAssurance &&
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <DataGrid
                                rows={props.data}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                checkboxSelection
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                                onRowSelectionModelChange={value => setSelectedAssurance(value)}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            {(newAssurance || oldAssurance || props.editMode) &&
                <DialogActions>
                    {oldAssurance && <Button disabled={selectedAssurance.length !== 1 && oldAssurance}
                                             variant="contained" onClick={handleTemplateAssurance}>Template</Button>}
                    <Button variant="contained" onClick={handleAddAssurance}
                            disabled={selectedAssurance.length === 0 && oldAssurance}>{props.editMode ? 'Enregistrer' : 'Ajouter'}</Button>
                    <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
                </DialogActions>
            }
        </Dialog>
    )
}