import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {LoadingButton} from "@mui/lab";

export default (props) => {

    const handleClose = () => {
        props.closeModal();
    };

    const handleConfirmAction = () => {
        props.actionConfirmed(props.action);
    }

    return (
        <Dialog open={true} fullWidth maxWidth="sm" onClose={handleClose}>
            <DialogContent>
                <Box sx={{display: 'flex', justifyContent: 'center', mb: 3}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'center'}}>{props.data}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={6} lg={6} sx={{textAlign: 'center'}}>
                        <LoadingButton loading={props.loading} variant="contained"
                                       onClick={handleConfirmAction}>Confirmer</LoadingButton>
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{textAlign: 'center'}}>
                        <Button variant="contained" color="error" onClick={handleClose}>Annuler</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}