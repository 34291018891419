import {Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import Toolbar from "@mui/material/Toolbar";
import {useSelector} from "react-redux";
import {selectCurrentTokenPayload} from "../../feature/auth/authSlice";
import pageAdministration from "../../utils/pageAdministration";
import {useNavigate} from "react-router-dom";

const MenuDrawer = (props) => {

    const TokenPayload = useSelector(selectCurrentTokenPayload);

    let navigate = useNavigate();

    let listDrawer = [] ;

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    pageAdministration.forEach( page => {
        if(TokenPayload.droits.filter( tokenDroit => page.access.filter( access => access === tokenDroit).length > 0).length > 0) {
            if (page.view) {
                listDrawer.push(page);
            }
        }
    });

    useEffect(() => {
        setState({...state, [props.toggleDrawer.anchor]: props.toggleDrawer.open});
    }, [props.toggleDrawer]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };

    const handleClickPage = (urlPage) => event => {
        navigate(urlPage);
    };
    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Toolbar/>
            <List>
                {listDrawer.map((value) => (
                    <ListItem key={value.name} disablePadding>
                        <ListItemButton onClick={handleClickPage(value.url)}>
                            <ListItemIcon>
                                {value.logo}
                            </ListItemIcon>
                            <ListItemText primary={value.name}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
        >
            {list('left')}
        </Drawer>)
};

export default MenuDrawer;