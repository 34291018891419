import Grid from "@mui/material/Grid";
import {FormControl, FormControlLabel, FormGroup, Switch} from "@mui/material";

export default (props) => {

    const handleChangeMail = (event) => {
        props.updateInfo('mail', event.target.checked);
    };
    const handleChangeSMS = (event) => {
        props.updateInfo('sms', event.target.checked);
    };

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6} lg={6}>
                <FormControl fullWidth>
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            control={<Switch checked={props.dataEvenement.suiviDossierMail}
                                             onChange={handleChangeMail}/>}
                            label="Suivi dossier mail"
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
                <FormControl fullWidth>
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            control={<Switch checked={props.dataEvenement.suiviDossierSMS}
                                             onChange={handleChangeSMS}/>}
                            label="Suivi dossier SMS"
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}