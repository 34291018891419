import {Fragment} from "react";
import {useSelectEvenementQuery} from "../../../feature/utilisateur/utilisateurApiSlice";
import {Backdrop, CircularProgress} from "@mui/material";
import ControleAdministratif from "../ControleAdministratif";

export default () => {

    const {
        data: currentSelectedEvenement,
        isSuccess: SucessCurrentSelectedEvenement,
        isError: isErreurCurrentSelectedEvenement
    } = useSelectEvenementQuery(null);

    const allSuccessLoad = SucessCurrentSelectedEvenement;

    return(
        <Fragment>
            {!allSuccessLoad ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop> :
                <ControleAdministratif
                    evenementID={currentSelectedEvenement.id}/>
            }
        </Fragment>
    )
}