import {apiSlice} from "../../app/api/apiSlice";
const urlDroit = "droit" ;

export const evenementApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllDroit: builder.query({
            query: () => ({
                url: `${urlDroit}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllDroit', ...result.id}, {type: 'ListAllDroit', id: 'LIST'}] :
                    [{type: 'ListAllDroit', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllDroitQuery,
} = evenementApiSlice