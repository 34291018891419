import {Fragment, useEffect, useState} from "react";
import {Card, List, ListItem, ListItemText, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

const ListDisponibiliter = (props) => {

    const handleUpMoto = (event, params) => {
        props.orderDispo(params, 'up');
    };

    const handleDownMoto = (event, params) => {
        props.orderDispo(params, 'down');
    };
    const handleDeleteMoto = (event, params) => {
        props.deleteDispo(params);
    };

    return (
        <Fragment>
            {props.listDisponibiliter.length > 0 &&
                <Card variant="outlined"  sx={{marginTop: 2}}>
                    <List>
                        {props.listDisponibiliter.map(result => {
                            return <ListItem key={result.id} secondaryAction={
                                <Stack direction="row" spacing={2}>
                                    <IconButton edge="end" aria-label="up"
                                                disabled={result.order === 1 || props.listDisponibiliter.length === 1}
                                                onClick={event => handleUpMoto(event, result.id)}
                                    >
                                        <ArrowUpwardIcon/>
                                    </IconButton>
                                    <IconButton edge="end" aria-label="down"
                                                disabled={result.order === props.listDisponibiliter.length || props.listDisponibiliter.length === 1}
                                                onClick={event => handleDownMoto(event, result.id)}
                                    >
                                        <ArrowDownwardIcon/>
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete"
                                                onClick={event => handleDeleteMoto(event, result.id)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Stack>}>
                                <ListItemText
                                    primary={`${result.description}`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Card>}
        </Fragment>
    )
};

export default ListDisponibiliter;