import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import 'dayjs/locale/fr';

export default (props) => {

    const handleChangeNomEvenement = (event) => {
        props.updateInformation('name', event.target.value);
    }
    const handleChangeDateEvenement = (newValue) => {
        props.updateInformation('date', newValue);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <TextField
                    name="Nom de l'évènement"
                    fullWidth
                    id="input-text-nom-evenement"
                    label="Nom de l'évènement"
                    variant="outlined"
                    value={props.data.nomEvenement}
                    onChange={handleChangeNomEvenement}
                    error={props.formError.nomEvenement}
                    helperText={props.formError.nomEvenement ? 'Veuillez rensigner le champ' : ''}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box sx={{
                    textAlign: 'center'
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={props.locale}>
                        <DesktopDatePicker
                            label="Date de l'évènement"
                            inputFormat="DD/MM/YYYY"
                            value={props.data.dateEvenement}
                            onChange={handleChangeDateEvenement}
                            renderInput={(params) => <TextField {...params}
                                                                error={props.formError.dateEvenement}
                                                                helperText={props.formError.dateEvenement ? 'Veuillez rensigner le champ' : ''}/>}
                        />
                    </LocalizationProvider>
                </Box>
            </Grid>
        </Grid>)
}