import Box from "@mui/material/Box";
import {Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, InputBase} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useState} from "react";
import {useRechercheCompteClientQuery} from "../../feature/compte-client/compteClientApiSlice";
import Button from "@mui/material/Button";
import {useAjoutExtraPiloteMutation} from "../../feature/inscription/inscriptionApiSlice";
import Alert from "@mui/material/Alert";
import {useSelectEvenementQuery} from "../../feature/utilisateur/utilisateurApiSlice";
import Typography from "@mui/material/Typography";

export default (props) => {

    const handleClose = () => {
        props.closeModal(false);
    };

    const [valeurDeRecherche, setValeurDeRecherche] = useState(props.mailCompteClient);
    const [valeurRequest, setValeurRequest] = useState('');
    const [skipRecherche, setSkipRecherche] = useState(true);
    const [listCompteClient, setListCompteClient] = useState([]);
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const [ajoutExtraPilote] = useAjoutExtraPiloteMutation();

    const {
        data: currentSelectedEvenement,
        isSuccess: SucessCurrentSelectedEvenement,
        isError: isErreurCurrentSelectedEvenement
    } = useSelectEvenementQuery(null);

    const {
        data: listCompteClientResponse,
        isSuccess: SucessListCompteClientResponse,
        isError: isErreurListCompteClientResponse
    } = useRechercheCompteClientQuery({
        mail: valeurRequest,
        evenementID: currentSelectedEvenement.id
    }, {skip: skipRecherche});

    const columns = [
        {
            field: 'nom',
            headerName: 'Nom',
            width: 180,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'prenom',
            headerName: 'Prenom',
            width: 180,
            align: 'center',
            editable: false,
            headerAlign: 'center'
        },
        {
            field: 'adressemail',
            headerName: 'Adresse mail',
            width: 300,
            editable: false,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'eligible',
            headerName: 'Eligible',
            width: 90,
            type: 'boolean',
            editable: false,
            align: 'center',
            headerAlign: 'center'
        }
    ];

    const [pagesize, setPagesize] = useState(10);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (valeurDeRecherche.length > 0) {
            setValeurRequest(valeurDeRecherche);
        }
        setSkipRecherche(false);
    };
    const handleChangeRecherche = (event) => {
        setValeurDeRecherche(event.target.value);
    };
    const handleAssocierCompteClient = async () => {
        if (listCompteClient.length === 0) {
            setErrorAlertMessage('Veuillez sélectionner au moins un utilisateur à associer');
            setErrorAlert(true);
        } else if (listCompteClient.length > 1) {
            setErrorAlertMessage('Veuillez sélectionner un seul utilisateur à associer');
            setErrorAlert(true);
        } else {
            // appel du service d'association de l'utilisateur
            await ajoutExtraPilote({
                extraPiloteID: props.extraPiloteID,
                inscriptionID: props.inscriptionID,
                utilisateurID: listCompteClient[0]
            }).unwrap()
                .then(() => {
                    props.closeModal(false);
                })
                .catch(() => {
                    setErrorAlertMessage("Erreur Technique Impossible d'associer l'utilisateur !");
                    setErrorAlert(true);
                })
        }
    };

    const filterCompteClient = (list) => {
        return list.length === 0 ? [] : list.filter(compte => compte.id !== props.compteClient);
    };

    return (
        <Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Rechercher utilisateur</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={7} sm={7}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    <Grid item xs={6} sm={6}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2.5} sm={2.5}>
                                                <Typography sx={{fontSize: 16}} color="text.secondary">Nom :</Typography>
                                            </Grid>
                                            <Grid item xs={3} sm={3}>
                                                <Typography sx={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold'
                                                }}>{props.nomCompteClient}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={3.75} sm={3.75}>
                                                <Typography sx={{fontSize: 16}} color="text.secondary">Prenom :</Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4}>
                                                <Typography sx={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold'
                                                }}>{props.prenomCompteClient}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={1.5} sm={1.5}>
                                                <Typography sx={{fontSize: 16}} color="text.secondary">Email :</Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4}>
                                                <Typography sx={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold'
                                                }}>{props.mailCompteClient}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={7} sm={7}>
                        <Box onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                            <Paper
                                component="form"
                                sx={{p: "5px 4px", display: "flex", alignItems: "center"}}
                            >
                                <InputBase
                                    sx={{ml: 1, flex: 1}}
                                    placeholder="Nom de famille"
                                    inputProps={{"aria-label": "Nom de famille"}}
                                    onChange={handleChangeRecherche}
                                    value={valeurDeRecherche}
                                    fullWidth
                                />
                                <IconButton type="button" sx={{p: "10px"}} aria-label="search" onClick={handleSubmit}>
                                    <SearchIcon/>
                                </IconButton>
                            </Paper>
                        </Box>
                    </Grid>
                    {errorAlert && <Grid item xs={8} sm={8}>
                        <Alert severity="error">{errorAlertMessage}</Alert>
                    </Grid>}
                    {SucessListCompteClientResponse &&
                        <Grid item xs={12} sm={12}>
                            <DataGrid
                                rows={filterCompteClient(listCompteClientResponse)}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                rowHeight={40}
                                checkboxSelection
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                                onRowSelectionModelChange={value => setListCompteClient(value)}
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                {SucessListCompteClientResponse &&
                    <Button onClick={handleAssocierCompteClient} disabled={listCompteClient.length !== 1}
                            variant="contained">associer compte</Button>}
                <Button onClick={handleClose} variant="contained" color="error">fermer</Button>
            </DialogActions>
        </Dialog>
    )
}