import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useState} from "react";
import {Fragment} from "react";
import ModalGroupePilote from "./ModalGroupePilote";
import GroupsIcon from '@mui/icons-material/Groups';
import {Stack} from "@mui/material";
import ModalListGroupeInscription from "./ModalListGroupeInscription";

export default (props) => {

    const [showModal, setShowModal] = useState({
        modalGroupe: {
            show: false,
        },
        modalListMembreGroupe: {
            show: false
        }
    });

    const handleOpenCloseModalGroupePilote = (choice) => {
        setShowModal({
            ...showModal,
            modalGroupe: {
                show: choice
            }
        });
    };
    const handleOpenCloseModalListGroupePilote = (choice) => {
        setShowModal({
            ...showModal,
            modalListMembreGroupe: {
                show: choice
            }
        });
    };

    return (
        <Fragment>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Stack direction="row" spacing={2}>
                            {!props.GroupeInformation.temp &&
                                <Button onClick={() => handleOpenCloseModalListGroupePilote(true)} variant="contained"
                                        endIcon={<GroupsIcon/>}>
                                    membre groupe
                                </Button>}
                            <Button variant="outlined" onClick={() => handleOpenCloseModalGroupePilote(true)}>Associer
                                groupe</Button>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={5} sm={5}>
                                    <TextField
                                        name="Nom du groupe"
                                        disabled
                                        fullWidth
                                        id="nom_groupe_pilote"
                                        label="Nom du groupe"
                                        value={props.GroupeInformation.libelle}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {showModal.modalGroupe.show &&
                <ModalGroupePilote
                    closeModal={handleOpenCloseModalGroupePilote}
                    inscriptionID={props.inscriptionID}
                    nomGroupe={props.GroupeInformation.libelle}/>}
            {showModal.modalListMembreGroupe.show &&
                <ModalListGroupeInscription
                    groupe={props.GroupeInformation}
                    closeModal={handleOpenCloseModalListGroupePilote}/>
            }
        </Fragment>
    )
}