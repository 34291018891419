import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {DataGrid, frFR} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {DeleteForever} from "@mui/icons-material";
import formUtils from "../../../utils/formUtils";

export default (props) => {

    const [nomExperience, setNomExperience] = useState('');
    const [score, setScore] = useState(0);

    const [pagesize, setPagesize] = useState(5);

    const [newType, setNewType] = useState(false);
    const [oldType, setOldType] = useState(false);
    const [selectedType, setSelectedType] = useState([]);

    const [errorForm, setErrorForm] = useState({
        libelle: false,
        score: false
    });

    useEffect(() => {
        if (props.editMode) {
            setNomExperience(props.editItem.libelle);
            setScore(props.editItem.score);
        }
    }, []);

    const handleNewExperience = () => {
        setNewType(true);
        setOldType(false);
        setNomExperience('');
        setScore(0);
    };
    const handleOldExperience = () => {
        setNewType(false);
        setOldType(true);
        setSelectedType([]);
    };

    const handleChangeNomExperience = (event) => {
        setNomExperience(event.target.value);
    };
    const handleChangeScoreExperience = (event) => {
        setScore(event.target.value);
    };
    const handleTemplateExperience = () => {
        const objectType = props.data.filter(val => val.id === selectedType[0])[0];
        setNomExperience(objectType.libelle);
        setScore(objectType.score);
        setNewType(true);
        setOldType(false);
    };
    const handleAddExperience = () => {
        if (oldType) {
            props.addEvenement('old', selectedType);
        } else if (newType) {
            if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
                props.addEvenement('new', {
                    libelle: nomExperience,
                    score: score,
                });
            }
        } else if (props.editMode) {
            props.addEvenement('update', {
                id: props.editItem.id,
                libelle: nomExperience,
                score: score,
            });
        }
    };

    const columns = [
        {
            field: 'libelle',
            headerName: 'Nom',
            width: 200,
            editable: false,
        }, {
            field: 'score',
            headerName: 'Score',
            headerAlign: 'center',
            width: 100,
            editable: false,
            align: 'center',
        }, {
            field: 'supprimer',
            headerName: 'Supprimer',
            headerAlign: 'center',
            width: 85,
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <IconButton edge="end" aria-label="edit"
                                onClick={() => props.deleteExperience(cellValues)}
                    >
                        <DeleteForever/>
                    </IconButton>)
            }
        },
    ];

    const handleClose = () => {
        props.closeModal();
    };
    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            libelle: nomExperience.length === 0,
            desciption: score.length === 0,
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    };

    return (
        <Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>{props.editMode ? 'Modifier' : 'Ajouter'} expérience</DialogTitle>
            </Box>
            <DialogContent>
                {!props.editMode &&
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 4}}>
                        <Button onClick={handleNewExperience} variant="contained">Nouvelle Experience</Button>
                        <Button onClick={handleOldExperience} variant="contained">Ajouter Experience existante</Button>
                    </Box>
                }
                {(newType || props.editMode) &&
                    <Grid container spacing={2}>
                        <Grid item lg={6}>
                            <TextField fullWidth
                                       id="filled-nom"
                                       label="Nom" variant="outlined"
                                       onChange={handleChangeNomExperience}
                                       value={nomExperience}
                                       error={errorForm.libelle}
                                       helperText={errorForm.libelle ? 'Veuillez renseigner le champs' : ''}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <TextField fullWidth
                                       id="filled-desciption"
                                       label="Score"
                                       variant="outlined"
                                       type="number"
                                       onChange={handleChangeScoreExperience}
                                       value={score}
                                       error={errorForm.score}
                                       helperText={errorForm.desciption ? 'Veuillez renseigner le champs' : 'Score minimum dois être de 1'}
                            />
                        </Grid>
                    </Grid>
                }
                {oldType &&
                    <Grid container spacing={2}>
                        <Grid item lg={12}>
                            <DataGrid
                                rows={props.data}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                checkboxSelection
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                                onRowSelectionModelChange={value => setSelectedType(value)}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            {(newType || oldType || props.editMode) &&
                <DialogActions>
                    {oldType &&
                        <Button onClick={handleTemplateExperience} disabled={selectedType.length !== 1 && oldType}
                                variant="contained">Template</Button>}
                    <Button variant="contained" onClick={handleAddExperience}
                            disabled={selectedType.length === 0 && oldType}>{props.editMode ? 'Enregistrer' : 'Ajouter'}</Button>
                    <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
                </DialogActions>
            }
        </Dialog>
    )
};