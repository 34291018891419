import {apiSlice} from "../../app/api/apiSlice";

const urlFacture = "facture";

export const factureApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFactureDetailler: builder.query({
            query: (data) => ({
                url: `${urlFacture}/detail/${data.factureID}/${data.userID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'getFactureDetailler', ...result.id}, {type: 'getFactureDetailler', id: 'UNIQUE'}] :
                    [{type: 'getFactureDetailler', id: 'UNIQUE'}],
        }),
        getFactureInfo: builder.query({
            query: (factureID) => ({
                url: `${urlFacture}/info/${factureID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'getFactureInfo', ...result.id}, {type: 'getFactureInfo', id: 'UNIQUE'}] :
                    [{type: 'getFactureInfo', id: 'UNIQUE'}],
        }),
        validerFacture: builder.mutation({
            query: (data) => ({
                url: `${urlFacture}/valider-facture`,
                method: 'POST',
                body: {
                    idfacture: data.factureID,
                    montant: data.montant,
                    paiementtype: data.paiementType,
                    commentaire: data.commentaire
                }
            }),
            invalidatesTags: [{type: 'ListAllInscription', id: 'LIST'}, {
                type: 'listAllEncaissementInscription',
                id: 'LIST'
            }],
        }),
        getFullInfoFacture: builder.query({
            query: (factureID) => ({
                url: `${urlFacture}/full-info/${factureID}`,
                method: 'GET'
            }),
            providesTags: (result) =>
                result ? [{type: 'getFullInfoFacture', ...result.id}, {type: 'getFullInfoFacture', id: 'UNIQUE'}] :
                    [{type: 'getFullInfoFacture', id: 'UNIQUE'}],
        }),
        rembourserFacture: builder.mutation({
            query: (data) => ({
                url: `${urlFacture}/remboursement`,
                method: 'POST',
                body: {
                    idfacture: data.factureID,
                    remboursementtype: data.remboursementType,
                    pourcentageremboursement: data.pourcentageRemboursement,
                    montantremboursement: data.montantRemboursement,
                    commentaireremboursement: data.commentaireRemboursement,
                }
            }),
            invalidatesTags: [{type: 'getFullInfoFacture', id: 'UNIQUE'}, {type: 'getFactureInfo', id: 'UNIQUE'}, {type: 'getFactureDetailler', id: 'UNIQUE'}],
        }),
        getFraiCarteBancaire: builder.query({
            query: (factureID) => ({
                url: `${urlFacture}/frai-carte/${factureID}`,
                method: 'GET'
            }),
            providesTags: (result) =>
                result ? [{type: 'getFullInfoFacture', ...result.id}, {type: 'getFullInfoFacture', id: 'UNIQUE'}] :
                    [{type: 'getFullInfoFacture', id: 'UNIQUE'}],
        }),
    })
})

export const {
    useGetFactureDetaillerQuery,
    useGetFactureInfoQuery,
    useValiderFactureMutation,
    useGetFullInfoFactureQuery,
    useRembourserFactureMutation,
    useGetFraiCarteBancaireQuery,
} = factureApiSlice