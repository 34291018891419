import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";
import Paper from "@mui/material/Paper";
import CardStatutDossier from "../gestionInscription/CardStatutDossier";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import ListMotoControl from "./ListMotoControl";
import {Fragment} from "react";
import SecondPilote from "./SecondPilote";
import Checkbox from "@mui/material/Checkbox";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default (props) => {
    const {dossierRouleur} = props;

    const navigate = useNavigate();

    useEffect(() => {
        props.initierChoix(dossierRouleur.id, 'ROULEUR');
    }, []);

    const lienVersDossierRouleur = (idDossier) => {
        navigate(`/inscription/${idDossier}`);
    };
    const extractPilotes = (type, extraPilote) => {
        if (type === 'SECOND') {
            return extraPilote.find(pilote => pilote.type === 'SECOND')
        } else {
            return extraPilote.find(pilote => pilote.type !== 'SECOND')
        }
    };
    const constructSerieString = (serie) => {
        return `${serie.libelle} (${serie.premierdossard})`;
    };


    return (
        <Grid justifyContent="center" container spacing={2}>
            <Grid item xs={12} lg={12} md={12}>
                <Divider sx={{fontWeight: 'bold'}}>Dossier Rouleur</Divider>
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
                <Paper elevation={3} sx={{backgroundColor: '#EFEFEF', padding: 2}}>
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Statut dossier</Divider>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                                    <CardStatutDossier
                                        statut={dossierRouleur.statut}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Numéro de permis</Divider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography align='center'
                                                fontWeight='bold'
                                                variant='h6'>
                                        {dossierRouleur.numeropermis !== null ? dossierRouleur.numeropermis :
                                            <CloseIcon/>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Numéro de dossard</Divider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography align='center'
                                                fontWeight='bold'
                                                variant='h6'>
                                        {dossierRouleur.dossard !== null ? dossierRouleur.dossard :
                                            <CloseIcon/>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Numéro de licence</Divider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography align='center'
                                                fontWeight='bold'
                                                variant='h6'>
                                        {dossierRouleur.numerolicence != null ? dossierRouleur.numerolicence :
                                            <CloseIcon/>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Assurance</Divider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography align='center'
                                                fontWeight='bold'
                                                variant='h6'>
                                        {dossierRouleur.assurance !== null ? dossierRouleur.assurance.libellecourt :
                                            <CloseIcon/>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Série</Divider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography align='center'
                                                fontWeight='bold'
                                                variant='h6'>
                                        {dossierRouleur.serie !== null ? constructSerieString(dossierRouleur.serie) :
                                            <CloseIcon/>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Commentaire</Divider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography align='center'
                                                fontWeight='bold'
                                                variant='h6'>
                                        {dossierRouleur.commentutil !== null ? dossierRouleur.commentutil :
                                            <CloseIcon/>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container justifyContent="center" spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Numero Dossier</Divider>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                                    <Link sx={{fontWeight: 'bold'}}
                                          onClick={() => lienVersDossierRouleur(dossierRouleur.id)}>{dossierRouleur.barcode}</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider>Motos</Divider>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <ListMotoControl
                                listMoto={dossierRouleur.motos}
                            />
                        </Grid>
                        {extractPilotes('SECOND', dossierRouleur.inscription_extra_pilotes) !== undefined &&
                            <Fragment>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Second Guidon</Divider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SecondPilote
                                        infoPilote={extractPilotes('SECOND', dossierRouleur.inscription_extra_pilotes)}/>
                                </Grid>
                            </Fragment>}
                        {extractPilotes('SIDE', dossierRouleur.inscription_extra_pilotes) !== undefined &&
                            <Fragment>
                                <Grid item xs={12} sm={12}>
                                    <Divider>Passager Side</Divider>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SecondPilote
                                        infoPilote={extractPilotes('SIDE', dossierRouleur.inscription_extra_pilotes)}/>
                                </Grid>
                            </Fragment>}
                        <Grid item xs={12} sm={12}>
                            <Divider>Confirmer Dossier</Divider>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                            <Checkbox defaultChecked
                                      onChange={(event) => props.choixValiderDossier(dossierRouleur.id, event.target.checked)}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}