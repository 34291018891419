import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup,
    InputLabel,
    MenuItem,
    Select, Switch
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useState} from "react";
import formUtils from "../../utils/formUtils";

export default (props) => {

    const [infoMoto, setInfoMoto] = useState({
        id: props.dataMoto.id,
        typeMoto: props.dataMoto.id_type,
        marque: props.dataMoto.marque,
        modele: props.dataMoto.modele,
        cylindree: props.dataMoto.cylindree,
        annee: props.dataMoto.annee,
        serialNumber: props.dataMoto.serialnumber,
        assurance: props.dataMoto.policeassurance,
        partager: props.dataMoto.partager,
        preparer: props.dataMoto.preparer
    });
    const [errorMoto, setErrorMoto] = useState({
        typeMoto: {
            error: false,
            message: ''
        },
        marque: {
            error: false,
            message: ''
        },
        modele: {
            error: false,
            message: ''
        },
        cylindree: {
            error: false,
            message: ''
        },
        annee: {
            error: false,
            message: ''
        },
        serialNumber: {
            error: false,
            message: ''
        },
        assurance: {
            error: false,
            message: ''
        },
        partager: {
            error: false,
            message: ''
        },
        preparer: {
            error: false,
            message: ''
        }
    });

    const handleClose = () => {
        props.closeModal('modal', {show: false});
    };

    const handleSaveMoto = () => {
        if (verificationFormulaire()) {
            props.updateMoto('data', {
                motoID: infoMoto.id,
                typeMoto: infoMoto.typeMoto,
                marque: infoMoto.marque,
                modele: infoMoto.modele,
                cylindree: infoMoto.cylindree,
                annee: infoMoto.annee,
                serialNumber: infoMoto.serialNumber,
                assurance: infoMoto.assurance === null || infoMoto.assurance.length === 0 ? null : infoMoto.assurance,
                partager: infoMoto.partager,
                preparer: infoMoto.preparer
            });
            props.closeModal('modal', {show: false});
        }
    }

    const verificationFormulaire = () => {
        let returnFormValidity;
        // vérification de champs s'il ne sont pas vide
        const champTypeMoto = formUtils.verifEmptyEntry(infoMoto.typeMoto);
        const champMarqueEmpty = formUtils.verifEmptyEntry(infoMoto.marque);
        const champModelEmpty = formUtils.verifEmptyEntry(infoMoto.modele);
        const champCylindreEmpty = formUtils.verifEmptyEntry(infoMoto.cylindree);
        const champAnneeEmpty = formUtils.verifEmptyEntry(infoMoto.annee);
        const champSerialNumberEmpty = formUtils.verifEmptyEntry(infoMoto.serialNumber);

        setErrorMoto({
            ...errorMoto,
            marque: {
                error: champMarqueEmpty,
                message: champMarqueEmpty ? 'Champs obligatoire' : ''
            },
            modele: {
                error: champModelEmpty,
                message: champModelEmpty ? 'Champs obligatoire' : ''
            },
            cylindree: {
                error: champCylindreEmpty,
                message: champCylindreEmpty ? 'Champs obligatoire' : ''
            },
            annee: {
                error: champAnneeEmpty,
                message: champAnneeEmpty ? 'Champs obligatoire' : ''
            },
            serialNumber: {
                error: champSerialNumberEmpty,
                message: champSerialNumberEmpty ? 'Champs obligatoire' : ''
            },
            typeMoto: {
                error: champTypeMoto,
                message: champTypeMoto ? 'Champs obligatoire' : ''
            }
        });

        returnFormValidity = !champMarqueEmpty && !champModelEmpty && !champCylindreEmpty && !champAnneeEmpty && !champSerialNumberEmpty && !champTypeMoto;

        return returnFormValidity;
    };

    return (
        <Dialog open={true} fullWidth maxWidth="sm" onClose={handleClose}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Modifier Moto</DialogTitle>
            </Box>
            <DialogContent>
                <Container component="main">
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={5} lg={5}>
                                    <FormControl fullWidth>
                                        <InputLabel required>Type moto</InputLabel>
                                        <Select
                                            id="demo-simple-select-type-moto"
                                            value={infoMoto.typeMoto}
                                            label="Type moto"
                                            onChange={event => {
                                                setInfoMoto({
                                                    ...infoMoto,
                                                    typeMoto: event.target.value
                                                });
                                            }}
                                            error={errorMoto.typeMoto.error}
                                            required
                                        >
                                            {props.listTypeMoto.map(type => {
                                                return <MenuItem key={type.id} value={type.id}>{type.libelle}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                name="Marque"
                                fullWidth
                                id="marque_moto"
                                label="Marque"
                                value={infoMoto.marque}
                                onChange={event => {
                                    setInfoMoto({
                                        ...infoMoto,
                                        marque: event.target.value
                                    });
                                }}
                                error={errorMoto.marque.error}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                name="Modèle"
                                fullWidth
                                id="modèle_moto"
                                label="Modèle"
                                value={infoMoto.modele}
                                onChange={event => {
                                    setInfoMoto({
                                        ...infoMoto,
                                        modele: event.target.value
                                    });
                                }}
                                error={errorMoto.modele.error}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                required
                                name="Cylindrée"
                                fullWidth
                                id="prenom"
                                label="Cylindrée"
                                type="number"
                                value={infoMoto.cylindree}
                                error={errorMoto.cylindree.error}
                                onChange={event => {
                                    setInfoMoto({
                                        ...infoMoto,
                                        cylindree: event.target.value
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <TextField
                                required
                                name="Année"
                                fullWidth
                                id="prenom"
                                label="Année"
                                type="number"
                                value={infoMoto.annee}
                                error={errorMoto.annee.error}
                                onChange={event => {
                                    setInfoMoto({
                                        ...infoMoto,
                                        annee: event.target.value
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                required
                                name="N° de série de ta moto"
                                fullWidth
                                id="prenom"
                                label="N° de série de ta moto"
                                value={infoMoto.serialNumber}
                                error={errorMoto.serialNumber.error}
                                onChange={event => {
                                    if (formUtils.verifAlpha(event.target.value)) {
                                        setInfoMoto({
                                            ...infoMoto,
                                            serialNumber: event.target.value
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField
                                name="N° police assurance"
                                fullWidth
                                id="prenom"
                                label="N° police assurance"
                                value={infoMoto.assurance !== null ? infoMoto.assurance : ''}
                                error={errorMoto.assurance.error}
                                onChange={event => {
                                    setInfoMoto({
                                        ...infoMoto,
                                        assurance: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={infoMoto.partager} onChange={event => {
                                        setInfoMoto({
                                            ...infoMoto,
                                            partager: event.target.checked
                                        })
                                    }}/>}
                                    label="Moto est partager"/>
                                <FormControlLabel
                                    control={<Switch checked={infoMoto.preparer} onChange={event => {
                                        setInfoMoto({
                                            ...infoMoto,
                                            preparer: event.target.checked
                                        })
                                    }}/>}
                                    label="Moto est préparée spécialement pour rouler sur circuit"/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSaveMoto}>Enregistrer</Button>
                <Button onClick={handleClose} variant="contained" color="error">Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}