import {apiSlice} from "../../app/api/apiSlice";

const urlInscriptionType = "inscription-type";

export const inscriptionTypeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllTypeInscription: builder.query({
            query: () => ({
                url: `${urlInscriptionType}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllTypeInscription', ...result.id}, {type: 'ListAllTypeInscription', id: 'LIST'}] :
                    [{type: 'ListAllTypeInscription', id: 'LIST'}],
        }),
        listTypeInscriptionEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlInscriptionType}/evenement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListTypeInscriptionEvenement', ...result.id}, {
                        type: 'ListTypeInscriptionEvenement',
                        id: 'LIST'
                    }] :
                    [{type: 'ListTypeInscriptionEvenement', id: 'LIST'}],
        }),
        removeTypeInscriptionEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, inscriptionTypeID} = data;
                return {
                    url: `${urlInscriptionType}/evenement/${evenementID}`,
                    method: 'DELETE',
                    body: {
                        inscriptiontypeid: inscriptionTypeID
                    }
                }
            },
            invalidatesTags: [{type: 'ListTypeInscriptionEvenement', id: 'LIST'}],
        }),
        addTypeInscriptionsEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, listInscriptionTypes} = data;
                return {
                    url: `${urlInscriptionType}/evenement/add`,
                    method: 'POST',
                    body: {
                        evenementid: evenementID,
                        listinscriptiontype: listInscriptionTypes
                    }
                }
            },
            invalidatesTags: [{type: 'ListTypeInscriptionEvenement', id: 'LIST'}],
        }),
        createTypeInscription: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlInscriptionType}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        description: data.description,
                        side: data.side,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllTypeInscription', id: 'LIST'}],
        }),
        updateTypeInscription: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlInscriptionType}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libelle: data.libelle,
                        description: data.description,
                        side: data.side,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllTypeInscription', id: 'LIST'},{type: 'ListTypeInscriptionEvenement', id: 'LIST'}],
        }),
        deleteTypeInscription: builder.mutation({
            query: (motoTypeID) => {
                return {
                    url: `${urlInscriptionType}/${motoTypeID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListAllTypeInscription', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllTypeInscriptionQuery,
    useListTypeInscriptionEvenementQuery,
    useRemoveTypeInscriptionEvenementMutation,
    useAddTypeInscriptionsEvenementMutation,
    useCreateTypeInscriptionMutation,
    useUpdateTypeInscriptionMutation,
    useDeleteTypeInscriptionMutation,
} = inscriptionTypeApiSlice