import {
    List,
    ListItem, ListItemText,
    Paper,
    Popper,
    Table,
    TableBody, TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

export default (props) => {
    return (
        <Popper open={props.open} anchorEl={props.element} placement='top'>
            <Paper sx={{backgroundColor: 'rgb(160,160,160)'}}>
                {props.type === 'moto' &&
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Cylindree mini</TableCell>
                                <TableCell align="center">Cylindree max</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.map((row) => (
                                <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row">{row.libelle}</TableCell>
                                    <TableCell align="center">{row.description}</TableCell>
                                    <TableCell align="center">{row.cylindreeMin}</TableCell>
                                    <TableCell align="center">{row.cylindreeMax}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
                {props.type === 'niveau' &&
                    <List>
                        {props.data.map((row) => (
                            <ListItem key={row.id}>
                                <ListItemText primary={row.libelle}/>
                            </ListItem>
                        ))}
                    </List>
                }
                {props.type === 'inscription' &&
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Side Car</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{props.data.libelle}</TableCell>
                                <TableCell align="center">{props.data.description}</TableCell>
                                <TableCell align="center">{props.data.side ? <CheckIcon/> : <CloseIcon/>}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
            </Paper>
        </Popper>
    )
}