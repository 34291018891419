import {CardContent, Dialog} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import formUtils from "../../../utils/formUtils";

export default (props) => {

    const [nomPays, setNomPays] = useState('');
    const [abreviationPays, setAbreviationPays] = useState('');
    const [errorForm, setErrorForm] = useState({
        libelle: false,
        abreviation: false,
    });

    useEffect(() => {
        if (props.editMode) {
            setNomPays(props.editItem.libelle);
            setAbreviationPays(props.editItem.abreviation);
        }
    }, []);

    const handleClose = () => {
        props.closeWindows(false);
    };

    const handleAddPays = () => {
        if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
            if (!props.editMode) {
                props.addPays('new', {
                    libelle: nomPays,
                    abreviation: abreviationPays
                });
            } else {
                props.addPays('update', {
                    id: props.editItem.id,
                    libelle: nomPays,
                    abreviation: abreviationPays
                });
            }
        }
    }
    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            libelle: nomPays.length === 0,
            abreviation: abreviationPays.length === 0,
        }
        setErrorForm({
            ...errorForm,
            ...ObjVerif
        });
        return ObjVerif;
    };

    return (
        <Dialog open={true} fullWidth maxWidth="xs" onClose={handleClose}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={12}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography align='center'
                                            variant="h6">{props.editMode ? 'Modifier' : 'Ajouter'} Pays</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={12} lg={9}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="abreviation-text"
                                            label="Libelle"
                                            value={nomPays}
                                            onChange={event => setNomPays(event.target.value)}
                                            error={errorForm.libelle}
                                            helperText={errorForm.libelle ? 'Veuillez renseigner le champs' : ''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={12} lg={9}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="abreviation-text"
                                            label="Abreviation"
                                            value={abreviationPays}
                                            onChange={event => setAbreviationPays(event.target.value.toUpperCase())}
                                            error={errorForm.abreviation}
                                            helperText={errorForm.libelle ? 'Veuillez renseigner le champs' : ''}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button variant="contained" sx={{ml: 1}} size="small" color="error"
                                            onClick={handleClose}>Annuler</Button>
                                    <Button variant="contained" sx={{ml: 1}} onClick={handleAddPays}
                                            size="small">{props.editMode ? 'Enregistrer' : 'Ajouter'}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Dialog>
    )
};