import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import {Fragment} from "react";
import NavBar from "./components/appBar/NavBar";
import SignIn from "./pages/Authentification/SignIn";
import RequireAuth from "./feature/auth/RequireAuth";
import ListEvenement from "./pages/private/ListEvenement";
import Evenement from "./pages/private/Evenement";
import Error500 from "./pages/public/Error500";
import Configuration from "./pages/private/Configuration";
import GestionUtilisateur from "./pages/private/GestionUtilisateur";
import CompteUtilisateur from "./pages/private/CompteUtilisateur";
import GestionInscriptionFetch from "./pages/private/fetch/GestionInscriptionFetch";
import GestionFactureFetch from "./pages/private/fetch/GestionFactureFetch";
import ControleAdministratifFetch from "./pages/private/fetch/ControleAdministratifFetch";
import ListInscriptionFetch from "./pages/private/fetch/ListInscriptionFetch";
import ListEncaissementInscriptionFetch from "./pages/private/fetch/ListEncaissementInscriptionFetch";

function App() {
    return (
        <Fragment>
            <NavBar/>
            <Routes>
                <Route path="/" element={<Navigate replace to="/login"/>}/>
                <Route path="/login" element={<SignIn/>}/>
                <Route path="/500" element={<Error500/>}/>
                <Route element={<RequireAuth/>}>
                    <Route path="/evenements" element={<ListEvenement/>}/>
                    <Route path="/evenement/:evenementId" element={<Evenement/>}/>
                    <Route path="/configuration" element={<Configuration/>}/>
                    <Route path="/utilisateurs" element={<GestionUtilisateur/>}/>
                    <Route path="/utilisateur" element={<CompteUtilisateur/>}/>
                    <Route path="/utilisateur/:userId" element={<CompteUtilisateur/>}/>
                    <Route path="/inscriptions" element={<ListInscriptionFetch/>}/>
                    <Route path="/inscription/:inscriptionId" element={<GestionInscriptionFetch/>}/>
                    <Route path="/encaissement-inscrit" element={<ListEncaissementInscriptionFetch/>}/>
                    <Route path="/facture/:factureId/:clientId" element={<GestionFactureFetch/>}/>
                    <Route path="/control-administratif" element={<ControleAdministratifFetch/>}/>
                </Route>
            </Routes>
        </Fragment>
    );
}

export default App;
