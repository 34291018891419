import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup, FormHelperText, InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select, TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TypeDePaiement from "../../Constants/TypeDePaiement";
import {useState} from "react";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import {useRembourserFactureMutation} from "../../feature/facture/factureApiSlice";
import Alert from "@mui/material/Alert";

export default (props) => {

    const [dataForm, setDataForm] = useState({
        remboursementType: props.paiementType,
        pourcentageRemboursement: 50,
        montantRemboursement: (props.montantFacture * 50) / 100,
        commentaireRemboursement: '',
        montantFacture: props.montantFacture,
        remboursementFraiCarte: true
    });
    const [errorMontant, setErrorMontant] = useState(false);

    const [rembourserFacture, {isLoading: isLoadingRembourserFacture},] = useRembourserFactureMutation();

    const filterPaiementType = (element) => {
        if (element === 'CARTE BANCAIRE') {
            return props.paiementCB;
        } else {
            return true;
        }
    };

    const handleChangerRemboursementType = (event) => {
        setDataForm({...dataForm, remboursementType: event.target.value});
    };
    const handleChangePourcentageRemboursement = (event) => {
        setDataForm({...dataForm, pourcentageRemboursement: event.target.value});
    };
    const handleChangeMontantRemboursement = (event) => {
        setDataForm({...dataForm, montantRemboursement: event.target.value});
    };
    const handleChangeCommentaireRemboursement = (event) => {
        setDataForm({...dataForm, commentaireRemboursement: event.target.value});
    };
    const handleBlurPourcentageRemboursement = (event) => {
        let montant = (dataForm.montantFacture * event.target.value / 100);
        if (!dataForm.remboursementFraiCarte) {
            montant = montant - props.montantFraiCarte;
        }
        setDataForm({...dataForm, montantRemboursement: montant});
    };
    const handleChangeFraiCarte = (event) => {
        setDataForm({
            ...dataForm,
            remboursementFraiCarte: event.target.checked,
            montantRemboursement: !event.target.checked ? dataForm.montantRemboursement - props.montantFraiCarte : dataForm.montantRemboursement
        });
    };
    const handleCLose = () => {
        props.closeModal(false);
    };
    const handleClickValiderRemboursement = async () => {
        setErrorMontant(false);
        if (dataForm.montantRemboursement < 1) {
            setErrorMontant(true);
        } else {
            console.log(props);
            await rembourserFacture({
                factureID: props.factureID,
                remboursementType: dataForm.remboursementType,
                montantRemboursement: dataForm.montantRemboursement,
                commentaireRemboursement: dataForm.commentaireRemboursement.length === 0 ? null : dataForm.commentaireRemboursement,
            }).unwrap()
                .then(() => {
                    props.sucessSave('Remboursement effectuer avec succès !');
                    props.closeModal(false);
                })
                .catch(error => {
                    if (error.status === 406) {
                        props.errorSave(error.data);
                    } else {
                        props.errorSave('Erreur Technique veuillez essayer ultérieurement');
                    }
                })
        }
    };

    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <DialogTitle>Remboursement</DialogTitle>
            </Box>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel id="select-paiement-label">Type de remboursement</InputLabel>
                            <Select
                                required
                                fullWidth
                                labelId="select-paiement"
                                id="select-paiement"
                                value={dataForm.remboursementType}
                                onChange={handleChangerRemboursementType}
                                label="Type de remboursement"
                            >
                                {TypeDePaiement.filter(filterPaiementType).map(type => {
                                    return <MenuItem key={type} value={type}>{type}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Pourcentage remboursement</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                value={dataForm.pourcentageRemboursement}
                                onChange={handleChangePourcentageRemboursement}
                                onBlur={handleBlurPourcentageRemboursement}
                                inputProps={{
                                    'type': 'number',
                                    'min': 0,
                                    'max': 100
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                }
                                label="Pourcentage remboursement"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel error={errorMontant} htmlFor="outlined-adornment-password">Montant
                                remboursement</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                value={dataForm.montantRemboursement}
                                onChange={handleChangeMontantRemboursement}
                                error={errorMontant}
                                inputProps={{
                                    'type': 'number',
                                    'max': props.montantFacture,
                                    'min': 0
                                }}
                                endAdornment={
                                    <InputAdornment position="end">€
                                    </InputAdornment>
                                }
                                label="Montant remboursement"
                            />
                            {errorMontant && <FormHelperText error={errorMontant}>Le montant dois être supérieur a 1
                                euros</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Commentaire"
                            value={dataForm.commentaireRemboursement}
                            onChange={handleChangeCommentaireRemboursement}
                            fullWidth
                        />
                    </Grid>
                    {dataForm.remboursementType === 'CARTE BANCAIRE' &&
                        <Grid item xs={12} sm={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={dataForm.remboursementFraiCarte}
                                                                     onChange={handleChangeFraiCarte}/>}
                                                  label={`Remboursement des frais de carte bancaire (${props.montantFraiCarte} €)`}/>
                            </FormGroup>
                        </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={isLoadingRembourserFacture} onClick={handleClickValiderRemboursement}
                               variant="contained">valider</LoadingButton>
                <Button variant="contained" color="error" onClick={() => handleCLose()}>annuler
                    remboursement</Button>
            </DialogActions>
        </Dialog>
    )
}