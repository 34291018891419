import {
    Card,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import IconButton from "@mui/material/IconButton";
import {Fragment, useState} from "react";
import ListIcon from '@mui/icons-material/List';
import PopperInfo from "./PopperInfo";
import FeedIcon from '@mui/icons-material/Feed';

export default (props) => {

    const [selectElement, setSelectElement] = useState(null);
    const [open, setOpen] = useState(false);
    const [typePopper, setTypePopper] = useState('');
    const [dataPopper, setDataPopper] = useState('');

    const handleHidePopperTypeMoto = (event) => {
        setSelectElement(event.currentTarget);
        setOpen(false);
    };
    const handleShowPopperTypeMoto = (data) => (event) => {
        setSelectElement(event.currentTarget);
        setOpen(true);
        setTypePopper('moto');
        setDataPopper(data);
    };
    const handleHidePopperNiveau = (event) => {
        setSelectElement(event.currentTarget);
        setOpen(false);
    };
    const handleShowPopperNiveau = (data) => (event) => {
        setSelectElement(event.currentTarget);
        setOpen(true);
        setTypePopper('niveau');
        setDataPopper(data);
    };
    const handleHidePopperTypeInscription = (event) => {
        setSelectElement(event.currentTarget);
        setOpen(false);
    };
    const handleShowPopperTypeInscription = (data) => (event) => {
        setSelectElement(event.currentTarget);
        setOpen(true);
        setTypePopper('inscription');
        setDataPopper(data);
    };

    const handleDeleteSerie = (event, params) => {
        props.deleteSerie(params);
    };
    const handleEditSerie = (event, params) => {
        props.editSerie(params);
    };

    return (
        <Fragment>
            {open && <PopperInfo
                open={open}
                element={selectElement}
                type={typePopper}
                data={dataPopper}/>}
            {props.listSerie.length > 0 &&
                <Card variant="outlined" sx={{marginTop: 2}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Accès</TableCell>
                                <TableCell align="center">Prix</TableCell>
                                <TableCell align="center">Premier<br/>dossard</TableCell>
                                <TableCell align="center">Type<br/>inscription</TableCell>
                                <TableCell align="center">Nombre<br/>Max inscrit</TableCell>
                                <TableCell align="center">Score<br/>Minimum</TableCell>
                                <TableCell align="center">Score<br/>Maximum</TableCell>
                                <TableCell align="center">Niveau</TableCell>
                                <TableCell align="center">Type Moto</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.listSerie.map(value => {
                                return <TableRow key={value.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row">{value.libelle}</TableCell>
                                    <TableCell component="th" scope="row">{value.desciption}</TableCell>
                                    <TableCell align="center">{value.nbacces}</TableCell>
                                    <TableCell align="center">{value.prix}</TableCell>
                                    <TableCell align="center">{value.premierdossard}</TableCell>
                                    <TableCell align="center">
                                        <IconButton edge="end" aria-label="edit" disabled={value.seriebenevole}>
                                            <FeedIcon
                                                onMouseEnter={handleShowPopperTypeInscription(value.inscriptiontype)}
                                                onMouseLeave={handleHidePopperTypeInscription}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">{value.nbmax}</TableCell>
                                    <TableCell align="center">{value.scoremin}</TableCell>
                                    <TableCell align="center">{value.scoremax}</TableCell>
                                    <TableCell align="center">
                                        <IconButton edge="end" aria-label="edit">
                                            <ListIcon onMouseEnter={handleShowPopperNiveau(value.niveaus)}
                                                      onMouseLeave={handleHidePopperNiveau}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton edge="end" aria-label="edit">
                                            <ListIcon onMouseEnter={handleShowPopperTypeMoto(value.mototypes)}
                                                      onMouseLeave={handleHidePopperTypeMoto}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack direction="row" spacing={2} sx={{mt: -2, mb: -2}}>
                                            <IconButton edge="end" aria-label="edit"
                                                        onClick={event => handleEditSerie(event, value.id)}>
                                                <ModeEditIcon/>
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete"
                                                        onClick={event => handleDeleteSerie(event, value.id)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </Card>
            }
        </Fragment>
    )
};