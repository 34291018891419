import {apiSlice} from "../../app/api/apiSlice";

const urlUtilisateur = "user";

export const utilisateurApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        selectEvenement: builder.query({
            query: () => ({
                url: `${urlUtilisateur}/selected-evenement`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'SelectedEvenementUser', ...result.id}, {
                        type: 'SelectedEvenementUser',
                        id: 'UNIQUE'
                    }] :
                    [{type: 'SelectedEvenementUser', id: 'UNIQUE'}],
        }),
        saveEvenementSelect: builder.mutation({
            query: (evenementID) => ({
                url: `${urlUtilisateur}/selected-evenement`,
                method: 'POST',
                body: {
                    evenementid: evenementID
                }
            }),
            invalidatesTags: [{type: 'SelectedEvenementUser', id: 'UNIQUE'}]
        }),
        listerUtilisateurAdmin: builder.query({
            query: () => ({
                url: `${urlUtilisateur}/list-user-admin`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListUserAdmin', ...result.id}, {type: 'ListUserAdmin', id: 'LIST'}] :
                    [{type: 'ListUserAdmin', id: 'LIST'}],
        }),
        newPasswordCompteAdmin: builder.mutation({
            query: (data) => ({
                url: `${urlUtilisateur}/reset-password-admin`,
                method: 'POST',
                body: {
                    userid: data.userid,
                    mail: data.mail,
                    sendmail: data.sendmail
                }
            }),
            invalidatesTags: [{type: 'ListUserAdmin', id: 'LIST'}]
        }),
        saveCompteAdmin: builder.mutation({
            query: (data) => ({
                url: `${urlUtilisateur}/${data.userID}`,
                method: 'PUT',
                body: {
                    userid: data.userid,
                    listdroit: data.droits,
                    role: data.role,
                    mail: data.mail
                }
            }),
            invalidatesTags: [{type: 'ListUserAdmin', id: 'LIST'}]
        }),
        createCompteAdmin: builder.mutation({
            query: (data) => ({
                url: `${urlUtilisateur}/`,
                method: 'POST',
                body: {
                    listdroit: data.droits,
                    role: data.role,
                    mail: data.mail,
                    adressemail: data.emailIdentifiant
                }
            }),
            invalidatesTags: [{type: 'ListUserAdmin', id: 'LIST'}]
        }),
        deleteCompteAdmin: builder.mutation({
            query: (userID) => ({
                url: `${urlUtilisateur}/${userID}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{type: 'ListUserAdmin', id: 'LIST'}]
        }),
    })
})

export const {
    useSelectEvenementQuery,
    useSaveEvenementSelectMutation,
    useListerUtilisateurAdminQuery,
    useNewPasswordCompteAdminMutation,
    useSaveCompteAdminMutation,
    useCreateCompteAdminMutation,
    useDeleteCompteAdminMutation,
} = utilisateurApiSlice