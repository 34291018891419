import {Fragment} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RepeatOneIcon from '@mui/icons-material/RepeatOne';

export default (props) => {

    const handleOpenModalSerie = () => {
        props.openModal(true);
    };

    const handleOpenModalChangementDossard = () => {
        props.openModalChangementDossard(true);
    };

    return (
        <Fragment>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant="outlined" onClick={handleOpenModalSerie}>
                            Associer serie
                        </Button>
                    </Box>
                </Grid>
                {props.SerieInformation.id.length > 0 && <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} lg={6} md={6}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={5} lg={5} md={5}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        value={props.SerieInformation.libelle}
                                        id="outlined-nom-pilote"
                                        label="Série"
                                        variant="outlined"/>
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        value={props.SerieInformation.premierdossard}
                                        id="outlined-nom-pilote"
                                        label="Premier Dossard"
                                        variant="outlined"/>
                                </Grid>
                                <Grid item xs={4} lg={4} md={4}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        value={props.SerieInformation.nbmax}
                                        id="outlined-nom-pilote"
                                        label="Nombre pilote Maximum"
                                        variant="outlined"/>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item xs={4} lg={4} md={4}>
                                            <TextField
                                                fullWidth
                                                disabled={true}
                                                value={props.SerieInformation.nombreDossier}
                                                id="outlined-nom-pilote"
                                                label="Nombre pilote serie"
                                                variant="outlined"/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} lg={6} md={6}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={4} lg={4} md={4}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Numero pilote</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            value={props.dossardPilote !== null ? props.dossardPilote : ''}
                                            type="number"
                                            disabled={true}
                                            label="Numero pilote"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={props.dateConfirmation === null || props.dateAnnulation !== null}
                                                        aria-label="toggle password visibility"
                                                        onClick={handleOpenModalChangementDossard}
                                                        edge="end"
                                                    >
                                                        <RepeatOneIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        value={props.SerieInformation.scorePilote}
                                        id="outlined-nom-pilote"
                                        label="Score Pilote"
                                        variant="outlined"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
        </Fragment>
    )
}