import {apiSlice} from "../../app/api/apiSlice";
const urlExperience = "experience" ;

export const evenementApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllExperience: builder.query({
            query: () => ({
                url: `${urlExperience}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllExperience', ...result.id}, {type: 'ListAllExperience', id: 'LIST'}] :
                    [{type: 'ListAllExperience', id: 'LIST'}],
        }),
        listExperienceEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlExperience}/evenement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListExperienceEvenement', ...result.id}, {type: 'ListExperienceEvenement', id: 'LIST'}] :
                    [{type: 'ListExperienceEvenement', id: 'LIST'}],
        }),
        removeExperienceEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, experienceID} = data;
                return {
                    url: `${urlExperience}/evenement/${evenementID}`,
                    method: 'DELETE',
                    body: {
                        experienceid: experienceID
                    }
                }
            },
            invalidatesTags: [{type: 'ListExperienceEvenement', id: 'LIST'}],
        }),
        addExperienceEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, listExperience} = data;
                return {
                    url: `${urlExperience}/evenement/${evenementID}`,
                    method: 'POST',
                    body: {
                        experiences: listExperience
                    }
                }
            },
            invalidatesTags: [{type: 'ListExperienceEvenement', id: 'LIST'}],
        }),
        createExperience: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlExperience}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        score: data.score,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllExperience', id: 'LIST'}],
        }),
        updateExperience: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlExperience}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libelle: data.libelle,
                        score: data.score,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllExperience', id: 'LIST'},{type: 'ListExperienceEvenement', id: 'LIST'}],
        }),
        deleteExperience: builder.mutation({
            query: (experienceID) => {
                return {
                    url: `${urlExperience}/${experienceID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListAllExperience', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllExperienceQuery,
    useListExperienceEvenementQuery,
    useRemoveExperienceEvenementMutation,
    useAddExperienceEvenementMutation,
    useCreateExperienceMutation,
    useUpdateExperienceMutation,
    useDeleteExperienceMutation,
} = evenementApiSlice