import {apiSlice} from "../../app/api/apiSlice";

const urlInscription = "inscription";

export const inscriptionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllInscription: builder.query({
            query: (evenementID) => ({
                url: `${urlInscription}/list-all/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllInscription', ...result.id}, {type: 'ListAllInscription', id: 'LIST'}] :
                    [{type: 'ListAllInscription', id: 'LIST'}],
        }),
        getInscription: builder.query({
            query: (data) => ({
                url: `${urlInscription}/${data.inscriptionID}/${data.evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'GetInscription', ...result.id}, {type: 'GetInscription', id: 'UNIQUE'}] :
                    [{type: 'GetInscription', id: 'UNIQUE'}],
        }),
        ajoutExtraPilote: builder.mutation({
            query: (data) => ({
                url: `${urlInscription}/extra-pilote/${data.extraPiloteID}`,
                method: 'POST',
                body: {
                    inscriptionid: data.inscriptionID,
                    utilisateurid: data.utilisateurID
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}],
        }),
        ajoutGroupeDossier: builder.mutation({
            query: (data) => ({
                url: `${urlInscription}/groupe-pilote/${data.groupePiloteID}`,
                method: 'POST',
                body: {
                    inscriptionid: data.inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}],
        }),
        enregistrerInscription: builder.mutation({
            query: (data) => ({
                url: `${urlInscription}/`,
                method: 'POST',
                body: {...data}
            }),
            invalidatesTags: [{type: 'GetInscription'}, {
                type: 'ListAllInscription',
                id: 'LIST'
            }, {type: 'listAllEncaissementInscription', id: 'LIST'}],
        }),
        validerInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/valider-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        associerSerieInscription: builder.mutation({
            query: (data) => ({
                url: `${urlInscription}/associer-serie`,
                method: 'POST',
                body: {
                    inscriptionid: data.inscriptionID,
                    serieid: data.serieID,
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        confirmerInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/confirmer-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        invaliderInscription: builder.mutation({
            query: (data) => ({
                url: `${urlInscription}/invalider-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: data.id,
                    motif: data.motif
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        changerDossardInscription: builder.mutation({
            query: (data) => ({
                url: `${urlInscription}/changer-numero-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: data.inscriptionID,
                    nouveaunumero: data.nouveauNumero
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        listAttenteInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/liste-attente-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        refusInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/refus-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        annulerInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/annuler-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        listAllEncaissementInscription: builder.query({
            query: (evenementID) => ({
                url: `${urlInscription}/list-all-encaissement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'listAllEncaissementInscription', ...result.id}, {
                        type: 'listAllEncaissementInscription',
                        id: 'LIST'
                    }] :
                    [{type: 'listAllEncaissementInscription', id: 'LIST'}],
        }),
        initierFactureInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/initier-facture`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'listAllEncaissementInscription', id: 'LIST'}],
        }),
        dupliquerInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/dupliquer-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'ListAllInscription', id: 'LIST'}],
        }),
        modifierMotifDossierInvalide: builder.mutation({
            query: (data) => ({
                url: `${urlInscription}/invalider-dossier`,
                method: 'PUT',
                body: {
                    inscriptionid: data.id,
                    motif: data.motif
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        presentInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/present-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        abandonInscription: builder.mutation({
            query: (inscriptionID) => ({
                url: `${urlInscription}/abandon-dossier`,
                method: 'POST',
                body: {
                    inscriptionid: inscriptionID,
                }
            }),
            invalidatesTags: [{type: 'GetInscription'}, {type: 'ListAllInscription', id: 'LIST'}],
        }),
        envoieMailConfirmation: builder.mutation({
            query: (evenementID) => ({
                url: `${urlInscription}/envoie-mail-qrcode`,
                method: 'POST',
                body: {
                    evenementid: evenementID,
                }
            }),
        }),
    })
})

export const {
    useListAllInscriptionQuery,
    useGetInscriptionQuery,
    useAjoutExtraPiloteMutation,
    useAjoutGroupeDossierMutation,
    useEnregistrerInscriptionMutation,
    useValiderInscriptionMutation,
    useAssocierSerieInscriptionMutation,
    useConfirmerInscriptionMutation,
    useInvaliderInscriptionMutation,
    useChangerDossardInscriptionMutation,
    useListAttenteInscriptionMutation,
    useRefusInscriptionMutation,
    useAnnulerInscriptionMutation,
    useListAllEncaissementInscriptionQuery,
    useInitierFactureInscriptionMutation,
    useDupliquerInscriptionMutation,
    useModifierMotifDossierInvalideMutation,
    usePresentInscriptionMutation,
    useAbandonInscriptionMutation,
    useEnvoieMailConfirmationMutation,
} = inscriptionApiSlice