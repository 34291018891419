import {Fragment, useEffect, useState} from "react";
import formUtils from "../../utils/formUtils";
import {useNavigate, useParams} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
    Backdrop, CircularProgress,
    createTheme,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup, Snackbar,
    Switch
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import Alert from '@mui/material/Alert';
import ListDisponibiliter from "../../components/evenement/disponibiliter/ListDisponibiliter";
import AjoutDisponibiliter from "../../components/evenement/disponibiliter/AjoutDisponibiliter";
import {
    useAddDisponibiliterEvenementMutation,
    useListAllDisponibilitersQuery,
    useListDisponibilitersEvenementQuery,
    useMoveDisponibiliterEvenementMutation,
    useRemoveDisponibiliterEvenementMutation
} from "../../feature/disponibiliter/disponibiliterApiSlice";
import AddRepas from "../../components/evenement/repas/AddRepas";
import DetailRepas from "../../components/evenement/repas/DetailRepas";
import InformationEvenement from "../../components/evenement/information/InformationEvenement";
import SerieZorga from "../../components/evenement/serie zorga/SerieZorga";
import ModalAjoutTypeInsciption from "../../components/evenement/type inscription/ModalAjoutTypeInsciption";
import {
    useAddTypeInscriptionsEvenementMutation, useCreateTypeInscriptionMutation, useDeleteTypeInscriptionMutation,
    useListAllTypeInscriptionQuery,
    useListTypeInscriptionEvenementQuery, useRemoveTypeInscriptionEvenementMutation, useUpdateTypeInscriptionMutation
} from "../../feature/inscription-type/inscriptionTypeApiSlice";
import ListTypeInscription from "../../components/evenement/type inscription/ListTypeInscription";
import ModalAjoutTypeMoto from "../../components/evenement/typeMoto/ModalAjoutTypeMoto";
import {
    useAddTypeMotoEvenementMutation, useCreateTypeMotoMutation, useDeleteTypeMotoMutation,
    useListAllTypeMotoQuery,
    useListTypeMotoEvenementQuery,
    useRemoveTypeMotoEvenementMutation, useUpdateTypeMotoMutation
} from "../../feature/moto-type/motoTypeApiSlice";
import ListTypeMoto from "../../components/evenement/typeMoto/ListTypeMoto";
import ModalExperience from "../../components/evenement/experience/ModalExperience";
import {
    useAddExperienceEvenementMutation, useCreateExperienceMutation, useDeleteExperienceMutation,
    useListAllExperienceQuery,
    useListExperienceEvenementQuery,
    useRemoveExperienceEvenementMutation, useUpdateExperienceMutation
} from "../../feature/experience/experienceApiSlice";
import ListExperience from "../../components/evenement/experience/ListExperience";
import ScoreExperience from "../../components/evenement/experience/ScoreExperience";
import ModalNiveau from "../../components/evenement/niveau/ModalNiveau";
import {
    useAddNiveauEvenementMutation, useCreateNiveauMutation, useDeleteNiveauMutation,
    useListAllNiveauQuery,
    useListNiveauEvenementQuery,
    useRemoveNiveauEvenementMutation, useUpdateNiveauMutation
} from "../../feature/niveau/niveauApiSlice";
import ListNiveau from "../../components/evenement/niveau/ListNiveau";
import ModalSerie from "../../components/evenement/serie/ModalSerie";
import ListSerie from "../../components/evenement/serie/ListSerie";
import {
    useAddSerieEvenementMutation, useCreateSerieMutation, useDeleteSerieMutation,
    useListAllSerieQuery,
    useListSerieEvenementQuery,
    useRemoveSerieEvenementMutation, useUpdateSerieMutation
} from "../../feature/serie/serieApiSlice";
import ListHoraire from "../../components/evenement/horaire/ListHoraire";
import {
    useAddHoraireMutation,
    useDeleteHoraireMutation,
    useListAllHoraireQuery, useUpdateHoraireMutation
} from "../../feature/horaire/horaireApiSlice";
import ModalHoraire from "../../components/evenement/horaire/ModalHoraire";
import {useGetEvenementQuery, useSaveEvenementMutation} from "../../feature/evenement/evenementApiSlice";
import {
    useAddAssuranceEvenementMutation, useCreateAssuranceMutation, useDeleteAssuranceMutation,
    useListAllAssuranceQuery,
    useListAssuranceEvenementQuery,
    useRemoveAssuranceEvenementMutation, useUpdateAssuranceMutation
} from "../../feature/assurance/assuranceApiSlice";
import ListAssurance from "../../components/evenement/assurance/ListAssurance";
import ModalAssurance from "../../components/evenement/assurance/ModalAssurance";
import {
    useAddRepasDetailMutation, useAjoutDateRepasMutation, useDeleteRepasDetailMutation,
    useListAllRepasEvenementQuery, useSuppressionDateRepasMutation, useUpdateRepasDetailMutation
} from "../../feature/repas/RepasApiSlice";
import PaiementEvenement from "../../components/evenement/paiement/PaiementEvenement";
import CommunicationEvenement from "../../components/evenement/communication/CommunicationEvenement";

const theme = createTheme();

const Evenement = () => {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Evenement';
    }, []);

    //mutation type inscription
    const [removeTypeInscriptionEvenement] = useRemoveTypeInscriptionEvenementMutation();
    const [addTypeInscriptionsEvenement] = useAddTypeInscriptionsEvenementMutation();
    const [createTypeInscription] = useCreateTypeInscriptionMutation();
    const [updateTypeInscription] = useUpdateTypeInscriptionMutation();
    const [deleteTypeInscription] = useDeleteTypeInscriptionMutation();
    //mutation type inscription
    const [removeTypeMotoEvenement] = useRemoveTypeMotoEvenementMutation();
    const [addTypeMotoEvenement] = useAddTypeMotoEvenementMutation();
    const [createTypeMoto] = useCreateTypeMotoMutation();
    const [updateTypeMoto] = useUpdateTypeMotoMutation();
    const [deleteTypeMoto] = useDeleteTypeMotoMutation();
    //mutation experience
    const [removeExperienceEvenement] = useRemoveExperienceEvenementMutation();
    const [addExperienceEvenement] = useAddExperienceEvenementMutation();
    const [createExperience] = useCreateExperienceMutation();
    const [updateExperience] = useUpdateExperienceMutation();
    const [deleteExperience] = useDeleteExperienceMutation();
    //mutation niveau
    const [removeNiveauEvenement] = useRemoveNiveauEvenementMutation();
    const [addNiveauEvenement] = useAddNiveauEvenementMutation();
    const [createNiveau] = useCreateNiveauMutation();
    const [updateNiveau] = useUpdateNiveauMutation();
    const [deleteNiveau] = useDeleteNiveauMutation();
    //mutation serie
    const [removeSerieEvenement] = useRemoveSerieEvenementMutation();
    const [addSerieEvenement] = useAddSerieEvenementMutation();
    const [createSerie] = useCreateSerieMutation();
    const [updateSerie] = useUpdateSerieMutation();
    const [deleteSerie] = useDeleteSerieMutation();
    //mutation horaire
    const [addHoraire] = useAddHoraireMutation();
    const [deleteHoraire] = useDeleteHoraireMutation();
    const [updateHoraire] = useUpdateHoraireMutation();
    //mutation assurance
    const [removeAssuranceEvenement] = useRemoveAssuranceEvenementMutation();
    const [addAssuranceEvenement] = useAddAssuranceEvenementMutation();
    const [createAssurance] = useCreateAssuranceMutation();
    const [updateAssurance] = useUpdateAssuranceMutation();
    const [deleteAssurance] = useDeleteAssuranceMutation();
    //mutation repas
    const [addRepasDetail] = useAddRepasDetailMutation();
    const [deleteRepasDetail] = useDeleteRepasDetailMutation();
    const [ajoutDateRepas] = useAjoutDateRepasMutation();
    const [suppressionDateRepas] = useSuppressionDateRepasMutation();
    const [updateRepasDetail] = useUpdateRepasDetailMutation();
    //mutation disponibiliter
    const [removeDisponibiliterEvenement] = useRemoveDisponibiliterEvenementMutation();
    const [addDisponibiliterEvenement] = useAddDisponibiliterEvenementMutation();
    const [moveDisponibiliterEvenement] = useMoveDisponibiliterEvenementMutation();
    //mutation disponibiliter
    const [saveEvenement] = useSaveEvenementMutation();

    const {evenementId} = useParams();
    const defaultDate = dayjs('2000-01-01');
    const [loadingGlobal, setLoadingGlobal] = useState(true);
    const [skipFetchData, setSkipFetchData] = useState(true);
    const [urlData, setUrlData] = useState({
        evenementID: null
    });
    const [formInformation, setFormInformation] = useState({
        nomEvenement: '',
        dateEvenement: defaultDate,
        dateDebutInscription: defaultDate,
        dateFinInscription: defaultDate,
        rouleur: false,
        benevole: false,
        zorga: false,
        assuranceZorgaOfferte: 'none',
        scoreStage: '',
        scoreCompetition: '',
        nombreTicketBenevole: '',
        delaiPaiement: 7,
        relancePaiement: 2,
        suiviDossierMail: true,
        suiviDossierSMS: true,
        numeroPlaqueIdentique: false,
    });
    const [showModal, setShowModal] = useState({
        modalInscriptionType: {
            show: false,
            edit: false,
            editID: {}
        },
        modalMotoType: {
            show: false,
            edit: false,
            editID: {}
        },
        modalExperience: {
            show: false,
            edit: false,
            editID: {}
        },
        modalNiveau: {
            show: false,
            edit: false,
            editID: {}
        },
        modalSerie: {
            show: false,
            edit: false,
            editID: {}
        },
        modalHoraire: {
            show: false,
            edit: false,
            serieID: '',
            editID: {}
        },
        modalAssurance: {
            show: false,
            edit: false,
            editID: {}
        },
        modalRepas: {
            show: false,
            edit: false,
            editID: {}
        }
    });
    const [locale] = useState('fr');
    const [technicalErrorAlert, setTechnicalErrorAlert] = useState(false);
    const [fonctionalErrorAlert, setFonctionalErrorAlert] = useState(false);
    const [sucessSaveAlert, setSucessSaveAlert] = useState(false);
    const [messageFonctionalErrorAlert, setMessageFonctionalErrorAlert] = useState('');
    const [formError, setFormError] = useState({
        nomEvenement: false,
        dateEvenement: false,
        dateDebutInscription: false,
        dateFinInscription: false,
        scoreStage: false,
        scoreCompetition: false,
        nombreTicketBenevole: false,
    });
    const [savingLoading, setSavingLoading] = useState(false);

    const {
        data: Evenement,
        isSuccess: SucessEvenement,
        error: errorEvenement
    } = useGetEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listDispoEvenement,
        isSuccess: SucessListDispoEvenement,
        isError: isErreurListDispoEvenement
    } = useListDisponibilitersEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listDisponibiliter,
        isSuccess: SucessListDisponibiliter,
        isError: isErreurListDisponibiliter
    } = useListAllDisponibilitersQuery({skip: skipFetchData});

    const {
        data: listInscriptionType,
        isSuccess: SucessListInscriptionType,
        isError: isErreurListInscriptionType
    } = useListAllTypeInscriptionQuery({skip: skipFetchData});

    const {
        data: listInscriptionTypeEvenement,
        isSuccess: SucessListInscriptionTypeEvenement,
        isError: isErreurListInscriptionTypeEvenement
    } = useListTypeInscriptionEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listMotoType,
        isSuccess: SucessListMotoType,
        isError: isErreurListMotoType
    } = useListAllTypeMotoQuery({skip: skipFetchData});

    const {
        data: listMotoTypeEvenement,
        isSuccess: SucessListMotoTypeEvenement,
        isError: isErreurListMotoTypeEvenement
    } = useListTypeMotoEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listExperience,
        isSuccess: SucessListExperience,
        isError: isErreurListExperience
    } = useListAllExperienceQuery({skip: skipFetchData});

    const {
        data: listExperienceEvenement,
        isSuccess: SucessListExperienceEvenement,
        isError: isErreurListExperienceEvenement
    } = useListExperienceEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listNiveau,
        isSuccess: SucessListNiveau,
        isError: isErreurListNiveau
    } = useListAllNiveauQuery({skip: skipFetchData});

    const {
        data: listNiveauEvenement,
        isSuccess: SucessListNiveauEvenement,
        isError: isErreurListNiveauEvenement
    } = useListNiveauEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listSerie,
        isSuccess: SucessListSerie,
        isError: isErreurListSerie
    } = useListAllSerieQuery({skip: skipFetchData});

    const {
        data: listSerieEvenement,
        isSuccess: SucessListSerieEvenement,
        isError: isErreurListSerieEvenement
    } = useListSerieEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listHoraire,
        isSuccess: SucessListHoraire,
        isError: isErreurListHoraire
    } = useListAllHoraireQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listAssurance,
        isSuccess: SucessListAssurance,
        isError: isErreurListAssurance
    } = useListAllAssuranceQuery({skip: skipFetchData});

    const {
        data: listAssuranceEvenement,
        isSuccess: SucessListAssuranceEvenement,
        isError: isErreurListAssuranceEvenement
    } = useListAssuranceEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const {
        data: listRepasEvenement,
        isSuccess: SucessListRepasEvenement,
        isError: isErreurListRepasEvenement
    } = useListAllRepasEvenementQuery(urlData.evenementID, {skip: skipFetchData});

    const errorGlobal = isErreurListAssuranceEvenement || isErreurListRepasEvenement || isErreurListAssurance || isErreurListHoraire || isErreurListSerieEvenement || isErreurListSerie || isErreurListNiveauEvenement || isErreurListNiveau || isErreurListExperienceEvenement || isErreurListExperience || isErreurListMotoTypeEvenement || isErreurListMotoType || isErreurListInscriptionTypeEvenement || isErreurListInscriptionType || isErreurListDisponibiliter || isErreurListDispoEvenement;

    useEffect(() => {
        setUrlData({
            ...urlData,
            evenementID: evenementId
        });
        setSkipFetchData(false);
    }, []);
    useEffect(() => {
        if (errorEvenement) {
            if (errorEvenement.status === 406) {
                navigate('/evenements');
            }
        }
        if (errorGlobal) {
            navigate('/500');
        }
        if (SucessListDisponibiliter && SucessListInscriptionType && SucessListInscriptionTypeEvenement
            && SucessListMotoType && SucessListMotoTypeEvenement && SucessListExperience
            && SucessListExperienceEvenement && SucessListNiveauEvenement && SucessListSerie
            && SucessListSerieEvenement && SucessListHoraire && SucessEvenement
            && SucessListDispoEvenement && SucessListAssurance && SucessListAssuranceEvenement
            && SucessListRepasEvenement && SucessListNiveau) {
            if (SucessEvenement) {
                setFormInformation({
                    ...formInformation,
                    nomEvenement: Evenement.libelle != null ? Evenement.libelle : formInformation.nomEvenement,
                    dateEvenement: Evenement.date != null ? dayjs(Evenement.date) : formInformation.dateEvenement,
                    dateDebutInscription: Evenement.datedebutinsciption != null ? dayjs(Evenement.datedebutinsciption) : formInformation.dateDebutInscription,
                    dateFinInscription: Evenement.datefininscription != null ? dayjs(Evenement.datefininscription) : formInformation.dateFinInscription,
                    rouleur: Evenement.rouleur != null ? Evenement.rouleur : formInformation.rouleur,
                    benevole: Evenement.benevole != null ? Evenement.benevole : formInformation.benevole,
                    zorga: Evenement.zorga != null ? Evenement.zorga : formInformation.zorga,
                    assuranceZorgaOfferte: Evenement.options_evenement.assurance_offerte === null ? formInformation.assuranceZorgaOfferte : Evenement.options_evenement.assurance_offerte,
                    scoreStage: Evenement.options_evenement.scoreStage != null ? Evenement.options_evenement.scoreStage : formInformation.scoreStage,
                    scoreCompetition: Evenement.options_evenement.scoreCompetition != null ? Evenement.options_evenement.scoreCompetition : formInformation.scoreCompetition,
                    nombreTicketBenevole: Evenement.options_evenement.nombreTicketBenevole != null ? Evenement.options_evenement.nombreTicketBenevole : formInformation.nombreTicketBenevole,
                    delaiPaiement: Evenement.options_evenement.delaisPaiement != null ? Evenement.options_evenement.delaisPaiement : formInformation.delaiPaiement,
                    relancePaiement: Evenement.options_evenement.delaisRelance != null ? Evenement.options_evenement.delaisRelance : formInformation.relancePaiement,
                    suiviDossierMail: Evenement.options_evenement.notificationMail != null ? Evenement.options_evenement.notificationMail : formInformation.suiviDossierMail,
                    suiviDossierSMS: Evenement.options_evenement.notificationSMS != null ? Evenement.options_evenement.notificationSMS : formInformation.suiviDossierSMS,
                    numeroPlaqueIdentique: Evenement.options_evenement.numeroPlaqueIdentique != null ? Evenement.options_evenement.numeroPlaqueIdentique : formInformation.numeroPlaqueIdentique
                })
                setLoadingGlobal(false);
            }
        }
    }, [SucessListDisponibiliter, SucessListInscriptionType, SucessListInscriptionTypeEvenement, SucessListMotoTypeEvenement, SucessListMotoType, SucessListExperience, SucessListExperienceEvenement, SucessListNiveauEvenement, SucessListSerie, SucessListSerieEvenement, SucessListHoraire, SucessListDispoEvenement, SucessListAssurance, SucessListAssuranceEvenement, SucessListRepasEvenement, errorEvenement, SucessListNiveau, errorGlobal]);

    const updateInformationEvenement = (champs, value) => {
        if (champs === 'name') {
            setFormInformation({
                ...formInformation,
                nomEvenement: value
            })
        } else if (champs === 'date') {
            setFormInformation({
                ...formInformation,
                dateEvenement: value
            });
        }
    };

    const handleChangeDebutInscription = (newValue) => {
        setFormInformation({
            ...formInformation,
            dateDebutInscription: newValue
        });
    };
    const handleChangeFinInscription = (newValue) => {
        setFormInformation({
            ...formInformation,
            dateFinInscription: newValue
        });
    };

    const handleAddDisponibiliter = async (idDispo) => {
        await addDisponibiliterEvenement({evenementID: urlData.evenementID, disponibiliterID: idDispo}).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleDeleteDisponibiliter = async (idDispo) => {
        await removeDisponibiliterEvenement({evenementID: urlData.evenementID, disponibiliterID: idDispo}).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleOrderUpDown = async (idDispo, order) => {
        await moveDisponibiliterEvenement({
            evenementID: urlData.evenementID,
            disponibiliterID: idDispo,
            order: order
        }).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };

    const handleOpenCloseAjoutRepas = (data) => {
        setShowModal({
            ...showModal,
            modalRepas: {...showModal.modalRepas, show: data, edit: false}
        });
    };
    const handleEnregistrerRepas = async (type, data) => {
        if (type === 'new') {
            const ObjAddRepas = {
                evenementID: urlData.evenementID, repas: {
                    libelle: data.libelle,
                    prixpublic: data.prixPublic,
                    prixcomplet: data.prixComplet,
                }
            };
            await addRepasDetail(ObjAddRepas).unwrap()
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'update') {
            await updateRepasDetail(data).unwrap()
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleDeleteRepas = async (idRepas) => {
        await deleteRepasDetail(idRepas).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleAddDayRepas = async (objAddDay) => {
        await ajoutDateRepas(objAddDay).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleDeleteDayRepas = async (objDeleteDayRepas) => {
        await suppressionDateRepas(objDeleteDayRepas).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleEditDayRepas = async (idDay) => {
        setShowModal({
            ...showModal,
            modalRepas: {
                edit: true,
                show: true,
                editID: listRepasEvenement.filter(val => val.id === idDay)[0]
            }
        });
    };

    const handleChangerZorga = (data) => {
        const {champs, value} = data;
        switch (champs) {
            case "check":
                setFormInformation({
                    ...formInformation,
                    zorga: value
                })
                break;
            case "assurance":
                setFormInformation({
                    ...formInformation,
                    assuranceZorgaOfferte: value
                })
                break;
            case "serie":
                setFormInformation({
                    ...formInformation,
                    numeroPlaqueIdentique: value
                })
                break;
        }
    };
    const handleChangeTicketBenevole = (event) => {
        setFormInformation({
            ...formInformation,
            nombreTicketBenevole: event.target.value
        });
    }

    const handleDeleteInscriptionTypeEvenement = async (idType) => {
        const data = {evenementID: urlData.evenementID, inscriptionTypeID: idType};
        await removeTypeInscriptionEvenement(data).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleEditInscriptionType = async (idType) => {
        setShowModal({
            ...showModal,
            modalInscriptionType: {
                edit: true,
                show: true,
                editID: listInscriptionType.filter(val => val.id === idType)[0]
            }
        });
    };
    const handleAddInscriptionTypeEvenement = async (type, dataType) => {
        if (type === 'old') {
            const data = {evenementID: urlData.evenementID, listInscriptionTypes: dataType};
            await addTypeInscriptionsEvenement(data).unwrap()
                .then(() => {
                    setShowModal({
                        ...showModal,
                        modalInscriptionType: {...showModal.modalInscriptionType, show: false}
                    });
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'new') {
            try {
                const newType = await createTypeInscription(dataType).unwrap();
                const data = {evenementID: urlData.evenementID, listInscriptionTypes: [newType.id]};
                await addTypeInscriptionsEvenement(data).unwrap();
                setShowModal({...showModal, modalInscriptionType: {...showModal.modalInscriptionType, show: false}});
            } catch (e) {
                setTechnicalErrorAlert(true);
            }
        } else if (type === 'update') {
            await updateTypeInscription(dataType).unwrap()
                .then(() => {
                    setShowModal({
                        ...showModal,
                        modalInscriptionType: {...showModal.modalInscriptionType, show: false}
                    });
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleDeleteTypeInscription = async (dataCell) => {
        await deleteTypeInscription(dataCell.id).unwrap()
            .catch(error => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleDeleteMotoTypeEvenement = async (idType) => {
        const data = {evenementID: urlData.evenementID, motoTypeID: idType};
        await removeTypeMotoEvenement(data).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleEditMotoType = async (idType) => {
        setShowModal({
            ...showModal,
            modalMotoType: {
                edit: true,
                show: true,
                editID: listMotoType.filter(val => val.id === idType)[0]
            }
        });
    };
    const handleAddMotoTypeEvenement = async (type, dataType) => {
        if (type === 'old') {
            const data = {evenementID: urlData.evenementID, listMotoTypes: dataType};
            await addTypeMotoEvenement(data).unwrap()
                .then(() => {
                    setShowModal({
                        ...showModal,
                        modalMotoType: {...showModal.modalMotoType, show: false}
                    });
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'new') {
            try {
                const newType = await createTypeMoto(dataType).unwrap();
                const data = {evenementID: urlData.evenementID, listMotoTypes: [newType.id]};
                await addTypeMotoEvenement(data).unwrap();
                setShowModal({...showModal, modalMotoType: {...showModal.modalMotoType, show: false}});
            } catch (e) {
                setTechnicalErrorAlert(true);
            }
        } else if (type === 'update') {
            await updateTypeMoto(dataType).unwrap()
                .then(() => {
                    setShowModal({
                        ...showModal,
                        modalMotoType: {...showModal.modalMotoType, show: false}
                    });
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleDeleteTypeMoto = async (dataCell) => {
        await deleteTypeMoto(dataCell.id).unwrap()
            .catch(error => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleDeleteExperienceEvenement = async (idType) => {
        const data = {evenementID: urlData.evenementID, experienceID: idType};
        await removeExperienceEvenement(data).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleEditEvenement = async (idType) => {
        setShowModal({
            ...showModal,
            modalExperience: {
                edit: true,
                show: true,
                editID: listExperience.filter(val => val.id === idType)[0]
            }
        });
    };
    const handleAddEvenementEvenement = async (type, dataType) => {
        if (type === 'old') {
            const data = {evenementID: urlData.evenementID, listExperience: dataType};
            await addExperienceEvenement(data).unwrap()
                .then(() => {
                    setShowModal({
                        ...showModal,
                        modalExperience: {...showModal.modalExperience, show: false}
                    });
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'new') {
            try {
                const newType = await createExperience(dataType).unwrap();
                const data = {evenementID: urlData.evenementID, listExperience: [newType.id]};
                await addExperienceEvenement(data).unwrap();
                setShowModal({...showModal, modalExperience: {...showModal.modalExperience, show: false}});
            } catch (e) {
                setTechnicalErrorAlert(true);
            }
        } else if (type === 'update') {
            await updateExperience(dataType).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalExperience: {...showModal.modalExperience, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleDeleteExperience = async (dataCell) => {
        await deleteExperience(dataCell.id).unwrap()
            .catch(error => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };
    const handleChangeScoreExperience = (type, dataType) => {
        if (type === 'stage') {
            setFormInformation({
                ...formInformation,
                scoreStage: dataType,
            });
        } else if (type === 'competition') {
            setFormInformation({
                ...formInformation,
                scoreCompetition: dataType,
            });
        }
    };

    const handleDeleteNiveauEvenement = async (idType) => {
        const data = {evenementID: urlData.evenementID, niveauID: idType};
        await removeNiveauEvenement(data).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleAddNiveauEvenement = async (type, dataType) => {
        if (type === 'old') {
            const data = {evenementID: urlData.evenementID, listNiveau: dataType};
            await addNiveauEvenement(data).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalNiveau: {...showModal.modalNiveau, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'new') {
            try {
                const newNiveau = await createNiveau(dataType).unwrap();
                const data = {evenementID: urlData.evenementID, listNiveau: [newNiveau.id]};
                await addNiveauEvenement(data).unwrap();
                setShowModal({...showModal, modalNiveau: {...showModal.modalNiveau, show: false}});
            } catch (e) {
                setTechnicalErrorAlert(true);
            }
        } else if (type === 'update') {
            await updateNiveau(dataType).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalNiveau: {...showModal.modalNiveau, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleEditNiveau = async (idType) => {
        setShowModal({
            ...showModal,
            modalNiveau: {
                edit: true,
                show: true,
                editID: listNiveau.filter(val => val.id === idType)[0]
            }
        });
    };
    const handleDeleteNiveau = async (dataCell) => {
        await deleteNiveau(dataCell.id).unwrap()
            .catch(error => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleDeleteSerieEvenement = async (idType) => {
        const data = {evenementID: urlData.evenementID, serieID: idType};
        await removeSerieEvenement(data).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleAddSerieEvenement = async (type, dataType) => {
        if (type === 'old') {
            const data = {evenementID: urlData.evenementID, listSerie: dataType};
            await addSerieEvenement(data).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalSerie: {...showModal.modalSerie, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'new') {
            try {
                const newSerie = await createSerie(dataType).unwrap();
                const data = {evenementID: urlData.evenementID, listSerie: [newSerie.id]};
                await addSerieEvenement(data).unwrap();
                setShowModal({...showModal, modalSerie: {...showModal.modalSerie, show: false}});
            } catch (e) {
                setTechnicalErrorAlert(true);
            }
        } else if (type === 'update') {
            await updateSerie(dataType).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalSerie: {...showModal.modalSerie, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleEditSerie = (idNiveau) => {
        setShowModal({
            ...showModal,
            modalSerie: {
                edit: true,
                show: true,
                editID: listSerie.filter(val => val.id === idNiveau)[0]
            }
        });
    };
    const handleDeleteSerie = async (dataCell) => {
        await deleteSerie(dataCell.id).unwrap()
            .catch(error => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleAddHoraireEvenement = async (type, dataType) => {
        if (type === 'new') {
            const data = {evenementID: urlData.evenementID, dataHoraire: dataType};
            await addHoraire(data).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalHoraire: {...showModal.modalHoraire, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'update') {
            await updateHoraire(dataType).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalHoraire: {...showModal.modalHoraire, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleDeleteHoraire = async (idHoraire) => {
        await deleteHoraire(idHoraire).unwrap()
            .catch(error => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };
    const handleEditHoraire = (idHoraire, idSerie, idJour) => {
        setShowModal({
            ...showModal,
            modalHoraire: {
                edit: true,
                show: true,
                serieID: idSerie,
                editID: {
                    ...listHoraire.find(val => val.id === idSerie)
                        .horaires.find(val => val.id === idJour)
                        .horaires.find(val => val.id === idHoraire), jourID: idJour
                }
            }
        });
    };

    const handleDeleteAssuranceEvenement = async (idAssurance) => {
        const data = {evenementID: urlData.evenementID, assuranceID: idAssurance};
        await removeAssuranceEvenement(data).unwrap()
            .catch(() => {
                setTechnicalErrorAlert(true);
            })
    };
    const handleAddAssuranceEvenement = async (type, dataAssurance) => {
        if (type === 'old') {
            const data = {evenementID: urlData.evenementID, listAssurance: dataAssurance};
            await addAssuranceEvenement(data).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalAssurance: {...showModal.modalAssurance, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        } else if (type === 'new') {
            try {
                const newAssurance = await createAssurance(dataAssurance).unwrap();
                const data = {evenementID: urlData.evenementID, listAssurance: [newAssurance.id]};
                await addAssuranceEvenement(data).unwrap();
                setShowModal({...showModal, modalAssurance: {...showModal.modalAssurance, show: false}});
            } catch (e) {
                setTechnicalErrorAlert(true);
            }
        } else if (type === 'update') {
            await updateAssurance(dataAssurance).unwrap()
                .then(() => {
                    setShowModal({...showModal, modalAssurance: {...showModal.modalAssurance, show: false}});
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };
    const handleEditAssurance = async (idAssurance) => {
        setShowModal({
            ...showModal,
            modalAssurance: {
                edit: true,
                show: true,
                editID: listAssurance.filter(val => val.id === idAssurance)[0]
            }
        });
    };
    const handleDeleteAssurance = async (dataCell) => {
        await deleteAssurance(dataCell.id).unwrap()
            .catch(error => {
                if (error.status === 406) {
                    setMessageFonctionalErrorAlert(error.data);
                    setFonctionalErrorAlert(true);
                } else {
                    setTechnicalErrorAlert(true);
                }
            })
    };

    const handleUpdatePaiement = (champs, data) => {
        if (champs === 'delais') {
            setFormInformation({
                ...formInformation,
                delaiPaiement: data
            });
        } else if (champs === 'relance') {
            setFormInformation({
                ...formInformation,
                relancePaiement: data
            });
        }
    };
    const handleUpdateCommunication = (champs, data) => {
        if (champs === 'mail') {
            setFormInformation({
                ...formInformation,
                suiviDossierMail: data
            });
        } else if (champs === 'sms') {
            setFormInformation({
                ...formInformation,
                suiviDossierSMS: data
            });
        }
    };

    const handleCloseFonctionalError = () => {
        setFonctionalErrorAlert(false);
    };
    const handleCloseTechnicalError = () => {
        setTechnicalErrorAlert(false);
    };
    const handleCloseSucessSave = () => {
        setSucessSaveAlert(false);
    };

    const handleClickAddTypeInscription = () => {
        setShowModal({
            ...showModal,
            modalInscriptionType: {...showModal.modalInscriptionType, edit: false, show: true}
        });
    };
    const handleClickAddTypeMoto = () => {
        setShowModal({
            ...showModal,
            modalMotoType: {...showModal.modalMotoType, edit: false, show: true}
        });
    };
    const handleClickAddExperience = () => {
        setShowModal({
            ...showModal,
            modalExperience: {...showModal.modalExperience, edit: false, show: true}
        });
    };
    const handleClickAddNiveau = () => {
        setShowModal({
            ...showModal,
            modalNiveau: {...showModal.modalNiveau, edit: false, show: true}
        });
    };
    const handleClickAddSerie = () => {
        setShowModal({
            ...showModal,
            modalSerie: {...showModal.modalSerie, edit: false, show: true}
        });
    };
    const handleClickAddHoraire = (idSerie) => {
        setShowModal({
            ...showModal,
            modalHoraire: {...showModal.modalHoraire, edit: false, show: true, serieID: idSerie}
        });
    };
    const handleClickAddAssurance = () => {
        setShowModal({
            ...showModal,
            modalAssurance: {...showModal.modalAssurance, edit: false, show: true}
        });
    };

    const handleCloseModalTypeInscription = () => {
        setShowModal({
            ...showModal,
            modalInscriptionType: {...showModal.modalInscriptionType, show: false}
        });
    };
    const handleCloseModalTypeMoto = () => {
        setShowModal({
            ...showModal,
            modalMotoType: {...showModal.modalMotoType, show: false}
        });
    };
    const handleCloseModalExperience = () => {
        setShowModal({
            ...showModal,
            modalExperience: {...showModal.modalExperience, show: false}
        });
    };
    const handleCloseModalNiveau = () => {
        setShowModal({
            ...showModal,
            modalNiveau: {...showModal.modalNiveau, show: false}
        });
    };
    const handleCloseModalSerie = () => {
        setShowModal({
            ...showModal,
            modalSerie: {...showModal.modalSerie, show: false}
        });
    };
    const handleCloseModalHoraire = () => {
        setShowModal({
            ...showModal,
            modalHoraire: {...showModal.modalHoraire, show: false}
        });
    };
    const handleCloseModalAssurance = () => {
        setShowModal({
            ...showModal,
            modalAssurance: {...showModal.modalAssurance, show: false}
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formUtils.verifErreurFormulaire(verificationFormulaire())) {
            setSavingLoading(true);
            await saveEvenement({
                evenementID: urlData.evenementID,
                evenementData: {
                    nomevenement: formInformation.nomEvenement,
                    dateevenement: formInformation.dateEvenement,
                    rouleur: formInformation.rouleur,
                    benevole: formInformation.benevole,
                    zorga: formInformation.zorga,
                    datedebutinsciption: formInformation.dateDebutInscription,
                    datefininscription: formInformation.dateFinInscription,
                    assuranceofferte: formInformation.assuranceZorgaOfferte === 'none' ? null : formInformation.assuranceZorgaOfferte,
                    scorestage: formInformation.scoreStage,
                    scorecompetition: formInformation.scoreCompetition,
                    nombreticketbenevole: formInformation.nombreTicketBenevole,
                    delaispaiement: formInformation.delaiPaiement,
                    delaisrelance: formInformation.relancePaiement,
                    notificationmail: formInformation.suiviDossierMail,
                    notificationsms: formInformation.suiviDossierSMS,
                    numeroplaqueidentique: formInformation.numeroPlaqueIdentique
                }
            }).unwrap()
                .then(() => {
                    setSavingLoading(false);
                    setSucessSaveAlert(true);
                })
                .catch(() => {
                    setTechnicalErrorAlert(true);
                })
        }
    };

    // filter props value
    const filterInscriptionTypeNotEvenement = (listInscriptionType) => {
        if (listInscriptionType != null && listInscriptionTypeEvenement != null) {
            return listInscriptionType.filter(type => listInscriptionTypeEvenement.filter(list => list.id === type.id).length === 0)
        } else {
            return []
        }
    };
    const filterMotoTypeNotEvenement = (listMotoType) => {
        if (listMotoType != null && listMotoTypeEvenement != null) {
            return listMotoType.filter(type => listMotoTypeEvenement.filter(list => list.id === type.id).length === 0)
        } else {
            return []
        }
    };
    const filterExperienceNotEvenement = (listExperience) => {
        if (listExperience != null && listExperienceEvenement != null) {
            return listExperience.filter(type => listExperienceEvenement.filter(list => list.id === type.id).length === 0)
        } else {
            return []
        }
    };
    const filterNiveauNotEvenement = (listNiveau) => {
        if (listNiveau != null && listNiveauEvenement != null) {
            return listNiveau.filter(type => listNiveauEvenement.filter(list => list.id === type.id).length === 0)
        } else {
            return []
        }
    };
    const filterSerieNotEvenement = (listSerie) => {
        if (listSerie != null && listSerieEvenement != null) {
            return listSerie.filter(type => listSerieEvenement.filter(list => list.id === type.id).length === 0)
        } else {
            return []
        }
    };
    const filterAssuranceNotEvenement = (listAssurance) => {
        if (listAssurance != null && listAssuranceEvenement != null) {
            return listAssurance.filter(type => listAssuranceEvenement.filter(list => list.id === type.id).length === 0)
        } else {
            return []
        }
    };
    const filterDisponibiliterNotEvenement = (listDisponibiliter) => {
        if (listDisponibiliter != null && listDispoEvenement != null) {
            return listDisponibiliter.filter(type => listDispoEvenement.filter(list => list.id === type.id).length === 0)
        } else {
            return []
        }
    };

    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            nomEvenement: formInformation.nomEvenement.length === 0,
            dateEvenement: formInformation.dateEvenement.diff(defaultDate) === 0,
            dateDebutInscription: formInformation.dateDebutInscription.diff(defaultDate) === 0,
            dateFinInscription: formInformation.dateFinInscription.diff(defaultDate) === 0,
            scoreStage: formInformation.rouleur ? formInformation.scoreStage.length === 0 : false,
            scoreCompetition: formInformation.rouleur ? formInformation.scoreCompetition.length === 0 : false,
            nombreTicketBenevole: formInformation.benevole ? formInformation.nombreTicketBenevole.length === 0 : false
        }
        setFormError({
            ...formError,
            ...ObjVerif
        });
        return ObjVerif;
    };

    return (
        <Fragment>
            {loadingGlobal ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="lg">
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={12}>
                                    <Typography align='center' variant="h4">Evenement</Typography>
                                </Grid>
                            </Grid>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 3
                            }}>Information</Divider>
                            <InformationEvenement updateInformation={updateInformationEvenement}
                                                  data={formInformation}
                                                  locale={locale}
                                                  formError={formError}/>
                            <Grid container spacing={2}>
                                <Grid item xs={6} lg={6}>
                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Divider textAlign="center" sx={{
                                                marginTop: 2,
                                                marginBottom: 3
                                            }}>Paiement</Divider>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <PaiementEvenement
                                                dataEvenement={formInformation}
                                                updateInfo={handleUpdatePaiement}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Divider textAlign="center" sx={{
                                                marginTop: 2,
                                                marginBottom: 3
                                            }}>Communication</Divider>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <CommunicationEvenement
                                                dataEvenement={formInformation}
                                                updateInfo={handleUpdateCommunication}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider textAlign="left" sx={{
                                marginTop: 2,
                                marginBottom: 3
                            }}>Inscription</Divider>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <Box sx={{
                                        textAlign: 'center'
                                    }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                            <DesktopDatePicker
                                                label="Debut des inscriptions"
                                                inputFormat="DD/MM/YYYY"
                                                value={formInformation.dateDebutInscription}
                                                onChange={handleChangeDebutInscription}
                                                renderInput={(params) => <TextField {...params}
                                                                                    error={formError.dateDebutInscription}
                                                                                    helperText={formError.dateDebutInscription ? 'Veuillez rensigner le champ' : ''}/>}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Box sx={{
                                        textAlign: 'center'
                                    }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                            <DesktopDatePicker
                                                label="Fin des inscriptions"
                                                inputFormat="DD/MM/YYYY"
                                                value={formInformation.dateFinInscription}
                                                onChange={handleChangeFinInscription}
                                                renderInput={(params) => <TextField {...params}
                                                                                    error={formError.dateFinInscription}
                                                                                    helperText={formError.dateFinInscription ? 'Veuillez rensigner le champ' : ''}/>}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={formInformation.rouleur}
                                                                               onChange={event => setFormInformation({
                                                                                   ...formInformation,
                                                                                   rouleur: event.target.checked
                                                                               })}/>}
                                                              label="Inscription ouverte pour les rouleurs"/>
                                            <FormControlLabel control={<Switch checked={formInformation.benevole}
                                                                               onChange={event => setFormInformation({
                                                                                   ...formInformation,
                                                                                   benevole: event.target.checked,
                                                                                   zorga: event.target.checked ? formInformation.zorga : false,
                                                                               })}/>}
                                                              label="Inscription ouverte pour les benevoles"/>
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {formInformation.rouleur &&
                                <Fragment>
                                    <Typography textAlign='center' variant="h5">
                                        Inscription
                                    </Typography>
                                    <Divider textAlign="left" sx={{
                                        marginBottom: 1,
                                    }}>Type d'inscription</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button variant="contained"
                                                        onClick={handleClickAddTypeInscription}>Ajout Type
                                                    d'inscription</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                                <ListTypeInscription
                                                    listTypeInscription={listInscriptionTypeEvenement}
                                                    deleteInscriptionType={handleDeleteInscriptionTypeEvenement}
                                                    editInscriptionType={handleEditInscriptionType}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}>Type de moto</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button variant="contained"
                                                        onClick={handleClickAddTypeMoto}>Ajout Type de moto</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                                <ListTypeMoto
                                                    listTypeMoto={listMotoTypeEvenement}
                                                    deleteMotoType={handleDeleteMotoTypeEvenement}
                                                    editMotoType={handleEditMotoType}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}>Expérience</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button variant="contained"
                                                        onClick={handleClickAddExperience}>Ajout Expérience</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                                <ListExperience
                                                    listExperience={listExperienceEvenement}
                                                    deleteExperience={handleDeleteExperienceEvenement}
                                                    editExperience={handleEditEvenement}/>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <ScoreExperience
                                                data={formInformation}
                                                onChange={handleChangeScoreExperience}
                                                formError={formError}/>
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}>Niveau</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button variant="contained"
                                                        onClick={handleClickAddNiveau}>Ajout Niveau</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                                <ListNiveau
                                                    listNiveau={listNiveauEvenement}
                                                    deleteNiveau={handleDeleteNiveauEvenement}
                                                    editNiveau={handleEditNiveau}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}>Assurance</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button variant="contained"
                                                        onClick={handleClickAddAssurance}>Ajout Assurance</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                                <ListAssurance
                                                    listAssurance={listAssuranceEvenement}
                                                    deleteAssurance={handleDeleteAssuranceEvenement}
                                                    editAssurance={handleEditAssurance}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}>Serie</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button variant="contained"
                                                        onClick={handleClickAddSerie}>Ajout Serie</Button>
                                            </Box>
                                        </Grid>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item xs={12} lg={12}>
                                                <ListSerie
                                                    listSerie={listSerieEvenement}
                                                    deleteSerie={handleDeleteSerieEvenement}
                                                    editSerie={handleEditSerie}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 2,
                                        marginBottom: 1
                                    }}>Horaire</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <ListHoraire
                                                listHoraire={listHoraire}
                                                AjouterHoraire={handleClickAddHoraire}
                                                DeleteHoraire={handleDeleteHoraire}
                                                EditHoraire={handleEditHoraire}/>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{mt: 2, mb: 2}}/>
                                </Fragment>
                            }
                            {formInformation.benevole &&
                                <Fragment>
                                    <Typography textAlign='center' variant="h5">
                                        Benevole
                                    </Typography>
                                    <Divider textAlign="left" sx={{
                                        marginBottom: 1
                                    }}>Repas</Divider>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button variant="contained"
                                                        onClick={() => handleOpenCloseAjoutRepas(true)}>Ajouter
                                                    Repas</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{mt: 0.25}}>
                                        {
                                            listRepasEvenement.map(value => {
                                                return (
                                                    <Grid key={value.id} item xs={12} sm={4}>
                                                        <DetailRepas data={value}
                                                                     deleteItem={handleDeleteRepas}
                                                                     addDay={handleAddDayRepas}
                                                                     deleteDay={handleDeleteDayRepas}
                                                                     editDay={handleEditDayRepas}/>
                                                    </Grid>)
                                            })
                                        }
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 1,
                                        marginBottom: 1
                                    }}>Disponibiliter</Divider>
                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item xs={12} lg={5}>
                                            <AjoutDisponibiliter
                                                listDisponibiliter={filterDisponibiliterNotEvenement(listDisponibiliter)}
                                                addDispo={handleAddDisponibiliter}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <ListDisponibiliter
                                                listDisponibiliter={listDispoEvenement}
                                                deleteDispo={handleDeleteDisponibiliter}
                                                orderDispo={handleOrderUpDown}/>
                                        </Grid>
                                    </Grid>
                                    <Divider textAlign="left" sx={{
                                        marginTop: 1,
                                        marginBottom: 1
                                    }}>Ticket Repas</Divider>
                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item xs={12} lg={3}>
                                            <TextField fullWidth label="Nombre Ticket" id="input-nombres-ticket"
                                                       type='number'
                                                       value={formInformation.nombreTicketBenevole}
                                                       onChange={handleChangeTicketBenevole}
                                                       error={formError.nombreTicketBenevole}
                                                       helperText={formError.nombreTicketBenevole ? 'Veuillez rensigner le champ' : 'Nombres de ticket boisson par bénévole'}
                                            />
                                        </Grid>
                                    </Grid>
                                    {(formInformation.zorga && !formInformation.rouleur) && <Fragment>
                                        <Divider textAlign="left" sx={{
                                            marginTop: 2,
                                            marginBottom: 1
                                        }}>Assurance</Divider>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={12}>
                                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Button variant="contained"
                                                            onClick={handleClickAddAssurance}>Ajout Assurance</Button>
                                                </Box>
                                            </Grid>
                                            <Grid container justifyContent="center" spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <ListAssurance
                                                        listAssurance={listAssuranceEvenement}
                                                        deleteAssurance={handleDeleteAssuranceEvenement}
                                                        editAssurance={handleEditAssurance}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Fragment>}
                                    <Divider textAlign="left" sx={{
                                        marginTop: 1,
                                        marginBottom: 1
                                    }}>Serie Zorga</Divider>
                                    <Grid container spacing={2}>
                                        <SerieZorga assuranceList={listAssuranceEvenement} data={formInformation}
                                                    onChange={handleChangerZorga}/>
                                    </Grid>
                                </Fragment>
                            }
                            <Grid container spacing={2} justifyContent="center" sx={{mt: 2, mb: 10}}>
                                <Grid item xs={5} lg={5}>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        loading={savingLoading}
                                    >
                                        Sauvegarder
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                    {showModal.modalInscriptionType.show &&
                        <ModalAjoutTypeInsciption
                            data={filterInscriptionTypeNotEvenement(listInscriptionType)}
                            closeModal={handleCloseModalTypeInscription}
                            addType={handleAddInscriptionTypeEvenement}
                            editMode={showModal.modalInscriptionType.edit}
                            editItem={showModal.modalInscriptionType.editID}
                            deleteInsciptionType={handleDeleteTypeInscription}/>}
                    {showModal.modalMotoType.show &&
                        <ModalAjoutTypeMoto
                            data={filterMotoTypeNotEvenement(listMotoType)}
                            closeModal={handleCloseModalTypeMoto}
                            addType={handleAddMotoTypeEvenement}
                            editMode={showModal.modalMotoType.edit}
                            editItem={showModal.modalMotoType.editID}
                            deleteTypeMoto={handleDeleteTypeMoto}/>}
                    {showModal.modalExperience.show &&
                        <ModalExperience
                            data={filterExperienceNotEvenement(listExperience)}
                            closeModal={handleCloseModalExperience}
                            addEvenement={handleAddEvenementEvenement}
                            editMode={showModal.modalExperience.edit}
                            editItem={showModal.modalExperience.editID}
                            deleteExperience={handleDeleteExperience}/>
                    }
                    {showModal.modalNiveau.show &&
                        <ModalNiveau
                            data={filterNiveauNotEvenement(listNiveau)}
                            closeModal={handleCloseModalNiveau}
                            addNiveau={handleAddNiveauEvenement}
                            editMode={showModal.modalNiveau.edit}
                            editItem={showModal.modalNiveau.editID}
                            deleteNiveau={handleDeleteNiveau}/>
                    }
                    {showModal.modalSerie.show &&
                        <ModalSerie
                            data={filterSerieNotEvenement(listSerie)}
                            listNiveau={listNiveauEvenement}
                            listTypeInscription={listInscriptionTypeEvenement}
                            listTypeMoto={listMotoTypeEvenement}
                            closeModal={handleCloseModalSerie}
                            addSerie={handleAddSerieEvenement}
                            editMode={showModal.modalSerie.edit}
                            editItem={showModal.modalSerie.editID}
                            deleteSerie={handleDeleteSerie}/>
                    }
                    {showModal.modalHoraire.show &&
                        <ModalHoraire
                            listSerie={listSerieEvenement}
                            serieSelect={showModal.modalHoraire.serieID}
                            closeModal={handleCloseModalHoraire}
                            addHoraire={handleAddHoraireEvenement}
                            editMode={showModal.modalHoraire.edit}
                            editItem={showModal.modalHoraire.editID}/>
                    }
                    {showModal.modalAssurance.show &&
                        <ModalAssurance
                            data={filterAssuranceNotEvenement(listAssurance)}
                            closeModal={handleCloseModalAssurance}
                            addAssurance={handleAddAssuranceEvenement}
                            editMode={showModal.modalAssurance.edit}
                            editItem={showModal.modalAssurance.editID}
                            deleteAssurance={handleDeleteAssurance}/>
                    }
                    {showModal.modalRepas.show &&
                        <AddRepas closeWindows={handleOpenCloseAjoutRepas}
                                  ajouterRepas={handleEnregistrerRepas}
                                  editMode={showModal.modalRepas.edit}
                                  editItem={showModal.modalRepas.editID}/>}
                </ThemeProvider>}
            <Snackbar open={fonctionalErrorAlert} autoHideDuration={5000} onClose={handleCloseFonctionalError}>
                <Alert onClose={handleCloseFonctionalError} severity="error" sx={{width: '100%'}}>
                    {messageFonctionalErrorAlert}
                </Alert>
            </Snackbar>
            <Snackbar open={technicalErrorAlert} autoHideDuration={5000} onClose={handleCloseTechnicalError}>
                <Alert onClose={handleCloseTechnicalError} severity="error" sx={{width: '100%'}}>
                    Erreur technique essayer ultérieurement !
                </Alert>
            </Snackbar>
            <Snackbar open={sucessSaveAlert} autoHideDuration={2000} onClose={handleCloseSucessSave}>
                <Alert onClose={handleCloseSucessSave} severity="success" sx={{width: '100%'}}>
                    Enregistrement effectuer avec succès !
                </Alert>
            </Snackbar>
        </Fragment>
    )
};

export default Evenement;