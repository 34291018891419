import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {MuiTelInput} from "mui-tel-input";

export default (props) => {

    const handleChangePrenom = (event) => {
        props.updateContact('prenom', event.target.value);
    };
    const handleChangeNom = (event) => {
        props.updateContact('nom', event.target.value);
    };
    const handleChangeTelephone = (newValue) => {
        props.updateContact('numero', newValue);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="Prenom"
                    fullWidth
                    id="prenom_contact"
                    label="Prenom"
                    value={props.dataContact.prenom}
                    onChange={handleChangePrenom}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="Nom"
                    fullWidth
                    id="nom_contact"
                    label="Nom"
                    onChange={handleChangeNom}
                    value={props.dataContact.nom}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <MuiTelInput
                    fullWidth
                    id="telephone_contact"
                    label="Numero de Téléphone"
                    value={props.dataContact.numero}
                    onChange={handleChangeTelephone}
                    onlyCountries={['FR', 'BE', 'IT', 'ES', 'GB', 'CH', 'IE', 'NL', 'LU']}
                    langOfCountryName="fr"
                    defaultCountry="FR"
                    forceCallingCode
                    required
                />
            </Grid>
        </Grid>
    )
}