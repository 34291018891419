import {Fragment, useEffect} from "react";
import {
    Backdrop, Box, Chip,
    CircularProgress,
    createTheme,
    Divider,
    FormControl, FormControlLabel, FormGroup, FormHelperText,
    InputAdornment,
    OutlinedInput, Snackbar, Switch
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useListAllDroitQuery} from "../../feature/droit/droitApiSlice";
import TransfertListDroit from "../../components/compteUtilisateur/TransfertListDroit";
import {
    useCreateCompteAdminMutation,
    useListerUtilisateurAdminQuery,
    useNewPasswordCompteAdminMutation, useSaveCompteAdminMutation
} from "../../feature/utilisateur/utilisateurApiSlice";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import formUtils from "../../utils/formUtils";
import Alert from "@mui/material/Alert";

const theme = createTheme();

export default () => {

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Utilisateur';
    }, []);

    const {userId} = useParams();
    const navigate = useNavigate();

    const [loadingGlobal, setLoadingGlobal] = useState(true);
    const [skipFetchData, setSkipFetchData] = useState(true);
    const [urlData, setUrlData] = useState({
        utilisateurID: null
    });
    const [currentUser, setCurrentUser] = useState({
        id: '',
        email: '',
        role: '',
        droits: [],
        bloquer: true,
        emailNewPassword: '',
        sendEmail: false,
        emailEnvoyerIdentifiant: ''
    });
    const [erreurForm, setErreurForm] = useState({
        emailNewPassword: {message: '', error: false},
        role: {message: '', error: false},
        mail: {message: '', error: false},
        emailEnvoyerIdentifiant: {message: '', error: false},
    });
    const [loading, setLoading] = useState({
        sendNewPassword: false,
        saveDataUser: false
    });
    const [sucessAlert, setSucessAlert] = useState(false);
    const [sucessAlertMessage, setSucessAlertMessage] = useState('');

    const [newPasswordCompteAdmin] = useNewPasswordCompteAdminMutation();
    const [saveCompteAdmin] = useSaveCompteAdminMutation();
    const [createCompteAdmin] = useCreateCompteAdminMutation();

    const {
        data: listUtilisateurAdmin,
        isSuccess: SucessListUtilisateurAdmin,
        isError: isErreurListUtilisateurAdmin
    } = useListerUtilisateurAdminQuery(null, {skip: skipFetchData});

    const {
        data: listAllDroit,
        isSuccess: SucessListAllDroit,
        isError: isErreurListAllDroit
    } = useListAllDroitQuery(null);

    const mail = '@trofeorosso.org';

    const fetchData = SucessListUtilisateurAdmin && SucessListAllDroit;

    useEffect(() => {
        if (userId !== undefined) {
            setUrlData({
                ...urlData,
                utilisateurID: userId
            });
            setSkipFetchData(false);
        }
    }, []);

    useEffect(() => {
        //modifier compte utilisateur
        if (fetchData && listUtilisateurAdmin) {
            const utilisateur = listUtilisateurAdmin.find(val => val.id === userId);
            setCurrentUser({
                ...currentUser,
                id: utilisateur.id,
                role: utilisateur.role,
                email: utilisateur.adressemail.split('@')[0],
                droits: utilisateur.droits,
                bloquer: utilisateur.tentative_password >= 3
            });
            setLoadingGlobal(false);
        }
        //create utilisateur
        if (SucessListAllDroit && userId === undefined) {
            setLoadingGlobal(false);
        }
    }, [fetchData, listUtilisateurAdmin, SucessListAllDroit]);

    const filterDroitUserAdmin = (listAllDroit) => {
        return listAllDroit.filter(type => currentUser.droits.filter(list => list.id === type.id).length === 0)
            .filter(name => {
                const droit = ['ADMIN', 'BUREAU', 'USER'];
                return droit.filter(value => value === name.code).length === 0;
            });
    };

    const handleChangeSendMail = (event) => {
        setCurrentUser({
            ...currentUser,
            sendEmail: event.target.checked
        });
    };
    const handleChangeEmailResetPassword = (event) => {
        setCurrentUser({
            ...currentUser,
            emailNewPassword: event.target.value
        });
    };
    const handleSendNewPassword = async () => {
        if (currentUser.emailNewPassword.length === 0) {
            setErreurForm({
                ...erreurForm,
                emailNewPassword: {
                    message: 'Veuillez renseigner le champ',
                    error: true
                }
            });
        } else if (!formUtils.verifEmailFormat(currentUser.emailNewPassword)) {
            setErreurForm({
                ...erreurForm,
                emailNewPassword: {
                    message: 'Format adresse mail invalide',
                    error: true
                }
            });
        } else {
            setErreurForm({
                ...erreurForm,
                emailNewPassword: {
                    ...erreurForm.emailNewPassword,
                    error: false
                }
            });
            const data = {
                userid: currentUser.id,
                mail: currentUser.emailNewPassword,
                sendmail: currentUser.sendEmail
            }
            setLoading({
                ...loading,
                sendNewPassword: true
            });
            console.log(currentUser.id);
            await newPasswordCompteAdmin(data).unwrap()
                .then(() => {
                    setLoading({
                        ...loading,
                        sendNewPassword: false
                    });
                    setSucessAlertMessage('Nouveau mot de passe envoyer avec succès !');
                    setSucessAlert(true);
                })
                .catch(() => {
                    setLoading({
                        ...loading,
                        sendNewPassword: false
                    });
                })
        }
    };
    const handleChangeDroit = (data) => {
        setCurrentUser({
            ...currentUser,
            droits: data
        });
    };
    const handleChangeEmailIdentifiant = (event) => {
        setCurrentUser({
            ...currentUser,
            emailEnvoyerIdentifiant: event.target.value
        });
    };
    const handleChangeRole = (event) => {
        setCurrentUser({
            ...currentUser,
            role: event.target.value
        });
    };
    const handleChangeEmailCompte = (event) => {
        setCurrentUser({
            ...currentUser,
            email: event.target.value
        });
    }

    const handleSaveUserAdmin = async () => {
        if (urlData.utilisateurID === null) {
            if (formUtils.verifErreurFormulaireObjectComplexe(verificationFormulaire())) {
                const data = {
                    role: currentUser.role,
                    mail: currentUser.email + mail,
                    emailIdentifiant: currentUser.emailEnvoyerIdentifiant,
                    droits: currentUser.droits.length > 0 ? currentUser.droits.map(value => value.id) : [],
                };
                setLoading({
                    ...loading,
                    saveDataUser: true
                });
                await createCompteAdmin(data).unwrap()
                    .then(() => {
                        navigate(`/utilisateurs`);
                        setLoading({
                            ...loading,
                            saveDataUser: false
                        });
                    })
                    .catch(() => {
                        setLoading({
                            ...loading,
                            saveDataUser: false
                        });
                    })
            }
        } else {
            const data = {
                userID: currentUser.id,
                droits: currentUser.droits.length > 0 ? currentUser.droits.map(value => value.id) : [],
                role: currentUser.role,
                mail: currentUser.email + mail,
            };
            setLoading({
                ...loading,
                saveDataUser: true
            });
            await saveCompteAdmin(data).unwrap()
                .then(() => {
                    setLoading({
                        ...loading,
                        saveDataUser: false
                    });
                    setSucessAlertMessage('Compte sauvegarder avec succès !');
                    setSucessAlert(true);
                })
                .catch(() => {
                    setLoading({
                        ...loading,
                        saveDataUser: false
                    });
                })
        }

    };

    const handleCloseSucessSave = () => {
        setSucessAlert(false);
    };
    const verificationFormulaire = () => {
        let ObjVerif;
        ObjVerif = {
            role: {error: currentUser.role.length === 0, message: 'Veuillez renseigner le champ'},
            mail: {error: currentUser.email.length === 0, message: 'Veuillez renseigner le champ'},
            emailEnvoyerIdentifiant: {
                error: currentUser.emailEnvoyerIdentifiant.length === 0,
                message: 'Veuillez renseigner le champ'
            },
        }
        setErreurForm({
            ...erreurForm,
            ...ObjVerif
        });
        return ObjVerif;
    };

    return (
        <Fragment>
            {loadingGlobal ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                :
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="md" sx={{mb: 10, mt: 2}}>
                        <Divider textAlign="center" sx={{
                            marginTop: 2,
                            marginBottom: 2,
                        }}>Information utilisateur</Divider>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="Role utilisateur"
                                    fullWidth
                                    id="input-text-nom-utilisateur"
                                    label="Role utilisateur"
                                    variant="outlined"
                                    required
                                    value={currentUser.role}
                                    onChange={handleChangeRole}
                                    error={erreurForm.role.error}
                                    helperText={erreurForm.role.error ? erreurForm.role.message : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <OutlinedInput
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment position="end">{mail}</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        value={currentUser.email}
                                        onChange={handleChangeEmailCompte}
                                        error={erreurForm.mail.error}
                                    />
                                    {erreurForm.mail.error && <FormHelperText
                                        error={erreurForm.mail.error}>{erreurForm.mail.message}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            {urlData.utilisateurID !== null &&
                                <Grid item xs={12} md={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Chip label={`Compte ${currentUser.bloquer ? 'bloquer' : 'actif'}`}
                                              color={currentUser.bloquer ? 'error' : 'success'}/>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                        {urlData.utilisateurID !== null &&
                            <Fragment>
                                <Divider textAlign="center" sx={{
                                    marginTop: 2,
                                    marginBottom: 2,
                                }}>Mot de passe</Divider>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12} md={12}>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    name="Adresse mail"
                                                    fullWidth
                                                    id="input-text-nom-utilisateur"
                                                    label="Adresse mail"
                                                    variant="outlined"
                                                    required
                                                    value={currentUser.emailNewPassword}
                                                    onChange={handleChangeEmailResetPassword}
                                                    error={erreurForm.emailNewPassword.error}
                                                    helperText={erreurForm.emailNewPassword.error ? erreurForm.emailNewPassword.message : 'Adresse mail pour envoyer le nouveau mot de passe'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={currentUser.sendEmail}
                                                                               onChange={handleChangeSendMail}/>}
                                                              label="Envoyer identifiant"/>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <LoadingButton
                                                variant="contained" onClick={handleSendNewPassword}
                                                loading={loading.sendNewPassword}>Envoyer mot de passe</LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Fragment>}
                        <Divider textAlign="center" sx={{
                            marginTop: 2,
                            marginBottom: 2,
                        }}>Droits</Divider>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TransfertListDroit
                                    listDroit={filterDroitUserAdmin(listAllDroit)}
                                    listDroitUtilisateur={currentUser.droits}
                                    modificationDroit={handleChangeDroit}
                                />
                            </Grid>
                        </Grid>
                        {urlData.utilisateurID === null && <Fragment>
                            <Divider textAlign="center" sx={{
                                marginTop: 2,
                                marginBottom: 2,
                            }}>E-mail</Divider>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="Adresse mail"
                                        fullWidth
                                        id="input-text-nom-utilisateur"
                                        label="Adresse mail"
                                        variant="outlined"
                                        required
                                        value={currentUser.emailEnvoyerIdentifiant}
                                        onChange={handleChangeEmailIdentifiant}
                                        error={erreurForm.emailEnvoyerIdentifiant.error}
                                        helperText={erreurForm.emailEnvoyerIdentifiant.error ? erreurForm.emailEnvoyerIdentifiant.message : 'Adresse mail pour envoyer les identifiants'}
                                    />
                                </Grid>
                            </Grid>
                        </Fragment>}
                        <Grid container spacing={2} justifyContent="center" sx={{mt: 2, mb: 10}}>
                            <Grid item xs={5} lg={5}>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    onClick={handleSaveUserAdmin}
                                    loading={loading.saveDataUser}
                                >
                                    {urlData.utilisateurID === null ? 'Créé compte' : 'Sauvegarder'}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Container>
                </ThemeProvider>
            }
            <Snackbar open={sucessAlert} autoHideDuration={5000} onClose={handleCloseSucessSave}>
                <Alert onClose={handleCloseSucessSave} severity="success" sx={{width: '100%'}}>
                    {sucessAlertMessage}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}