import {Fragment, useEffect, useState} from "react";
import {
    Card,
    CardActions,
    CardContent,
    FormControl,
    InputBase, InputLabel,
    List,
    ListItem,
    ListItemText, MenuItem,
    Paper, Select,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ListDateRepas from "../../../Constants/ListDateRepas";

const DetailRepas = (props) => {
    const repasDetail = props.data;

    const [selectDate] = useState('');
    const [listSelectDate, setlistSelectDate] = useState([]);

    useEffect(() => {
        setlistSelectDate([...ListDateRepas.filter(repas => repasDetail.repas.filter(select => select.date === repas).length === 0)].map(value =>
            <MenuItem key={value} value={value}>{value}</MenuItem>));
    }, [props.data]);

    const handleDeleteDay = (idDay) => {
        props.deleteDay(idDay);
    };
    const handleChange = (event) => {
        props.addDay({date: event.target.value, repasDetailID: repasDetail.id});
    };

    return (
        <Fragment>
            <Card variant="outlined" sx={{
                backgroundColor: 'rgba(160,160,160,0.39)'
            }}>
                <CardActions sx={{mb: -4,display: 'flex', justifyContent: 'space-between'}}>
                    <Button size="small" color='error'
                            onClick={() => props.deleteItem(repasDetail.id)}>Supprimer</Button>
                    <Button size="small" color='primary'
                            onClick={() => props.editDay(repasDetail.id)}>Editer</Button>
                </CardActions>
                <CardContent sx={{textAlign: 'center'}}>
                    <Typography variant="h5" sx={{mb: 1.5}}>
                        {repasDetail.libelle}
                    </Typography>
                    <Typography sx={{mb: 1.5}} color="text.secondary">
                        {`Prix Publique: ${repasDetail.prixpublic} €`}
                    </Typography>
                    <Typography color="text.secondary">
                        {`Prix Complet: ${repasDetail.prixcomplet} €`}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Paper
                        sx={{width: 400}}
                    >
                        <FormControl sx={{minWidth: 120}} size="small" fullWidth>
                            <InputLabel id="demo-select-small">Date</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={selectDate}
                                label="Date"
                                onChange={handleChange}
                            >
                                {listSelectDate}
                            </Select>
                        </FormControl>
                    </Paper>
                </CardActions>
                {repasDetail.repas.length > 0 &&
                    <CardContent>
                        <Paper>
                            <List>
                                {repasDetail.repas.map(result => {
                                    return <ListItem key={result.id} secondaryAction={
                                        <IconButton edge="end" aria-label="delete"
                                                    onClick={() => handleDeleteDay(result.id)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    }>
                                        <ListItemText
                                            primary={`${result.date}`}
                                        />
                                    </ListItem>
                                })}
                            </List>
                        </Paper>
                    </CardContent>}
            </Card>
        </Fragment>
    )
};

export default DetailRepas;