import {
    Box,
    List,
    ListItem, ListItemText,
    Paper,
    Popper,
} from "@mui/material";
import {Fragment, useState} from "react";
import IconButton from "@mui/material/IconButton";
import ListIcon from '@mui/icons-material/List';

export default (props) => {

    const [popperDroit, setPopperDroit] = useState({
        show: false,
        selectElement: null,
        data: ''
    });

    const handleShowPopperDroit = (data) => (event) => {
        setPopperDroit({
            show: true,
            selectElement: event.currentTarget,
            data: data
        });
    };
    const handleHidePopperDroit = () => {
        setPopperDroit({
            ...popperDroit,
            show: false
        });
    };

    return (
        <Fragment>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <IconButton aria-label="edit">
                    <ListIcon onMouseEnter={handleShowPopperDroit(props.value)}
                              onMouseLeave={handleHidePopperDroit}/>
                </IconButton>
            </Box>
            {popperDroit.show && (
                <Popper open={popperDroit.show} anchorEl={popperDroit.selectElement} placement='right'>
                    <Paper sx={{backgroundColor: 'rgb(160,160,160)'}}>
                        <List dense={true}>
                            {popperDroit.data.map((row) => (
                                <ListItem key={row.id}>
                                    <ListItemText primary={row.libelle}/>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Popper>
            )}
        </Fragment>
    );
}