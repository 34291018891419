import {Outlet, Navigate, useLocation} from "react-router-dom"
import {useSelector} from "react-redux"
import {selectCurrentToken, selectCurrentTokenPayload} from "./authSlice";
import pageAdministration from "../../utils/pageAdministration";

const RequireAuth = () => {

    const location = useLocation();
    const token = useSelector(selectCurrentToken);
    const tokenPayload = useSelector(selectCurrentTokenPayload);
    let AuthorizedUrl = false;

    if (token) {
        pageAdministration.forEach(page => {
            if (location.pathname.split('/')[1] === page.url.split('/')[1]) {
                if (tokenPayload.droits.filter(tokenDroit => page.access.filter(access => access === tokenDroit).length > 0).length > 0) {
                    AuthorizedUrl = true;
                }
            }
        })
    }
    return (
        token
            ? AuthorizedUrl ? <Outlet/> : <Navigate to="/home" replace/>
            : <Navigate to="/login" replace/>
    )
}
export default RequireAuth;