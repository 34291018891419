import {Fragment, useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import StatutDossier from "../../components/gestionInscription/StatutDossier";
import * as Constants from "../../Constants/ContantesDossier";
import {LISTE_STATUTS_DOSSIER} from "../../Constants/ContantesDossier";
import IconButton from "@mui/material/IconButton";
import {useInitierFactureInscriptionMutation} from "../../feature/inscription/inscriptionApiSlice";
import {DataGrid, frFR} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import PaymentIcon from '@mui/icons-material/Payment';
import ModalEncaissementManuel from "../../components/encaissement/ModalEncaissementManuel";
import Alert from "@mui/material/Alert";
import SearchOffIcon from "@mui/icons-material/SearchOff";

const theme = createTheme();

export default (props) => {

    const {listEncaissement, listSerie, evenementID} = props;

    useEffect(() => {
        document.title = 'Trofeo Rosso Admin | Encaissemeent Inscription';
    }, []);

    const navigate = useNavigate();

    const initialFilter = {
        statut: '',
        serie: '',
        utilisateur: '',
        barcode: ''
    }

    const [pagesize, setPagesize] = useState(50);
    const [showModal, setShowModal] = useState({
        modalEncaissement: {
            show: false,
            userID: '',
            factureID: ''

        }
    });
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');
    const [sucessAlert, setSucessAlert] = useState(false);
    const [sucessAlertMessage, setSucessAlertMessage] = useState('');
    const [filter, setFilter] = useState(initialFilter);

    const [initierFactureInscription, {isLoading: isLoadingInitierFactureInscription}] = useInitierFactureInscriptionMutation();

    const handleFacture = (cellValue) => {
        navigate(`/facture/${cellValue.row.facture.id}/${cellValue.row.utilisateur.id}`);
    };
    const handleCloseErrorSave = () => {
        setErrorAlert(false);
    };
    const handleCloseSucessSave = () => {
        setSucessAlert(false);
    };
    const showSucessSnackBar = (content) => {
        setSucessAlertMessage(content);
        setSucessAlert(true);
    };
    const handleOpenModalEncaissement = (choix) => {
        setShowModal({
            ...showModal,
            modalEncaissement: {
                ...showModal.modalEncaissement,
                show: choix
            }
        });
    };
    const handleOpenEncaissement = async (userID, factureID, inscriptionID) => {
        if (factureID === null) {
            await initierFactureInscription(inscriptionID).unwrap()
                .then(facture => {
                    setShowModal({
                        ...showModal,
                        modalEncaissement: {
                            ...showModal.modalEncaissement,
                            show: true,
                            factureID: facture,
                            userID: userID
                        }
                    });
                })
                .catch(error => {
                    if (error.status === 406) {
                        setErrorAlertMessage(error.data);
                        setErrorAlert(true);
                    } else {
                        setErrorAlertMessage('Erreur Technique veuillez essayer ultérieuement');
                        setErrorAlert(true);
                    }
                })
        } else {
            setShowModal({
                ...showModal,
                modalEncaissement: {
                    ...showModal.modalEncaissement,
                    show: true,
                    factureID: factureID,
                    userID: userID
                }
            });
        }
    };

    const columns = [
        {
            field: 'barcode',
            headerName: 'N° dossier',
            flex: 1,
            minWidth: 200,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <Button size="small" disabled={cellValues.row.facture === null}
                               onClick={() => handleFacture(cellValues)}>{cellValues.row.barcode}</Button>
            }
        }, {
            field: 'nom',
            headerName: 'Info Personnelle',
            flex: 1.2,
            minWidth: 200,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                const test = `${cellValues.row.utilisateur.prenom} ${cellValues.row.utilisateur.nom}`;
                return <>{test}</>
            }
        }, {
            field: 'statut',
            headerName: 'Statut',
            flex: 1,
            minWidth: 50,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                return <StatutDossier statut={cellValues.row.statut}/>
            }
        }, {
            field: 'serie',
            headerName: 'Serie',
            flex: 2,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? `${params.value.libelle}` : ''
        }, {
            field: 'type_paiement',
            headerName: 'Type paiement',
            flex: 1.5,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return params.row.facture !== null ? params.row.facture.paiement !== null ? params.row.facture.paiement.toLowerCase() : '' : '';
            }
        }, {
            field: 'facture',
            headerName: 'Payé',
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.statut === Constants.STATUT_DOSSIER_PAYER || cellValues.row.statut === Constants.STATUT_DOSSIER_PRESENT || (cellValues.row.statut === Constants.STATUT_DOSSIER_ANNULE && cellValues.row.facture != null)) {
                    return `${cellValues.row.facture.montant} €`;
                } else {
                    return '0 €'
                }
            }
        }, {
            field: 'datepaiement',
            headerName: 'Date paiement',
            flex: 1.2,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('DD/MM/YYYY HH:mm') : ''
        }, {
            field: 'Commentaire',
            headerName: 'Commentaire',
            flex: 3,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return params.row.facture !== null ? params.row.facture.complement : ''
            }
        }, {
            field: 'encaissement',
            headerName: 'Encaissement',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                const disable = cellValues.row.statut !== Constants.STATUT_DOSSIER_CONFIRMER && cellValues.row.statut !== Constants.STATUT_DOSSIER_ATTENTE_PAIEMENT && cellValues.row.statut !== Constants.STATUT_DOSSIER_ERREUR_PAIEMENT;
                return (
                    <IconButton edge="end" aria-label="edit" disabled={disable}
                                onClick={() => handleOpenEncaissement(cellValues.row.utilisateur.id, cellValues.row.facture !== null ? cellValues.row.facture.id : null, cellValues.row.id)}
                    >
                        <PaymentIcon/>
                    </IconButton>)
            }
        },
    ];

    const handleClearFilter = () => {
        setFilter(initialFilter);
    };

    const filteredRows = listEncaissement.inscriptions.filter((row) => {
        return Object.keys(filter).every((key) => {
            if (!filter[key]) return true;

            // Vérification de la présence du champ dans row
            if (key in row) {
                let isMatch = false;
                switch (key) {
                    case 'statut':
                        isMatch = filter[key].length > 0 && row.statut.toLowerCase() === filter[key].toLowerCase();
                        break;
                    case 'serie':
                        // Supposons que id_serie soit directement sous row, ajustez selon la structure réelle
                        isMatch = filter[key].length > 0 && row.id_serie !== null && row.id_serie.toString().toLowerCase() === filter[key].toLowerCase();
                        break;
                    case 'utilisateur':
                        if (filter[key].length > 0 && row.utilisateur !== null && row.utilisateur.nom !== null && row.utilisateur.prenom !== null) {
                            const info = filter[key].toLowerCase();
                            isMatch = row.utilisateur.nom.toLowerCase().includes(info) || row.utilisateur.prenom.toLowerCase().includes(info);
                        }
                        break;
                    case 'barcode':
                        isMatch = filter[key].length > 0 && row.barcode.includes(filter[key]);
                        break;
                    default:
                        // Pour les autres cas, conversion en chaîne de caractères pour la comparaison
                        isMatch = row[key].toString().toLowerCase().includes(filter[key].toLowerCase());
                }
                return isMatch;
            }
            return true;
        });
    });

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xl" sx={{mb: 8, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Typography align='center' variant="h4">Encaissements Inscriptions</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} sx={{mt: 2}}>
                            <Grid container justifyContent="flex-end" alignItems="center" direction="row"
                                  spacing={2}>
                                <Grid item xs={1.5} lg={1.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Statut</InputLabel>
                                        <Select
                                            label="Statut"
                                            value={filter.statut}
                                            onChange={(event) => setFilter({...filter, statut: event.target.value})}
                                        >
                                            <MenuItem value="">
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {LISTE_STATUTS_DOSSIER.map(value => {
                                                return <MenuItem key={value} value={value}>{value}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5} lg={1.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-select-small-label">Serie</InputLabel>
                                        <Select
                                            label="Serie"
                                            value={filter.serie}
                                            onChange={(event) => setFilter({...filter, serie: event.target.value})}
                                        >
                                            <MenuItem value="">
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {listSerie.map(value => {
                                                return <MenuItem key={value.id}
                                                                 value={value.id}>{value.libelle}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.75} lg={1.75}>
                                    <TextField size="small"
                                               label="Information Personnelle" variant="outlined"
                                               value={filter.utilisateur}
                                               onChange={(event) => setFilter({
                                                   ...filter,
                                                   utilisateur: event.target.value
                                               })}/>
                                </Grid>
                                <Grid item xs={1.5} lg={1.5}>
                                    <TextField size="small" type="number"
                                               label="N° dossier" variant="outlined"
                                               value={filter.barcode}
                                               onChange={(event) => setFilter({
                                                   ...filter,
                                                   barcode: event.target.value
                                               })}/>
                                </Grid>
                                <Grid item xs={0.5} lg={0.5}>
                                    <IconButton onClick={handleClearFilter}>
                                        <SearchOffIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <DataGrid
                                rows={filteredRows}
                                columns={columns}
                                pageSize={pagesize}
                                rowsPerPageOptions={[5, 10, 50]}
                                disableRowSelectionOnClick
                                experimentalFeatures={{newEditingApi: true}}
                                autoHeight
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                onPageSizeChange={pageSize => setPagesize(pageSize)}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={handleCloseSucessSave}>
                    <Alert onClose={handleCloseSucessSave} severity="success" sx={{width: '100%'}}>
                        {sucessAlertMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={errorAlert} autoHideDuration={3000} onClose={handleCloseErrorSave}>
                    <Alert onClose={handleCloseErrorSave} severity="error" sx={{width: '100%'}}>
                        {errorAlertMessage}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
            {showModal.modalEncaissement.show &&
                <ModalEncaissementManuel
                    closeModal={handleOpenModalEncaissement}
                    userID={showModal.modalEncaissement.userID}
                    factureID={showModal.modalEncaissement.factureID}
                    sucessSave={showSucessSnackBar}/>}
        </Fragment>
    )
}