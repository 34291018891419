import {apiSlice} from "../../app/api/apiSlice";

const urlPays = "pays";

export const paysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllPays: builder.query({
            query: () => ({
                url: `${urlPays}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllPays', ...result.id}, {type: 'ListAllPays', id: 'LIST'}] :
                    [{type: 'ListAllPays', id: 'LIST'}],
        }),
        addPays: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlPays}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        abreviation: data.abreviation
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllPays', id: 'LIST'}],
        }),
        deletePays: builder.mutation({
            query: (paysID) => {
                return {
                    url: `${urlPays}/${paysID}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: [{type: 'ListAllPays', id: 'LIST'}],
        }),
        updatePays: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlPays}/${data.id}`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        abreviation: data.abreviation
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllPays', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllPaysQuery,
    useAddPaysMutation,
    useDeletePaysMutation,
    useUpdatePaysMutation,
} = paysApiSlice