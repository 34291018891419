import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {Card, ListItem} from "@mui/material";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";

export default (props) => {


    const listItem = [
        {nom: 'Date de création', champs: 'dateCreation'},
        {nom: 'Date inscription', champs: 'dateInscription'},
        {nom: 'Date confirmation', champs: 'dateConfirmation'},
        {nom: 'Date validation', champs: 'dateValidation'},
        {nom: 'Date mise d\'attente', champs: 'dateAttente'},
        {nom: 'Date paiement', champs: 'datePaiement'},
        {nom: 'Date relance', champs: 'dateRelance'},
        {nom: 'Date refus', champs: 'dateRefus'},
        {nom: 'Date présence', champs: 'datePresence'},
        {nom: 'Date annulation', champs: 'dateAnnulation'},
        {nom: 'Date abandon', champs: 'dateAbandon'},
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={4} lg={4}>
                <Card variant="outlined">
                    <List dense={true}>
                        {listItem.slice(0, 4).map(item => {
                            return <ListItem key={item.nom} secondaryAction={
                                <Typography variant="overline">
                                    {props.historiqueDate[item.champs] !== null ? dayjs(props.historiqueDate[item.champs]).format('DD/MM/YYYY HH:mm:ss') : ''}
                                </Typography>}>
                                <ListItemText primary={`${item.nom}`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Card>
            </Grid>
            <Grid item xs={4} lg={4}>
                <Card variant="outlined">
                    <List dense={true}>
                        {listItem.slice(4, 8).map(item => {
                            return <ListItem key={item.nom} secondaryAction={
                                <Typography variant="overline">
                                    {props.historiqueDate[item.champs] !== null ? dayjs(props.historiqueDate[item.champs]).format('DD/MM/YYYY HH:mm:ss') : ''}
                                </Typography>}>
                                <ListItemText primary={`${item.nom}`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Card>
            </Grid>
            <Grid item xs={4} lg={4}>
                <Card variant="outlined">
                    <List dense={true}>
                        {listItem.slice(8, 12).map(item => {
                            return <ListItem key={item.nom} secondaryAction={
                                <Typography variant="overline">
                                    {props.historiqueDate[item.champs] !== null ? dayjs(props.historiqueDate[item.champs]).format('DD/MM/YYYY HH:mm:ss') : ''}
                                </Typography>}>
                                <ListItemText primary={`${item.nom}`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Card>
            </Grid>
        </Grid>)
}