import {apiSlice} from "../../app/api/apiSlice";
const urlMotoType = "moto-type";

export const motoTypeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        listAllTypeMoto: builder.query({
            query: () => ({
                url: `${urlMotoType}/list`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListAllTypeMoto', ...result.id}, {type: 'ListAllTypeMoto', id: 'LIST'}] :
                    [{type: 'ListAllTypeMoto', id: 'LIST'}],
        }),
        listTypeMotoEvenement: builder.query({
            query: (evenementID) => ({
                url: `${urlMotoType}/evenement/${evenementID}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? [{type: 'ListTypeMotoEvenement', ...result.id}, {type: 'ListTypeMotoEvenement', id: 'LIST'}] :
                    [{type: 'ListTypeMotoEvenement', id: 'LIST'}],
        }),
        removeTypeMotoEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, motoTypeID} = data;
                return {
                    url: `${urlMotoType}/evenement/${evenementID}`,
                    method: 'DELETE',
                    body: {
                        mototypeid: motoTypeID
                    }
                }
            },
            invalidatesTags: [{type: 'ListTypeMotoEvenement', id: 'LIST'}],
        }),
        addTypeMotoEvenement: builder.mutation({
            query: (data) => {
                const {evenementID, listMotoTypes} = data;
                return {
                    url: `${urlMotoType}/evenement/${evenementID}`,
                    method: 'POST',
                    body: {
                        mototypes: listMotoTypes
                    }
                }
            },
            invalidatesTags: [{type: 'ListTypeMotoEvenement', id: 'LIST'}],
        }),
        createTypeMoto: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlMotoType}/`,
                    method: 'POST',
                    body: {
                        libelle: data.libelle,
                        description: data.description,
                        cylindreemin: data.cylindreeMini,
                        cylindreemax: data.cylindreeMax,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllTypeMoto', id: 'LIST'}],
        }),
        updateTypeMoto: builder.mutation({
            query: (data) => {
                return {
                    url: `${urlMotoType}/${data.id}`,
                    method: 'PUT',
                    body: {
                        libelle: data.libelle,
                        description: data.description,
                        cylindreemin: data.cylindreeMini,
                        cylindreemax: data.cylindreeMax,
                    }
                }
            },
            invalidatesTags: [{type: 'ListAllTypeMoto', id: 'LIST'},{type: 'ListTypeMotoEvenement', id: 'LIST'}],
        }),
        deleteTypeMoto: builder.mutation({
            query: (motoTypeID) => {
                return {
                    url: `${urlMotoType}/${motoTypeID}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'ListAllTypeMoto', id: 'LIST'}],
        }),
    })
})

export const {
    useListAllTypeMotoQuery,
    useListTypeMotoEvenementQuery,
    useRemoveTypeMotoEvenementMutation,
    useAddTypeMotoEvenementMutation,
    useCreateTypeMotoMutation,
    useUpdateTypeMotoMutation,
    useDeleteTypeMotoMutation,
} = motoTypeApiSlice